import { useEffect, useState } from "react";
import { defer } from "rxjs";
import { useMsal } from "@azure/msal-react";
import { bulkGet } from "../services/bulkServices";
import { DEF_APP_SETTINGS } from "../models/AppSettings";

export const useGetAppSettings = () => {
  const [results, setResults] = useState(null);

  const { instance, accounts } = useMsal();

  useEffect(() => {
    const fetchData = async () => {
      const token = await instance.acquireTokenSilent({
        scopes: ["User.Read", "User.ReadBasic.All"],
        account: accounts[0]
      });

      const query = {
        collection: "Settings",
        filter: {
          id: "app-settings"
        },
        project: {
          _id: 0,
          id: 0
        },
        confirm: true
      };

      let res = null;

      const bulkGetRes = await bulkGet(query, token.idToken);

      if (bulkGetRes.status === 200) {
        res =
          bulkGetRes.response.length > 0
            ? bulkGetRes.response[0]
            : DEF_APP_SETTINGS;
      } else {
        res = DEF_APP_SETTINGS;
      }

      return res;
    };

    const subscription = defer(() => fetchData()).subscribe(setResults);

    return () => {
      subscription.unsubscribe();
    };
  }, [instance, accounts]);

  return results;
};
