import React from "react";
import SweeperComp from "../components/SweeperComp";
import "./Sweeper.css";

const Sweeper = (props) => {
  return (
    <div className="Sweeper">
      <div className="container">
        <SweeperComp {...props} />
      </div>
    </div>
  );
};

export default Sweeper;
