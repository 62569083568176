import React from "react";
import { withRouter } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal
} from "@azure/msal-react";
import { sendMsGraphMail } from "./graph";
import { loginRequest, sendMailRequest } from "./authConfig";
import Routes from "./Routes";
import "./App.css";
import MainMenu from "./components/MainMenu";
import Login from "./containers/Login";
import { useGetUser } from "./components/useGetUser";

const MSGRAPH_MAILACTIVE = process.env.REACT_APP_MSGRAPH_MAILACTIVE === "true";

const App = (props) => {
  const AppContent = () => {
    const { instance, accounts } = useMsal();

    const userId = `${accounts[0].localAccountId}`;
    const name = accounts[0].name;
    const email = accounts[0].username;

    const sendEmail = (message, callback = null) => {
      if (MSGRAPH_MAILACTIVE) {
        instance
          .acquireTokenSilent({
            ...sendMailRequest,
            account: accounts[0]
          })
          .then((response) => {
            sendMsGraphMail(response.accessToken, message).then((response) => {
              if (callback) callback(response);
            });
          });
      } else {
        if (callback) callback("MS Graph mail is off");
      }
    };

    const getToken = async (callback = null) => {
      try {
        const res = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0]
        });

        if (callback) {
          callback(res);
        } else {
          return res;
        }
      } catch (e) {
        console.log(e);
      }
    };

    let user = {};
    let appSettings = {};
    let auth = {};

    const creds = useGetUser(userId);

    const logout = creds?.logout ?? false;
    const isCreds = creds !== null;
    user = creds?.user ?? null;
    appSettings = creds?.appSettings ?? null;
    auth = creds?.auth ?? null;

    if (isCreds && !logout && !user) {
      // Goes through registration approval
      const guest = {
        id: userId,
        name,
        email,
        role: "guest",
        prefs: {
          notifications: {
            registration: false
          }
        },
        delegation: []
      };

      user = { ...guest };
    }

    return (
      <div>
        {isCreds && !logout && (
          <div>
            <MainMenu location={props.location} user={user} />
            <Routes
              appProps={{ user, appSettings, auth, getToken, sendEmail }}
            />
          </div>
        )}
        {logout && <Login />}
      </div>
    );
  };

  return (
    <div className="App">
      <AuthenticatedTemplate>
        <AppContent />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </div>
  );
};

export default withRouter(App);
