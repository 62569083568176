import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  Collapse,
  FormGroup,
  Label,
  ListGroup,
  // ListGroupItemHeading,
  ListGroupItem,
  ListGroupItemText,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane
} from "reactstrap";
import {
  STATUS_ASSIGNED,
  STATUS_CANCELED,
  STATUS_COMPLETED,
  STATUS_NOT_COMPLETED,
  STATUS_PENDING
} from "../models/taskModels";
import TaskCompletion from "./TaskCompletion";
import { bulkUpdate, bulkDelete } from "../services/bulkServices";
import { cancelTask } from "../services/taskServices";
import { getTimeFromX } from "../services/dateTimeUtils";
import { useMsal } from "@azure/msal-react";
import { phraseToProperCase } from "../libs/case-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLink,
  faChevronUp,
  faChevronDown
} from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import Moment from "react-moment";
import "moment-timezone";
import "./Task.css";

// Set the timezone for every instance.
Moment.globalTimezone = "America/Detroit";

// Set the output format for every react-moment instance.
Moment.globalFormat = "MM/DD/YYYY HH:mm";

const DEBUG = false;

const getTimestamp = () => new Date().getTime();
const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;
// const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const Task = (props) => {
  const {
    user,
    defTask,
    setEditTask,
    callback,
    handleDeleteItem,
    crudOn,
    affiliation = null
  } = props;

  const { instance, accounts } = useMsal();

  const [task, setTask] = useState(defTask);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isConfirmingCancel, setIsConfirmingCancel] = useState(false);
  const [collapseState, setCollapseState] = useState({
    manageCompletion: false
  });

  const userInfo = {
    id: user.id,
    name: user.name,
    org: user.org,
    email: user.email
  };

  // Switches
  const isAssigned = task.status === STATUS_ASSIGNED;
  const isPending = task.status === STATUS_PENDING;
  const isNotCompleted = task.status === STATUS_NOT_COMPLETED;
  const isCanceled = task.status === STATUS_CANCELED;
  const isCompleted = task.status === STATUS_COMPLETED;
  const showStats = !isPending && !isCanceled;
  let isRecipient = false;

  // Check affiliation is passed and update isRecipient
  if (affiliation) {
    if (affiliation === "recipient") isRecipient = true;
  }

  // Handle main collapse
  const [isOpen, setIsOpen] = useState(false);
  const [collapseIcon, setCollapseIcon] = useState(faChevronDown);
  const toggle = () => {
    const icon = !isOpen ? faChevronUp : faChevronDown;

    setIsOpen(!isOpen);
    setCollapseIcon(icon);
  };

  const handleUpdateTask = (obj) => {
    const copyCollapseState = { ...collapseState };

    // Keep Manage Completion open
    copyCollapseState.manageCompletion = true;

    setCollapseState(copyCollapseState);
    setTask(obj);
  };

  const findLeaderInDirectReports = () => {
    const groups = Object.keys(task.directReports);
    let leader = null;
    let index = null;
    let exitLoops = false;

    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];
      const leaders = task.directReports[group];

      for (let j = 0; j < leaders.length; j++) {
        const thisLeader = leaders[j];

        if (thisLeader.id === user.id) {
          leader = thisLeader;
          index = j;
          exitLoops = true;

          break;
        }

        if (exitLoops) break;
      }
    }

    return { leader, index };
  };

  const getTaskThemeColor = () => {
    let color = "default";
    let option = null;

    if (!isRecipient) {
      if (isCompleted) {
        color = "success";
      }

      if (isNotCompleted) {
        color = "danger";
      }

      if (isAssigned) {
        color = "warning";
      }

      if (isPending || isCanceled) {
        color = "secondary";
      }
    } else {
      const { leader } = findLeaderInDirectReports();

      if (isPending || isCanceled) {
        color = "secondary";
      } else {
        if (isAssigned || isNotCompleted || isCompleted) {
          if (leader.completed) {
            if (leader.onTime) {
              color = "success";
            } else {
              color = "success";
              option = "gradient";
            }
          } else {
            if (isAssigned) {
              color = "warning";
            } else {
              color = "danger";
            }
          }
        }
      }
    }

    return { color, option };
  };

  // Control tabs
  const [activeTab, setActiveTab] = useState("1");
  const toggleTabs = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const getIdToken = async () => {
    const res = await instance.acquireTokenSilent({
      scopes: ["User.Read", "User.ReadBasic.All"],
      account: accounts[0]
    });

    return res.idToken;
  };

  const TaskTab = (props) => {
    const { task, handleUpdateTask, collapseState } = props;

    const ShowGroups = () => {
      return (
        <ListGroup className="mb-1rem overflow-300">
          {task.groups.sort().map((group, i) => {
            return <ListGroupItem key={i}>{group}</ListGroupItem>;
          })}
        </ListGroup>
      );
    };

    const ShowWebReferences = () => {
      return (
        <ListGroup>
          {task.webReferences.map((ref, i) => {
            return (
              <ListGroupItem key={i}>
                {/* <ListGroupItemHeading tag="h6" className="mb-05rem">
                  {ref.title}
                </ListGroupItemHeading> */}
                <ListGroupItemText className="mb-0rem">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${ref.scheme}${ref.url}`}
                  >
                    {/* <Button size="sm" color="link">
                      <i>
                        {ref.scheme}
                        {ref.url}
                      </i>{" "}
                      <FontAwesomeIcon icon={faLink} />
                    </Button> */}
                    <i>{ref.title}</i> <FontAwesomeIcon icon={faLink} />
                  </a>
                </ListGroupItemText>
              </ListGroupItem>
            );
          })}
        </ListGroup>
      );
    };

    const ShowDueDate = () => {
      const { color, option } = getTaskThemeColor();
      const timeFromX = getTimeFromX(task.dueDate);

      let classes = [];

      if (option) {
        classes.push(`custom-bg-${color}-${option}`);
      }

      return (
        <Badge color={color} className={classes.join(" ")}>
          <Moment format="MM/DD/YYYY">{task.dueDate}</Moment>{" "}
          <i>({timeFromX})</i>
        </Badge>
      );
    };

    const ShowStartDate = () => {
      const { color } = getTaskThemeColor();
      const timeFromX = getTimeFromX(task.startDate);

      return (
        <Badge color={color}>
          <Moment format="MM/DD/YYYY">{task.startDate}</Moment>{" "}
          <i>({timeFromX})</i>
        </Badge>
      );
    };

    const ShowStatus = () => {
      let { color } = getTaskThemeColor();
      const isCompleted = task.completedOn !== null;
      let completionTag = "";

      if (isCompleted) {
        if (task.onTime) completionTag = " On-time";
        if (task.late) {
          completionTag = " Late";
          color = "danger";
        }
      }

      return (
        <Badge color={color}>
          {phraseToProperCase(task.status)}
          {completionTag}
        </Badge>
      );
    };

    const ShowLogCard = () => {
      const isSame = task.createdAt === task.updatedAt;

      const CompletedOn = () => {
        return (
          <>
            <b>Completed on:</b>{" "}
            <Moment format="MM/DD/YYYY HH:mm:ss">{task.completedOn}</Moment>
          </>
        );
      };

      const CreatedByField = () => {
        const by = task.createdBy.name;
        const at = task.createdAt;

        return (
          <>
            <b>Created by:</b>
            <br /> {by} {" @ "}
            <Moment format="MM/DD/YYYY HH:mm:ss">{at}</Moment>
          </>
        );
      };

      const UpdatedByField = () => {
        const by = task.updatedBy.name;
        const at = task.updatedAt;

        return (
          <>
            <b>Updated by:</b>
            <br /> {by} {" @ "}
            <Moment format="MM/DD/YYYY HH:mm:ss">{at}</Moment>
          </>
        );
      };

      return (
        <Card
          body
          inverse
          style={{ marginTop: "1.5rem", backgroundColor: "#f7f7f7" }}
        >
          <CardText style={{ color: "black" }}>
            <CreatedByField />
          </CardText>
          {!isSame && (
            <CardText style={{ color: "black" }}>
              <UpdatedByField />
            </CardText>
          )}
          {isCompleted && (
            <CardText style={{ color: "black" }}>
              <CompletedOn />
            </CardText>
          )}
        </Card>
      );
    };

    const handleEditTask = () => {
      setEditTask(task);
    };

    const handleDeleteTask = async () => {
      setIsDeleting(true);

      // Request access token
      const idToken = await getIdToken();

      const query = {
        collection: "Tasks",
        filter: {
          id: task.id
        },
        confirm: true
      };

      const res = await bulkDelete(query, idToken);

      if (res.status === 200) {
        setIsDeleting(false);
        setIsConfirmingDelete(false);
        handleDeleteItem(task.id);
      }
    };

    const handleCancelTask = async () => {
      setIsCanceling(true);

      // Request access token
      const idToken = await getIdToken();

      const set = {
        status: STATUS_CANCELED,
        updatedBy: userInfo,
        updatedAt: getTimestamp()
      };

      const payload = {
        taskId: task.id,
        set
      };

      const res = await cancelTask(payload, idToken);

      if (res.status === 200) {
        setIsCanceling(false);
        setIsConfirmingCancel(false);
        callback({ ...task, ...set });
      }
    };

    const DeleteYesNo = () => {
      return (
        <>
          <CardText>Are you sure you want to delete this task?</CardText>
          <Button
            color="danger"
            size="sm"
            disabled={isDeleting}
            onClick={() => setIsConfirmingDelete(false)}
          >
            Not sure
          </Button>
          <Button
            className="ml-05rem"
            color="success"
            size="sm"
            disabled={isDeleting}
            onClick={handleDeleteTask}
          >
            Yes, I'm sure {isDeleting && <Spinner size="sm" color="light" />}
          </Button>
        </>
      );
    };

    const CancelYesNo = () => {
      return (
        <>
          <CardText>Are you sure you want to cancel this task?</CardText>
          <Button
            color="danger"
            size="sm"
            disabled={isCanceling}
            onClick={() => setIsConfirmingCancel(false)}
          >
            Not sure
          </Button>
          <Button
            className="ml-05rem"
            color="success"
            size="sm"
            disabled={isCanceling}
            onClick={handleCancelTask}
          >
            Yes, I'm sure {isCanceling && <Spinner size="sm" color="light" />}
          </Button>
        </>
      );
    };

    const ShowFooter = () => {
      const override = isConfirmingDelete || isConfirmingCancel;
      const showEdit = isPending && crudOn && !override;
      const showDelete = isPending && crudOn && !override;
      const showCancel = isAssigned && crudOn && !override;
      const showFooter = showEdit || showDelete || showCancel || override;
      return (
        showFooter && (
          <CardFooter>
            {showEdit && (
              <Button
                outline
                color="primary"
                size="sm"
                onClick={handleEditTask}
              >
                Edit task
              </Button>
            )}
            {showDelete && (
              <Button
                className="ml-05rem"
                outline
                color="danger"
                size="sm"
                disabled={isDeleting}
                onClick={() => setIsConfirmingDelete(true)}
              >
                Delete task
              </Button>
            )}
            {isConfirmingDelete && <DeleteYesNo />}
            {showCancel && (
              <Button
                className="ml-05rem"
                outline
                color="danger"
                size="sm"
                disabled={isCanceling}
                onClick={() => setIsConfirmingCancel(true)}
              >
                Cancel task
              </Button>
            )}
            {isConfirmingCancel && <CancelYesNo />}
          </CardFooter>
        )
      );
    };

    const ShowCompletionRate = () => {
      const assignments = task.stats.assignments;
      const completed = task.stats.completed;
      const rate = ((completed / assignments) * 100).toFixed(0);
      const color =
        rate === "100" ? "success" : isNotCompleted ? "danger" : "warning";

      return <Badge color={color}>{rate}%</Badge>;
    };

    const updateLeaderCompletionState = (leader, index, completion) => {
      const copyTask = { ...task };
      const copyLeader = {
        ...task.directReports[leader.jobTitle][index]
      };
      const assignments = task.stats.assignments;
      let tasksCompleted = task.stats.completed;
      let tasksNotCompleted = task.stats.notCompleted;
      let tasksOnTime = task.stats.onTime;
      let tasksLate = task.stats.late;

      // Update stats
      const stats = {};
      if (completion.completed) {
        tasksCompleted += 1;
        stats["completed"] = tasksCompleted;

        tasksNotCompleted -= 1;
        stats["notCompleted"] = tasksNotCompleted;
      }
      if (completion.onTime) {
        tasksOnTime += 1;
        stats["onTime"] = tasksOnTime;
      } else {
        tasksLate += 1;
        stats["late"] = tasksLate;
      }
      copyTask.stats = { ...task.stats, ...stats };

      // Update task completion
      if (assignments === tasksCompleted) {
        copyTask.status = STATUS_COMPLETED;
        copyTask.completedOn = completion.completedOn;

        if (assignments === tasksOnTime) {
          copyTask.onTime = true;
          copyTask.late = false;
        } else {
          copyTask.onTime = false;
          copyTask.late = true;
        }
      }

      // Update leader
      copyTask.directReports[leader.jobTitle][index] = {
        ...copyLeader,
        ...completion
      };

      handleUpdateTask(copyTask);
    };

    const handleSingleMarkAsCompleted = async () => {
      setIsUpdating(true);

      // Request access token
      const idToken = await getIdToken();

      const today = getTimestamp();
      const isOnTime = today < task.dueDate;
      const filter = {};
      const set = {};

      const { leader, index } = findLeaderInDirectReports();

      // Set leader query prop
      const leaderProp = `directReports.${leader.jobTitle}`;

      // Add to filter
      filter["id"] = task.id;
      filter[`${leaderProp}.id`] = leader.id;

      // Add to set
      set[`${leaderProp}.$.completed`] = true;
      set[`${leaderProp}.$.onTime`] = isOnTime;
      set[`${leaderProp}.$.completedOn`] = today;

      // Update task completion
      const assignments = task.stats.assignments;
      let tasksCompleted = task.stats.completed + 1;
      let tasksOnTime = task.stats.onTime;

      if (assignments === tasksCompleted) {
        set["status"] = STATUS_COMPLETED;
        set["completedOn"] = today;

        if (isOnTime) tasksOnTime += 1;

        if (assignments === tasksOnTime) {
          set["onTime"] = true;
          set["late"] = false;
        } else {
          set["onTime"] = false;
          set["late"] = true;
        }
      }

      const query = {
        collection: "Tasks",
        filter,
        update: {
          $set: set,
          $inc: {
            "stats.completed": 1,
            "stats.onTime": isOnTime ? 1 : 0,
            "stats.late": isOnTime ? 0 : 1,
            "stats.notCompleted": -1
          },
          $push: {},
          $pull: {},
          $setOnInsert: {}
        },
        options: {
          upsert: false
        },
        confirm: true
      };

      const res = await bulkUpdate(query, idToken);

      if (res.status === 200) {
        setIsUpdating(false);

        updateLeaderCompletionState(leader, index, {
          completed: true,
          onTime: isOnTime,
          completedOn: today
        });
      }
    };

    const MyTaskCompletion = () => {
      const { color, option } = getTaskThemeColor();
      const { leader } = findLeaderInDirectReports();
      const completed = leader.completed;
      const onTime = leader.onTime;

      let classes = [];

      if (option) {
        classes.push(`custom-bg-${color}-${option}`);
      }

      return (
        <div>
          <FormGroup className="mb-0rem">
            <Label>
              <b>Completion:</b>{" "}
              {completed && (
                <Badge color={color} className={classes.join(" ")}>
                  Completed {onTime ? "on-time" : "late"}
                </Badge>
              )}
            </Label>
          </FormGroup>
          {!completed && (
            <Button
              className="mb-0rem"
              outline
              color={color}
              disabled={isUpdating}
              onClick={handleSingleMarkAsCompleted}
            >
              <b>Mark my task as completed</b>{" "}
              {isUpdating && <Spinner size="sm" color="light" />}
            </Button>
          )}
        </div>
      );
    };

    const renderTaskDescription = (text) => {
      const paras = text.split(/\r\n|\n/);

      return (
        <span>
          {paras.map((para, i) => {
            return (
              <span key={i}>
                {para}
                <br />
              </span>
            );
          })}
        </span>
      );
    };

    return (
      <>
        <TabPane tabId="1">
          <CardBody>
            <FormGroup className="mb-1rem">
              <Label>
                <b>Task owner:</b> {task.leader.displayName}
              </Label>
            </FormGroup>
            {!isRecipient && (
              <FormGroup className="mb-1rem">
                <Label>
                  <b>Status:</b> <ShowStatus />
                </Label>
              </FormGroup>
            )}
            {!isRecipient && showStats && (
              <FormGroup className="mb-1rem">
                <Label>
                  <b>Completion:</b> <ShowCompletionRate />
                </Label>
              </FormGroup>
            )}
            {!isRecipient && (
              <TaskCompletion
                user={user}
                task={task}
                withDirectReports={task.withDirectReports}
                handleUpdateTask={handleUpdateTask}
                collapseState={collapseState}
                isRecipient={isRecipient}
                taskColor={getTaskThemeColor()}
              />
            )}
            <FormGroup className="mb-1rem">
              <Label>
                <b>Title:</b>
                <br />
                {task.taskTitle}
              </Label>
            </FormGroup>
            <FormGroup className="mb-1rem">
              <Label>
                <b>Description:</b>
                <br />
                {renderTaskDescription(task.taskDescription)}
              </Label>
            </FormGroup>
            <FormGroup className="mb-1rem">
              <Label>
                <b>Due date:</b> <ShowDueDate />
              </Label>
            </FormGroup>
            {!isRecipient && (
              <FormGroup className="mb-1rem">
                <Label>
                  <b>Start date:</b> <ShowStartDate />
                </Label>
              </FormGroup>
            )}
            {!isRecipient && (
              <FormGroup className="mb-1rem">
                <Label>
                  <b>Assigned Groups:</b>
                </Label>
                <ShowGroups />
              </FormGroup>
            )}
            {!isRecipient && (
              <FormGroup className="mb-1rem">
                <Label>
                  <b>Only assign to leaders with direct reports:</b>{" "}
                  {task.withDirectReports ? "Yes" : "No"}
                </Label>
              </FormGroup>
            )}
            {task.webReferences.length > 0 && (
              <FormGroup className="mb-1rem">
                <Label>
                  <b>Resources:</b>
                </Label>
                <ShowWebReferences />
              </FormGroup>
            )}
            {isRecipient && <MyTaskCompletion />}
            <ShowLogCard />
          </CardBody>
          <ShowFooter />
        </TabPane>
      </>
    );
  };

  const CustomCardHeader = (props) => {
    const { children } = props;

    const { color, option } = getTaskThemeColor();

    let classes = ["text-white"];

    if (option) {
      classes.push(`custom-bg-${color}-${option}`);
    } else {
      classes.push(`custom-bg-${color}`);
    }

    return (
      <CardHeader tag="h5" className={classes.join(" ")}>
        {children}
      </CardHeader>
    );
  };

  const ShowHeader = () => {
    const { color, option } = getTaskThemeColor();

    let classes = ["collapse-button"];

    if (option) {
      classes.push(`custom-bg-${color}-${option}`);
    }

    return (
      <CustomCardHeader>
        {task.taskTitle}
        <Button
          className={classes.join(" ")}
          size="sm"
          color={color}
          onClick={toggle}
        >
          <FontAwesomeIcon icon={collapseIcon} />
        </Button>
      </CustomCardHeader>
    );
  };

  return (
    <div className="Task">
      <Card className="mb-1rem">
        <ShowHeader />
        <Collapse isOpen={isOpen}>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggleTabs("1");
                }}
              >
                Task
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TaskTab
              task={task}
              handleUpdateTask={handleUpdateTask}
              collapseState={collapseState}
            />
          </TabContent>
        </Collapse>
      </Card>
      {DEBUG && (
        <Card className="mt-1rem mb-1rem">
          <CardHeader>Debug Task</CardHeader>
          <CardBody className="overflow-300">
            <Json data={task} />
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default Task;
