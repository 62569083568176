import React, { useState } from "react";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  CardBody,
  CardText,
  FormGroup,
  Label,
  Spinner,
  CardTitle
} from "reactstrap";
import { runDelegationUpdate } from "../services/taskServices";
import { useMsal } from "@azure/msal-react";
import "./DelegationUpdateComp.css";

const DEBUG = false;

const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;
// const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const DelegationUpdateComp = () => {
  const { instance, accounts } = useMsal();

  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState(null);

  const getIdToken = async () => {
    const res = await instance.acquireTokenSilent({
      scopes: ["User.Read", "User.ReadBasic.All"],
      account: accounts[0]
    });

    return res.idToken;
  };

  const handleRun = async () => {
    setIsLoading(true);

    // Request access token
    const idToken = await getIdToken();

    // Run delegation update
    const res = await runDelegationUpdate(idToken);

    setResults(res);

    setIsLoading(false);
  };

  const ShowResults = () => {
    const status = results.status;

    const ShowError = () => {
      return <CardText>Server responded with status: {status}</CardText>;
    };

    const ShowDetails = () => {
      const response = results.response;
      const { accounts, dataFactory } = response;

      const isUpdated = accounts.length > 0;

      return (
        <>
          <FormGroup className={dataFactory ? "" : "mb-0rem"}>
            <Label>
              <b>Accounts updated:</b>
            </Label>
            <br />
            {isUpdated ? accounts.join(", ") : "No accounts updated."}
          </FormGroup>
          {dataFactory && (
            <FormGroup className="mb-0rem">
              <Label>
                <b>Data Factory response:</b>
              </Label>
              <br />
              <Json data={dataFactory} />
            </FormGroup>
          )}
        </>
      );
    };

    return (
      <Card className="mt-1rem">
        <CardHeader>Results</CardHeader>
        <CardBody className="overflow-300">
          {status !== 200 && <ShowError />}
          {status === 200 && <ShowDetails />}
        </CardBody>
        <CardFooter>
          <Button
            className="mr-05rem"
            color="primary"
            size="sm"
            onClick={() => setResults(null)}
          >
            Clear results
          </Button>
        </CardFooter>
      </Card>
    );
  };

  const Debug = () => {
    return (
      <Card className="mt-1rem">
        <CardHeader>Debug</CardHeader>
        <CardBody className="overflow-300">
          <Json data={results} />
        </CardBody>
      </Card>
    );
  };

  return (
    <div className="DelegationUpdateComp">
      <Card>
        <CardHeader className="bg-primary text-white" tag="h5">
          Delegation Update
        </CardHeader>
        <CardBody>
          <CardTitle>Description of functionality...</CardTitle>
          <Button
            className="mr-05rem"
            color="primary"
            size="sm"
            disabled={isLoading}
            onClick={handleRun}
          >
            Run Delegation Update{" "}
            {isLoading && <Spinner size="sm" color="light" />}
          </Button>
          {results && <ShowResults />}
          {DEBUG && <Debug />}
        </CardBody>
      </Card>
    </div>
  );
};

export default DelegationUpdateComp;
