import { useEffect, useState } from "react";
import { defer } from "rxjs";
import { useMsal } from "@azure/msal-react";
import { getLeaderOrgTree } from "../services/orgTreeServices";

const leaderId = process.env.REACT_APP_TOPLEADER_ID;

export const useGetTopTree = () => {
  const [results, setResults] = useState(null);

  const { instance, accounts } = useMsal();

  useEffect(() => {
    const fetchData = async () => {
      const token = await instance.acquireTokenSilent({
        scopes: ["User.Read", "User.ReadBasic.All"],
        account: accounts[0]
      });

      return await getLeaderOrgTree(leaderId, token.idToken);
    };

    const subscription = defer(() => fetchData()).subscribe(setResults);

    return () => {
      subscription.unsubscribe();
    };
  }, [instance, accounts]);

  return results;
};
