import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from "reactstrap";
import {
  STATUS_ASSIGNED,
  STATUS_PENDING,
  STATUS_CANCELED,
  STATUS_COMPLETED,
  STATUS_NOT_COMPLETED
} from "../models/taskModels";
import Task from "./Task";
import TaskFormComp from "../components/TaskFormComp";
import { bulkGet } from "../services/bulkServices";
import { getCollectionCount } from "../services/collectionService";
import {
  getTodayDateUnixms,
  getFutureDateObjFromUnixms,
  dateObjToUnixms
} from "../services/dateTimeUtils";
import { useMsal } from "@azure/msal-react";
import GetPagination from "./GetPagination";
import { phraseToProperCase } from "../libs/case-utils";
import "./TasksComp.css";

const statusOptions = [
  // { status: "all", label: "Any" },
  { status: STATUS_ASSIGNED, label: phraseToProperCase(STATUS_ASSIGNED) },
  { status: STATUS_COMPLETED, label: phraseToProperCase(STATUS_COMPLETED) },
  {
    status: STATUS_NOT_COMPLETED,
    label: phraseToProperCase(STATUS_NOT_COMPLETED)
  },
  { status: STATUS_PENDING, label: phraseToProperCase(STATUS_PENDING) },
  { status: STATUS_CANCELED, label: phraseToProperCase(STATUS_CANCELED) }
];

const dueDateOptions = {};
dueDateOptions["all"] = null;
dueDateOptions[STATUS_PENDING] = null;
dueDateOptions[STATUS_CANCELED] = null;
dueDateOptions[STATUS_ASSIGNED] = [
  { status: "all", label: "Any" },
  { status: "dueToday", label: "Due today" },
  { status: "dueInOneDay", label: "Due in one day" },
  { status: "dueInOneWeek", label: "Due in one week" }
];
dueDateOptions[STATUS_COMPLETED] = [
  { status: "all", label: "Any" },
  { status: "onTime", label: "On-time" },
  { status: "late", label: "Late" }
];
dueDateOptions[STATUS_NOT_COMPLETED] = null;

const TasksComp = (props) => {
  const { user, appSettings } = props;

  const { instance, accounts } = useMsal();

  const [isSearchOpen, setIsSearchOpen] = useState(true);
  const toggleSearch = () => setIsSearchOpen(!isSearchOpen);

  const [selectStatus, setSelectStatus] = useState(STATUS_ASSIGNED);
  const [selectDueDate, setSelectDueDate] = useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const [editTask, setEditTask] = useState(null);
  const [items, setItems] = useState([]);

  // // Switches
  const isLeader = user.role === "leader";

  // Manage delegation
  const curUserDelegation = user.delegation;

  const getIdToken = async () => {
    const res = await instance.acquireTokenSilent({
      scopes: ["User.Read", "User.ReadBasic.All"],
      account: accounts[0]
    });

    return res.idToken;
  };

  const handleGoBack = () => {
    setEditTask(null);
  };

  const updateItem = (task) => {
    let itemsCopy = [...items];

    // Find item index
    let index = null;

    itemsCopy.every((item, i) => {
      if (item.id === task.id) {
        index = i;
        return false;
      }

      return true;
    });

    if (index !== null) {
      itemsCopy[index] = task;

      setEditTask(null);
      setItems(itemsCopy);
    } else {
      setEditTask(null);
    }
  };

  const deleteItem = (id) => {
    const itemsCopy = items.filter((item) => item.id !== id);

    setItems(itemsCopy);
  };

  const newSearch = () => {
    setIsLoading(false);

    setItems([]);
    toggleSearch();
  };

  // Control pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsCount, setItemsCount] = useState(0);

  const totalItemsPerPage = 20;

  const nextPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setItems([]);

    listItems(pageNumber, totalItemsPerPage);
  };

  const incChange = (pageNumber, inc, isposOrneg) => {
    let finalPage;
    const residual = pageNumber % inc;
    const control = residual === 0 ? inc : residual;

    if (isposOrneg > 0) {
      finalPage = pageNumber + inc - control + 1;
    } else {
      finalPage = pageNumber - inc - control + 1;
    }

    setCurrentPage(finalPage);
    setItems([]);

    listItems(finalPage, totalItemsPerPage);
  };

  const firstChange = () => {
    let finalPage = 1;

    setCurrentPage(finalPage);
    setItems([]);

    listItems(finalPage, totalItemsPerPage);
  };

  const lastChange = (finalPage) => {
    setCurrentPage(finalPage);
    setItems([]);

    listItems(finalPage, totalItemsPerPage);
  };

  // Control modal
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const toggleModal = () => setOpenModal(!openModal);
  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      &times;
    </button>
  );

  const showCustomModal = (params) => {
    const { title, message } = params;

    setModalTitle(title);
    setModalMsg(message);
    toggleModal();
  };

  const listItems = async (page, count) => {
    let res = [];
    let query = {};
    let totalItems = 0;
    const todayMs = getTodayDateUnixms();
    const inOneDayMs = dateObjToUnixms(
      getFutureDateObjFromUnixms(todayMs, 1, "days")
    );
    const inOneWeekMs = dateObjToUnixms(
      getFutureDateObjFromUnixms(todayMs, 1, "weeks")
    );

    const curUserDelegationIds = curUserDelegation.map((d) => d.id);

    // Add delegation filter
    if (isLeader) {
      query["leader.id"] = {
        $in: curUserDelegationIds
      };
    }

    if (selectStatus !== "all") query.status = selectStatus;
    if (dueDateOptions[selectStatus]) {
      if (selectDueDate !== "all") {
        switch (selectDueDate) {
          case "dueToday":
            query.dueDate = todayMs;
            break;
          case "dueInOneDay":
            query.dueDate = inOneDayMs;
            break;
          case "dueInOneWeek":
            query.dueDate = inOneWeekMs;
            break;
          case "onTime":
            query.onTime = true;
            break;
          case "late":
            query.late = true;
            break;
          default:
          // Do nothing
        }
      }
    }

    // Request access token
    const idToken = await getIdToken();

    try {
      const resItemsCount = await getCollectionCount(
        {
          collection: "Tasks",
          ...query
        },
        idToken
      );

      setItemsCount(resItemsCount.count);
    } catch (e) {}

    // Re-define query
    query = {
      collection: "Tasks",
      filter: {},
      skip: (page - 1) * count,
      limit: count,
      project: {
        _id: 0
      },
      confirm: true
    };

    // Add delegation filter
    if (isLeader) {
      query.filter["leader.id"] = {
        $in: curUserDelegationIds
      };
    }

    if (selectStatus !== "all") query.filter.status = selectStatus;
    if (dueDateOptions[selectStatus]) {
      if (selectDueDate !== "all") {
        switch (selectDueDate) {
          case "dueToday":
            query.filter.dueDate = todayMs;
            break;
          case "dueInOneDay":
            query.filter.dueDate = inOneDayMs;
            break;
          case "dueInOneWeek":
            query.filter.dueDate = inOneWeekMs;
            break;
          case "onTime":
            query.filter.onTime = true;
            break;
          case "late":
            query.filter.late = true;
            break;
          default:
          // Do nothing
        }
      }
    }

    try {
      res = await bulkGet(query, idToken);

      if (res.status === 200) {
        setItems(res.response);

        totalItems = res.response.length;
      } else {
        setItems([]);

        totalItems = 0;
      }
    } catch (e) {}

    return totalItems;
  };

  const handleSelectStatus = (option) => {
    setSelectStatus(option);
    setSelectDueDate("all");
  };

  const validateForm = () => {
    return selectStatus.length > 0 && selectDueDate.length > 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const numItems = await listItems(1, totalItemsPerPage);

      if (numItems) {
        toggleSearch();
      } else {
        showCustomModal({
          title: "Search results",
          message: "No results were found following that criteria"
        });
      }
    } catch (e) {}

    setIsLoading(false);
  };

  const CustomModal = () => {
    return (
      <Modal
        className="msgModal"
        returnFocusAfterClose={true}
        isOpen={openModal}
      >
        <ModalHeader toggle={toggleModal} close={closeBtn}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>
          <p>{modalMsg}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal} block>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const SearchResults = () => {
    let numberOfPages = 0;
    if (itemsCount % totalItemsPerPage === 0) {
      numberOfPages = Math.floor(itemsCount / totalItemsPerPage);
    } else {
      numberOfPages = Math.floor(itemsCount / totalItemsPerPage) + 1;
    }

    return items.length ? (
      <>
        <div className="new-search">
          <Button
            outline
            size="sm"
            onClick={newSearch}
            color="primary"
            style={{ float: "right" }}
          >
            New Search
          </Button>
        </div>
        <h5 className="card-title">Search results</h5>
        <GetPagination
          pages={numberOfPages}
          nextPage={nextPage}
          currentPage={currentPage}
          firstChange={firstChange}
          lastChange={lastChange}
          incChange={incChange}
        />
        <div className="mt-1rem">
          {items.map((item, i) => {
            const crudOn = curUserDelegation
              .map((d) => d.id)
              .includes(item.leader.id);

            return (
              <Task
                key={i}
                user={user}
                defTask={item}
                setEditTask={setEditTask}
                callback={updateItem}
                handleDeleteItem={deleteItem}
                crudOn={crudOn}
              />
            );
          })}
        </div>
      </>
    ) : (
      !isSearchOpen && (
        <>
          <div className="new-search">
            <Button
              outline
              size="sm"
              onClick={newSearch}
              color="primary"
              style={{ float: "right" }}
            >
              New Search
            </Button>
          </div>
          <h5 className="card-title">Search results</h5>
          <div className="mt-1rem">
            <CardText>
              No tasks left to display from the last search results...
            </CardText>
          </div>
        </>
      )
    );
  };

  return (
    <div className="TasksComp">
      <Card>
        <CardHeader className="bg-primary text-white" tag="h5">
          Task Management
        </CardHeader>
        <CardBody>
          <Collapse isOpen={isSearchOpen}>
            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit} id="search-form">
                  <FormGroup tag="fieldset">
                    <h5 className="card-title">Select task status</h5>
                    {statusOptions.map((el, i) => (
                      <FormGroup key={i} check>
                        <Label check>
                          <Input
                            type="radio"
                            name="status"
                            value={el.status}
                            defaultChecked={el.status === selectStatus}
                            disabled={isLoading}
                            onChange={(e) => handleSelectStatus(e.target.value)}
                          />{" "}
                          {el.label}
                        </Label>
                      </FormGroup>
                    ))}
                  </FormGroup>
                  {dueDateOptions[selectStatus] && (
                    <FormGroup tag="fieldset">
                      <h5 className="card-title">
                        Select task due date criteria
                      </h5>
                      {dueDateOptions[selectStatus].map((el, i) => (
                        <FormGroup key={i} check>
                          <Label check>
                            <Input
                              type="radio"
                              name="dueDate"
                              value={el.status}
                              checked={el.status === selectDueDate}
                              onChange={(e) => setSelectDueDate(e.target.value)}
                            />{" "}
                            {el.label}
                          </Label>
                        </FormGroup>
                      ))}
                    </FormGroup>
                  )}
                  <Button
                    className="btn-lg"
                    disabled={!validateForm() || isLoading}
                    color="primary"
                    block
                  >
                    Search {isLoading && <Spinner color="light" />}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Collapse>
          {!editTask && <SearchResults />}
          {editTask && (
            <TaskFormComp
              user={user}
              appSettings={appSettings}
              task={editTask}
              mode="update"
              callback={updateItem}
              handleGoBack={handleGoBack}
            />
          )}
        </CardBody>
      </Card>
      <CustomModal />
    </div>
  );
};

export default TasksComp;
