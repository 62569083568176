import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  Collapse,
  FormGroup,
  Label,
  Spinner
} from "reactstrap";
import {
  STATUS_ASSIGNED,
  STATUS_CANCELED,
  STATUS_COMPLETED,
  STATUS_NOT_COMPLETED,
  STATUS_PENDING
} from "../models/taskModels";
import { bulkUpdate } from "../services/bulkServices";
import { useMsal } from "@azure/msal-react";
import Moment from "react-moment";
import "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import "./TaskCompletion.css";

// Set the timezone for every instance.
Moment.globalTimezone = "America/Detroit";

// Set the output format for every react-moment instance.
Moment.globalFormat = "MM/DD/YYYY HH:mm";

const getTimestamp = () => new Date().getTime();
// const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;
// const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const TaskCompletion = (props) => {
  const {
    user,
    task,
    withDirectReports,
    handleUpdateTask,
    collapseState,
    isRecipient = null,
    taskColor
  } = props;

  const { instance, accounts } = useMsal();

  // Handle main collapse
  const [isOpen, setIsOpen] = useState(collapseState["manageCompletion"]);
  const [collapseIcon, setCollapseIcon] = useState(faChevronDown);
  const toggle = () => {
    const icon = !isOpen ? faChevronUp : faChevronDown;

    setIsOpen(!isOpen);
    setCollapseIcon(icon);
  };

  const [isUpdatingAll, setIsUpdatingAll] = useState(false);
  const [isConfirmingMarkAll, setIsConfirmingMarkAll] = useState(false);

  // Switches
  const isAssigned = task.status === STATUS_ASSIGNED;
  const isPending = task.status === STATUS_PENDING;
  const isNotCompleted = task.status === STATUS_NOT_COMPLETED;
  const isCanceled = task.status === STATUS_CANCELED;
  const showStats = !isPending && !isCanceled;
  const isTaskActive =
    task.status !== STATUS_PENDING && task.status !== STATUS_CANCELED;
  const showMarkAsCompleted =
    (isAssigned || isNotCompleted) && !isConfirmingMarkAll && !isRecipient;

  // Shorten variables
  const directReports = task.directReports;
  const groups = task.groups;
  const groupsCount = groups.length;
  const compTitle = isTaskActive ? "Manage Completion" : "Show Assignees";

  const getIdToken = async () => {
    const res = await instance.acquireTokenSilent({
      scopes: ["User.Read", "User.ReadBasic.All"],
      account: accounts[0]
    });

    return res.idToken;
  };

  const LeadersCard = (props) => {
    const {
      leaders,
      group,
      last,
      successCount,
      handleUpdateTask,
      isTaskActive
    } = props;

    const leadersCount = leaders.length;
    const completionRate = successCount / leadersCount;
    const isCompleted = completionRate === 1;
    const showFooter = !isCompleted && isTaskActive && !isRecipient;

    const [isUpdatingGroup, setIsUpdatingGroup] = useState(false);

    const [isOpen, setIsOpen] = useState(false);
    const [collapseIcon, setCollapseIcon] = useState(faChevronDown);
    const toggle = () => {
      const icon = !isOpen ? faChevronUp : faChevronDown;

      setIsOpen(!isOpen);
      setCollapseIcon(icon);
    };

    const LeaderCard = (props) => {
      const {
        leader,
        index,
        last,
        handleUpdateTask,
        isUpdatingGroup,
        isTaskActive
      } = props;

      const [isUpdating, setIsUpdating] = useState(false);

      const showFooter = isTaskActive && !leader.completed && !isUpdatingGroup;

      const ShowCompleted = () => {
        let completedTag = "No";
        let color = "default";

        if (leader.completed) {
          completedTag = leader.onTime ? "On-time" : "Late";
          color = leader.onTime ? "success" : "danger";
        }

        if (!isTaskActive && leader.completed) {
          completedTag = leader.onTime ? "On-time" : "Late";
        }

        if (isTaskActive && !leader.completed) color = "danger";

        return (
          <FormGroup className="mb-0rem">
            <Label>
              <b>Completed:</b> <Badge color={color}>{completedTag}</Badge>
            </Label>
          </FormGroup>
        );
      };

      const ShowCompletedOn = () => {
        return (
          leader.completedOn && (
            <FormGroup className="mb-0rem">
              <Label className="mb-0rem">
                <b>Completed on:</b>{" "}
                <Moment format="MM/DD/YYYY HH:mm:ss">
                  {leader.completedOn}
                </Moment>
              </Label>
            </FormGroup>
          )
        );
      };

      const updateLeaderCompletionState = (completion) => {
        const copyTask = { ...task };

        const copyLeader = {
          ...task.directReports[leader.jobTitle][index]
        };
        const assignments = task.stats.assignments;
        let tasksCompleted = task.stats.completed;
        let tasksNotCompleted = task.stats.notCompleted;
        let tasksOnTime = task.stats.onTime;
        let tasksLate = task.stats.late;

        // Update stats
        const stats = {};
        if (completion.completed) {
          tasksCompleted += 1;
          stats["completed"] = tasksCompleted;

          tasksNotCompleted -= 1;
          stats["notCompleted"] = tasksNotCompleted;
        }
        if (completion.onTime) {
          tasksOnTime += 1;
          stats["onTime"] = tasksOnTime;
        } else {
          tasksLate += 1;
          stats["late"] = tasksLate;
        }
        copyTask.stats = { ...task.stats, ...stats };

        // Update task completion
        if (assignments === tasksCompleted) {
          copyTask.status = STATUS_COMPLETED;
          copyTask.completedOn = completion.completedOn;

          if (assignments === tasksOnTime) {
            copyTask.onTime = true;
            copyTask.late = false;
          } else {
            copyTask.onTime = false;
            copyTask.late = true;
          }
        }

        // Update leader
        copyTask.directReports[leader.jobTitle][index] = {
          ...copyLeader,
          ...completion
        };

        handleUpdateTask(copyTask);
      };

      const handleSingleMarkAsCompleted = async () => {
        setIsUpdating(true);

        // Request access token
        const idToken = await getIdToken();

        const today = getTimestamp();
        const isOnTime = today < task.dueDate;
        const filter = {};
        const set = {};

        // Set leader query prop
        const leaderProp = `directReports.${leader.jobTitle}`;

        // Add to filter
        filter["id"] = task.id;
        filter[`${leaderProp}.id`] = leader.id;

        // Add to set
        set[`${leaderProp}.$.completed`] = true;
        set[`${leaderProp}.$.onTime`] = isOnTime;
        set[`${leaderProp}.$.completedOn`] = today;

        // Update task completion
        const assignments = task.stats.assignments;
        let tasksCompleted = task.stats.completed + 1;
        let tasksOnTime = task.stats.onTime;

        if (assignments === tasksCompleted) {
          set["status"] = STATUS_COMPLETED;
          set["completedOn"] = today;

          if (isOnTime) tasksOnTime += 1;

          if (assignments === tasksOnTime) {
            set["onTime"] = true;
            set["late"] = false;
          } else {
            set["onTime"] = false;
            set["late"] = true;
          }
        }

        const query = {
          collection: "Tasks",
          filter,
          update: {
            $set: set,
            $inc: {
              "stats.completed": 1,
              "stats.onTime": isOnTime ? 1 : 0,
              "stats.late": isOnTime ? 0 : 1,
              "stats.notCompleted": -1
            },
            $push: {},
            $pull: {},
            $setOnInsert: {}
          },
          options: {
            upsert: false
          },
          confirm: true
        };

        const res = await bulkUpdate(query, idToken);

        if (res.status === 200) {
          setIsUpdating(false);

          updateLeaderCompletionState({
            completed: true,
            onTime: isOnTime,
            completedOn: today
          });
        }
      };

      const ShowDirectReportsCount = () => {
        return leader.directReports > 0 ? leader.directReports : "None";
      };

      return (
        <Card className={last ? "mb-0rem" : "mb-1rem"}>
          <CardBody>
            <FormGroup className="mb-0rem">
              <Label>
                <b>Name:</b> {leader.displayName}
              </Label>
            </FormGroup>
            <FormGroup className="mb-0rem">
              <Label>
                <b>Department:</b> {leader.department}
              </Label>
            </FormGroup>
            <FormGroup className="mb-0rem">
              <Label>
                <b>Direct reports:</b> <ShowDirectReportsCount />
              </Label>
            </FormGroup>
            {!isPending && <ShowCompleted />}
            {!isPending && leader.completed && <ShowCompletedOn />}
          </CardBody>
          {showFooter && (
            <CardFooter>
              <Button
                className="ml-05rem"
                color={taskColor.color}
                size="sm"
                disabled={isUpdating}
                onClick={handleSingleMarkAsCompleted}
              >
                {!isRecipient ? leader.givenName : "I"} completed the task{" "}
                {isUpdating && <Spinner size="sm" color="light" />}
              </Button>
            </CardFooter>
          )}
        </Card>
      );
    };

    const updateGroupCompletionState = (leaderIndices, completion) => {
      const copyTask = { ...task };

      // Get current counts
      const assignments = task.stats.assignments;
      let tasksCompleted = task.stats.completed;
      let tasksNotCompleted = task.stats.notCompleted;
      let tasksOnTime = task.stats.onTime;
      let tasksLate = task.stats.late;

      // Update stats
      const stats = {};
      if (completion.completed) {
        tasksCompleted += leaderIndices.length;
        stats["completed"] = tasksCompleted;

        tasksNotCompleted -= leaderIndices.length;
        stats["notCompleted"] = tasksNotCompleted;
      }
      if (completion.onTime) {
        tasksOnTime += leaderIndices.length;
        stats["onTime"] = tasksOnTime;
      } else {
        tasksLate += leaderIndices.length;
        stats["late"] = tasksLate;
      }
      copyTask.stats = { ...task.stats, ...stats };

      // Update task completion
      if (assignments === tasksCompleted) {
        copyTask.status = STATUS_COMPLETED;
        copyTask.completedOn = completion.completedOn;

        if (assignments === tasksOnTime) {
          copyTask.onTime = true;
          copyTask.late = false;
        } else {
          copyTask.onTime = false;
          copyTask.late = true;
        }
      }

      // Update leaders completion
      for (let i = 0; i < leaderIndices.length; i++) {
        const leaderIndex = leaderIndices[i];
        const leader = leaders[leaderIndex];
        const copyLeader = {
          ...task.directReports[leader.jobTitle][leaderIndex]
        };

        // Update leader completion
        copyTask.directReports[leader.jobTitle][leaderIndex] = {
          ...copyLeader,
          ...completion
        };
      }

      handleUpdateTask(copyTask);
    };

    const handleGroupMarkAsCompleted = async () => {
      setIsUpdatingGroup(true);

      // Request access token
      const idToken = await getIdToken();

      const today = getTimestamp();
      const isOnTime = today < task.dueDate;
      const filter = {};
      const set = {};
      let leaderIndices = [];

      // Get current counts
      const assignments = task.stats.assignments;
      let tasksCompleted = task.stats.completed;
      let tasksOnTime = task.stats.onTime;

      // Add to filter
      filter["id"] = task.id;

      // Loop through all leaders
      for (let i = 0; i < leaders.length; i++) {
        const leader = leaders[i];

        // Skip completed
        if (leader.completed) continue;

        // Collect leader id to update
        leaderIndices.push(i);

        // Add to counts
        tasksCompleted += 1;
        tasksOnTime += isOnTime ? 1 : 0;

        // Set leader query prop
        const leaderProp = `directReports.${leader.jobTitle}`;

        // If group filter does not exist
        if (!filter[`${leaderProp}.id`]) {
          // Initialize leader id filter
          filter[`${leaderProp}.id`] = { $in: [] };

          // Add all leaders completion to set
          // it should only happen once
          set[`${leaderProp}.$[].completed`] = true;
          set[`${leaderProp}.$[].onTime`] = isOnTime;
          set[`${leaderProp}.$[].completedOn`] = today;
        }

        // Add leader id to filter
        filter[`${leaderProp}.id`]["$in"].push(leader.id);
      }

      // Update task stats
      if (assignments === tasksCompleted) {
        set["status"] = STATUS_COMPLETED;
        set["completedOn"] = today;

        if (assignments === tasksOnTime) {
          set["onTime"] = true;
          set["late"] = false;
        } else {
          set["onTime"] = false;
          set["late"] = true;
        }
      }

      // Calculate tasks updated
      const tasksUpdated = leaderIndices.length;

      // Build update query
      const query = {
        collection: "Tasks",
        filter,
        update: {
          $set: set,
          $inc: {
            "stats.completed": tasksUpdated,
            "stats.onTime": isOnTime ? tasksUpdated : 0,
            "stats.late": isOnTime ? 0 : tasksUpdated,
            "stats.notCompleted": -1 * tasksUpdated
          },
          $push: {},
          $pull: {},
          $setOnInsert: {}
        },
        options: {
          upsert: false
        },
        confirm: true
      };

      const res = await bulkUpdate(query, idToken);

      if (res.status === 200) {
        setIsUpdatingGroup(false);

        updateGroupCompletionState(leaderIndices, {
          completed: true,
          onTime: isOnTime,
          completedOn: today
        });
      }
    };

    // const bgColorClass = showStats
    //   ? completionRate === 1
    //     ? "success"
    //     : "danger"
    //   : "light";
    const bgColorClass = "default";
    // const bgTextColorClass = showStats ? "light" : "dark";
    const bgTextColorClass = "dark";
    const completionTag = (
      <i>({(completionRate * 100).toFixed(0)}% completed)</i>
    );

    return (
      <Card className={last ? "mb-0rem" : "mb-05rem"}>
        <CardHeader className={`bg-${bgColorClass} text-${bgTextColorClass}`}>
          {group} {showStats && completionTag}
          <Button
            className="collapse-button"
            size="sm"
            color={bgColorClass}
            onClick={toggle}
          >
            <FontAwesomeIcon icon={collapseIcon} />
          </Button>
        </CardHeader>
        <Collapse isOpen={isOpen}>
          <CardBody className="overflow-300">
            {leaders.map((leader, i) => {
              const last = leadersCount === i + 1;

              return (
                <LeaderCard
                  key={i}
                  leader={leader}
                  index={i}
                  last={last}
                  handleUpdateTask={handleUpdateTask}
                  isUpdatingGroup={isUpdatingGroup}
                  isTaskActive={isTaskActive}
                />
              );
            })}
          </CardBody>
          {showFooter && (
            <CardFooter>
              <Button
                className="ml-05rem"
                color={taskColor.color}
                size="sm"
                disabled={isUpdatingGroup}
                onClick={handleGroupMarkAsCompleted}
              >
                Mark group as completed{" "}
                {isUpdatingGroup && <Spinner size="sm" color="light" />}
              </Button>
            </CardFooter>
          )}
        </Collapse>
      </Card>
    );
  };

  const updateAllCompletionState = (leaderIndices, completion) => {
    const copyTask = { ...task };

    // Get current counts
    const assignments = task.stats.assignments;
    let tasksCompleted = task.stats.completed;
    let tasksNotCompleted = task.stats.notCompleted;
    let tasksOnTime = task.stats.onTime;
    let tasksLate = task.stats.late;

    // Update stats
    const stats = {};
    if (completion.completed) {
      tasksCompleted += leaderIndices.length;
      stats["completed"] = tasksCompleted;

      tasksNotCompleted -= leaderIndices.length;
      stats["notCompleted"] = tasksNotCompleted;
    }
    if (completion.onTime) {
      tasksOnTime += leaderIndices.length;
      stats["onTime"] = tasksOnTime;
    } else {
      tasksLate += leaderIndices.length;
      stats["late"] = tasksLate;
    }
    copyTask.stats = { ...task.stats, ...stats };

    // Update task completion
    if (assignments === tasksCompleted) {
      copyTask.status = STATUS_COMPLETED;
      copyTask.completedOn = completion.completedOn;

      if (assignments === tasksOnTime) {
        copyTask.onTime = true;
        copyTask.late = false;
      } else {
        copyTask.onTime = false;
        copyTask.late = true;
      }
    }

    // Update leaders completion
    for (let i = 0; i < leaderIndices.length; i++) {
      const leaderIndex = leaderIndices[i];
      const copyLeader = {
        ...task.directReports[leaderIndex.group][leaderIndex.index]
      };

      // Update leader completion
      copyTask.directReports[leaderIndex.group][leaderIndex.index] = {
        ...copyLeader,
        ...completion
      };
    }

    handleUpdateTask(copyTask);
  };

  const handleAllMarkAsCompleted = async () => {
    setIsUpdatingAll(true);

    // Request access token
    const idToken = await getIdToken();

    const today = getTimestamp();
    const isOnTime = today < task.dueDate;
    const filter = {};
    const set = {};
    let leaderIndices = [];

    // Get current counts
    const assignments = task.stats.assignments;
    let tasksCompleted = task.stats.completed;
    let tasksOnTime = task.stats.onTime;

    // Add to filter
    filter["id"] = task.id;

    // Gather direct reports
    const directReports = task.directReports;
    const groups = Object.keys(directReports);

    // Loop through job titles
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];

      // Gather leaders per job title
      const leaders = directReports[group];

      // Loop through leaders
      for (let j = 0; j < leaders.length; j++) {
        const leader = leaders[j];

        // Skip completed
        if (leader.completed) continue;

        // Collect leader id to update
        leaderIndices.push({ index: j, group });

        // Add to counts
        tasksCompleted += 1;
        tasksOnTime += isOnTime ? 1 : 0;

        // Set leader query prop
        const leaderProp = `directReports.${leader.jobTitle}`;

        // If group filter does not exist
        if (!filter[`${leaderProp}.id`]) {
          // Initialize leader id filter
          filter[`${leaderProp}.id`] = { $in: [] };

          // Add all leaders completion to set
          // it should only happen once
          set[`${leaderProp}.$[].completed`] = true;
          set[`${leaderProp}.$[].onTime`] = isOnTime;
          set[`${leaderProp}.$[].completedOn`] = today;
        }

        // Add leader id to filter
        filter[`${leaderProp}.id`]["$in"].push(leader.id);
      }

      // Update task stats
      if (assignments === tasksCompleted) {
        set["status"] = STATUS_COMPLETED;
        set["completedOn"] = today;

        if (assignments === tasksOnTime) {
          set["onTime"] = true;
          set["late"] = false;
        } else {
          set["onTime"] = false;
          set["late"] = true;
        }
      }
    }

    // Calculate tasks updated
    const tasksUpdated = leaderIndices.length;

    // Build update query
    const query = {
      collection: "Tasks",
      filter,
      update: {
        $set: set,
        $inc: {
          "stats.completed": tasksUpdated,
          "stats.onTime": isOnTime ? tasksUpdated : 0,
          "stats.late": isOnTime ? 0 : tasksUpdated,
          "stats.notCompleted": -1 * tasksUpdated
        },
        $push: {},
        $pull: {},
        $setOnInsert: {}
      },
      options: {
        upsert: false
      },
      confirm: true
    };

    const res = await bulkUpdate(query, idToken);

    if (res.status === 200) {
      setIsUpdatingAll(false);

      updateAllCompletionState(leaderIndices, {
        completed: true,
        onTime: isOnTime,
        completedOn: today
      });
    }
  };

  const MarkAllYesNo = () => {
    return (
      <>
        <CardText>Are you sure you want to mark all as completed?</CardText>
        <Button
          color="danger"
          size="sm"
          disabled={isUpdatingAll}
          onClick={() => setIsConfirmingMarkAll(false)}
        >
          Not sure
        </Button>
        <Button
          className="ml-05rem"
          color="success"
          size="sm"
          disabled={isUpdatingAll}
          onClick={handleAllMarkAsCompleted}
        >
          Yes, I'm sure {isUpdatingAll && <Spinner size="sm" color="light" />}
        </Button>
      </>
    );
  };

  return (
    <div className="TaskCompletion">
      <Card className="mb-15rem">
        <CardHeader>
          {compTitle}{" "}
          <Button
            className="collapse-button"
            size="sm"
            color="light"
            onClick={toggle}
          >
            <FontAwesomeIcon icon={collapseIcon} />
          </Button>
        </CardHeader>
        <Collapse isOpen={isOpen}>
          <CardBody className="overflow-1200">
            {showMarkAsCompleted && (
              <Button
                className="mb-1rem"
                color={taskColor.color}
                onClick={() => setIsConfirmingMarkAll(true)}
              >
                <b>Mark all as completed</b>
              </Button>
            )}
            {isConfirmingMarkAll && <MarkAllYesNo />}
            {!isUpdatingAll &&
              !isConfirmingMarkAll &&
              groups.sort().map((group, i) => {
                const last = groupsCount === i + 1;
                const successCount = directReports[group].filter(
                  (item) => item.completed
                ).length;
                let overrideLeaders = withDirectReports
                  ? directReports[group].filter(
                      (leader) => leader.directReports > 0
                    )
                  : directReports[group];

                // Exclude recipient from list
                if (isRecipient) {
                  overrideLeaders = overrideLeaders.filter((leader) => {
                    return leader.id === user.id;
                  });
                }

                return (
                  <LeadersCard
                    key={i}
                    group={group}
                    leaders={overrideLeaders}
                    last={last}
                    successCount={successCount}
                    handleUpdateTask={handleUpdateTask}
                    isTaskActive={isTaskActive}
                  />
                );
              })}
          </CardBody>
        </Collapse>
      </Card>
    </div>
  );
};

export default TaskCompletion;
