import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  Collapse,
  FormGroup,
  Label,
  Input,
  InputGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table
} from "reactstrap";
import {
  getUsersContains,
  transformLeaderObj
} from "../services/msGraphServices";
import { getLeaderDelegates } from "../services/orgTreeServices";
import { useMsal } from "@azure/msal-react";
import { phraseToProperCase } from "../libs/case-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "./DelegationsReportComp.css";

const DelegationsReportComp = () => {
  const { instance, accounts } = useMsal();

  const [leader, setLeader] = useState(null);
  const [delegates, setDelegates] = useState(null);

  const getIdToken = async () => {
    const res = await instance.acquireTokenSilent({
      scopes: ["User.Read", "User.ReadBasic.All"],
      account: accounts[0]
    });

    return res.idToken;
  };

  const getAccessToken = async () => {
    const res = await instance.acquireTokenSilent({
      scopes: ["User.Read", "User.Read.All", "Directory.Read.All", "Mail.Send"],
      account: accounts[0]
    });

    return res.accessToken;
  };

  // Handle collapse
  const [isSearchOpen, setIsSearchOpen] = useState(true);
  const toggleSearch = () => setIsSearchOpen(!isSearchOpen);

  const ShowSearch = (props) => {
    const { updateLeader, updateDelegates } = props;

    const [term, setTerm] = useState("");
    const [results, setResults] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedLeader, setSelectedLeader] = useState(null);

    const handleSearchTerm = (term) => {
      setTerm(term);
    };

    const handleSelectedLeader = async (option) => {
      const leader = JSON.parse(option);

      setSelectedLeader(leader);
    };

    const handleSearch = async () => {
      setIsLoading(true);

      // Request access token
      const accessToken = await getAccessToken();

      // Get users
      const usersRes = await getUsersContains(term, accessToken);

      const res = usersRes.value.map((item) => {
        return transformLeaderObj(item);
      });

      setResults(res);
      setIsLoading(false);
    };

    const handleReport = async () => {
      setIsLoading(true);

      // Get Id token
      const idToken = await getIdToken();

      // Get leader delegates
      const delegates = await getLeaderDelegates(selectedLeader.id, idToken);

      if (delegates && delegates.length > 0) {
        setIsLoading(false);
        updateDelegates(delegates);
        updateLeader(selectedLeader);

        toggleSearch();
      } else {
        setIsLoading(false);

        showCustomModal({
          title: "Search results",
          message: `No delegates were found for ${selectedLeader.displayName}.`
        });
      }
    };

    // Switches
    const isResults = results && results.length > 0;
    const emptyResults = results && results.length === 0;

    return (
      <Card className="mt-1rem">
        <CardBody>
          <FormGroup className="mb-0rem">
            <Label>
              <b>Search leader:</b>
            </Label>
          </FormGroup>
          <InputGroup>
            <Input
              type="text"
              bsSize="sm"
              name="search-leaders"
              id="search-leaders"
              value={term}
              disabled={isLoading}
              onChange={(e) => handleSearchTerm(e.target.value)}
            />
            <Button
              color="primary"
              size="sm"
              disabled={isLoading || term === ""}
              onClick={handleSearch}
            >
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </InputGroup>
          {emptyResults && (
            <CardText className="mt-1rem">
              No results following that criteria...
            </CardText>
          )}
          {isResults && (
            <FormGroup className="mt-1rem">
              <Label>
                <b>Pick a leader:</b>
              </Label>
              <Input
                bsSize="sm"
                value={JSON.stringify(selectedLeader)}
                type="select"
                id="select-leader"
                disabled={isLoading}
                onChange={(e) => handleSelectedLeader(e.target.value)}
              >
                <>
                  <option key="0" value="">
                    Select a leader from the list
                  </option>
                  {results.map((el, i) => {
                    return (
                      <option key={i + 1} value={JSON.stringify(el)}>
                        {el.displayName}
                      </option>
                    );
                  })}
                </>
              </Input>
            </FormGroup>
          )}
        </CardBody>
        <CardFooter>
          <Button
            color="primary"
            size="sm"
            disabled={isLoading || !selectedLeader}
            onClick={handleReport}
          >
            Get report
          </Button>
        </CardFooter>
      </Card>
    );
  };

  const ShowReport = () => {
    const newSearch = () => {
      toggleSearch();
    };

    return (
      <Card>
        <CardHeader className="bg-primary text-white" tag="h5">
          {leader.displayName}
          <Button
            outline
            size="sm"
            onClick={newSearch}
            color="light"
            style={{ float: "right" }}
          >
            New Search
          </Button>
        </CardHeader>
        <CardBody>
          <div className="overflow-500">
            <Table responsive size="sm">
              <thead>
                <tr>
                  <th>Delegate</th>
                  <th>Role</th>
                  <th>Email</th>
                  <th>Registration</th>
                </tr>
              </thead>
              <tbody>
                {delegates.map((delegate, i) => {
                  return (
                    <tr key={i}>
                      <td>{delegate.name}</td>
                      <td>{phraseToProperCase(delegate.role)}</td>
                      <td>{delegate.email}</td>
                      <td>{phraseToProperCase(delegate.registration)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    );
  };

  // Control modal
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const toggleModal = () => setOpenModal(!openModal);
  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      &times;
    </button>
  );

  const showCustomModal = (params) => {
    const { title, message } = params;

    setModalTitle(title);
    setModalMsg(message);
    toggleModal();
  };

  const CustomModal = () => {
    return (
      <Modal
        className="msgModal"
        returnFocusAfterClose={true}
        isOpen={openModal}
      >
        <ModalHeader toggle={toggleModal} close={closeBtn}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>{modalMsg}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal} block>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  return (
    <div className="DelegationsReportComp">
      <Card>
        <CardHeader className="bg-primary text-white" tag="h5">
          Leader Delegation Report
        </CardHeader>
        <CardBody>
          <Collapse isOpen={isSearchOpen}>
            <ShowSearch
              updateLeader={setLeader}
              updateDelegates={setDelegates}
            />
          </Collapse>
          {!isSearchOpen && delegates && <ShowReport />}
        </CardBody>
      </Card>
      <CustomModal />
    </div>
  );
};

export default DelegationsReportComp;
