import React, { useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner
} from "reactstrap";
import AdminGrantModal from "./AdminGrantModal";
import classnames from "classnames";
import { updateUser, deleteUser } from "../services/userService";
import {
  getUsersContains,
  transformLeaderObj
} from "../services/msGraphServices";
import { getUserRoles } from "../models/UserRoles";
import { triggerLeaderOrdTreeUpdate } from "../services/orgTreeServices";
import { bulkGet, bulkUpdate } from "../services/bulkServices";
import { useMsal } from "@azure/msal-react";
import { MailMessage } from "../models/Message";
import { phraseToProperCase } from "../libs/case-utils";
import Moment from "react-moment";
import "moment-timezone";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { appInfo } from "../appInfo";
import "./User.css";

const stage = appInfo.stage;

// Set the timezone for every instance.
Moment.globalTimezone = "America/Detroit";

// Set the output format for every react-moment instance.
Moment.globalFormat = "MM/DD/YYYY HH:mm";

const getTimestamp = () => new Date().getTime();

const adminRoles = ["admin", "it-admin"];

const User = (props) => {
  const { selectedUser, systemUser, sendEmail } = props;

  const { instance, accounts } = useMsal();

  const [user, setUser] = useState(selectedUser);
  const [warnings, setWarnings] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false);

  // Control tabs
  const [activeTab, setActiveTab] = useState("1");
  const toggleTabs = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const roleOptions = getUserRoles(systemUser.role);

  const handleSetUser = (user, warnings = null) => {
    if (warnings) setWarnings(warnings);

    setUser(user);
  };

  const getIdToken = async () => {
    const res = await instance.acquireTokenSilent({
      scopes: ["User.Read", "User.ReadBasic.All"],
      account: accounts[0]
    });

    return res.idToken;
  };

  const getAccessToken = async () => {
    const res = await instance.acquireTokenSilent({
      scopes: ["User.Read", "User.Read.All", "Directory.Read.All", "Mail.Send"],
      account: accounts[0]
    });

    return res.accessToken;
  };

  const getHeaderClass = () => {
    let status = null;

    switch (user.registration) {
      case "approved":
        status = "bg-success text-white";
        break;
      case "rejected":
        status = "bg-danger text-white";
        break;
      case "revoked":
        status = "bg-danger text-white";
        break;
      case "pending":
        status = "bg-warning text-white";
        break;
      default:
        status = "bg-warning text-white";
    }

    return status;
  };

  const sendApprovalNotification = async (status) => {
    // Set mail delegate
    const fromRecipient = {
      name: systemUser.name,
      address: systemUser.email
    };

    // Set to recipients
    const toRecipients = [
      {
        name: user.name,
        address: user.email
      }
    ];

    // Set message subject
    const subject = `${appInfo[stage].longName}: Registration request`;

    // Set message according to status
    let statusMessage = `Dear ${user.name},\n\nYour request to use the ${appInfo[stage].longName} System was approved.\n\nThank you,\n${systemUser.name}`;
    if (status === "rejected")
      statusMessage = `Dear ${user.name},\n\nYour request to use the ${appInfo[stage].longName} System was rejected.\n\nThank you,\n${systemUser.name}`;

    // Set message body
    const body = {
      content: statusMessage
    };

    // Set one attachment
    const attachments = [];

    // Set Cc list
    const ccRecipients = [];

    // Set Bcc list
    const BccRecipients = [];

    const message = MailMessage(
      fromRecipient,
      toRecipients,
      subject,
      body,
      attachments,
      ccRecipients,
      BccRecipients,
      true
    );

    sendEmail(message);
  };

  // Control modal
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const toggleModal = () => setOpenModal(!openModal);
  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      &times;
    </button>
  );

  const ErrorModal = () => {
    return (
      <Modal
        className="msgModal"
        returnFocusAfterClose={true}
        isOpen={openModal}
      >
        <ModalHeader toggle={toggleModal} close={closeBtn}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>
          <p>{modalMsg}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal} block>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const showCustomModal = (params) => {
    const { title, message } = params;

    setModalTitle(title);
    setModalMsg(message);
    toggleModal();
  };

  const EditButton = (props) => {
    const { handleEdit } = props;

    return (
      <Button
        outline
        color="primary"
        size="sm"
        onClick={() => handleEdit(true)}
      >
        Edit
      </Button>
    );
  };

  const DeleteButton = (props) => {
    const { handleDelete } = props;

    return (
      <Button
        outline
        color="danger"
        size="sm"
        className="ml-05rem"
        onClick={() => handleDelete(true)}
      >
        Delete
      </Button>
    );
  };

  const SaveCancelButtons = (props) => {
    const { handleSave, handleCancel, isLoading, isError } = props;

    return (
      <>
        <Button
          color="primary"
          outline
          size="sm"
          onClick={handleSave}
          disabled={isError}
        >
          Save {isLoading && <Spinner size="sm" color="light" />}
        </Button>
        <Button color="default" size="sm" onClick={handleCancel}>
          Cancel
        </Button>
      </>
    );
  };

  const DeleteCancelButtons = (props) => {
    const { handleDelete, handleCancelDelete, isLoading } = props;

    return (
      <>
        <CardText>Are you sure you want to delete this user?</CardText>
        <Button color="danger" size="sm" onClick={handleCancelDelete}>
          Not sure
        </Button>
        <Button
          className="ml-05rem"
          color="success"
          size="sm"
          onClick={handleDelete}
        >
          Yes, I'm sure {isLoading && <Spinner size="sm" color="light" />}
        </Button>
      </>
    );
  };

  const GeneralTab = (props) => {
    const {
      userId,
      systemUser,
      role: defRole,
      org: defOrganization,
      registration: defRegistration,
      delegation: defDelegation,
      handleUserUpdate
    } = props;

    const itsMe = userId === systemUser.id;
    const [showAdminGrant, setShowAdminGrant] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [role, setRole] = useState(defRole);
    const [registration, setRegistration] = useState(defRegistration);
    const [organization, setOrganization] = useState(defOrganization);
    const [orgError, setOrgError] = useState(null);
    const [delegation, setDelegation] = useState(defDelegation);
    const [isLoading, setIsLoading] = useState(false);
    const [isAdding, setIsAdding] = useState(false);

    // Switches
    const showNobody = !isEditing && delegation.length === 0;

    const validateInput = () => {
      const isEmpty = organization === "";
      const passedTest = /^[A-Za-z0-9 ]*$/.test(organization);

      if (isEmpty || !passedTest) {
        setOrgError("Field can't be empty or last character is invalid");

        return false;
      } else {
        setOrgError(null);

        return true;
      }
    };

    const getOrgTreesByMail = async (arr, idToken) => {
      const query = {
        collection: "OrgTrees",
        filter: {
          "tree.mail": {
            $in: arr
          }
        },
        sort: {},
        skip: null,
        limit: null,
        project: {
          _id: 0,
          "tree.mail": 1
        },
        confirm: true
      };

      const res = await bulkGet(query, idToken);

      if (res.status === 200) {
        return res.response.map((item) => item.tree.mail);
      } else {
        return [];
      }
    };

    const updateSkippedAccounts = async (accnts, idToken) => {
      const query = {
        collection: "Users",
        filter: {
          id: userId,
          "delegation.mail": {
            $in: accnts
          }
        },
        update: {
          $set: {
            "delegation.$[].updatedAt": getTimestamp()
          },
          $push: {},
          $pull: {},
          $setOnInsert: {}
        },
        options: {
          upsert: false
        },
        confirm: true
      };

      await bulkUpdate(query, idToken);
    };

    const requestDelegationUpdate = async (idToken) => {
      const defAccnts = defDelegation.map((accnt) => accnt.mail);
      const allAccnts = delegation.map((accnt) => accnt.mail);
      const newAccnts = _.difference(allAccnts, defAccnts);

      if (newAccnts.length > 0) {
        // Check if org trees already exist
        const skipAccnts = await getOrgTreesByMail(newAccnts, idToken);

        // Update timestamp of skipped accounts
        await updateSkippedAccounts(skipAccnts, idToken);

        // Get org trees to update
        const final = _.difference(newAccnts, skipAccnts);

        if (final.length > 0) {
          const update = await triggerLeaderOrdTreeUpdate(final, idToken);

          return { count: final.length, runId: update.runId };
        } else {
          return { count: 0 };
        }
      }

      return { count: 0 };
    };

    const handleSave = async () => {
      if (!validateInput()) return false;

      // Request access token
      const idToken = await getIdToken();

      const isNewAdmin =
        adminRoles.includes(role) && registration === "approved";
      const newRegistration = defRole === "guest" && defRole !== role;
      const rejectedGuest = role === "guest" && registration === "rejected";
      const notify = newRegistration || rejectedGuest;

      setIsLoading(true);

      await updateUser(
        userId,
        {
          org: organization,
          role,
          registration,
          prefs: {
            notifications: {
              registration: isNewAdmin
            }
          },
          delegation
        },
        idToken
      ).then(async () => {
        setIsEditing(false);
        setIsLoading(false);

        // Send notification when registration is approved
        if (notify) sendApprovalNotification(registration);

        // Initialize warnings
        let warnings = { delegation: null };

        // Request update of org trees
        const updateDelegation = await requestDelegationUpdate(idToken);

        if (updateDelegation.count > 0) warnings.delegation = updateDelegation;

        handleUserUpdate(
          {
            ...user,
            org: organization,
            role,
            registration,
            delegation
          },
          warnings
        );
      });
    };

    const handleCancel = () => {
      setOrganization(defOrganization);
      setRole(defRole);
      setRegistration(defRegistration);
      setDelegation(defDelegation);

      setIsEditing(false);
      setOrgError(null);
    };

    const handleDelete = async () => {
      setIsLoading(true);

      // Request access token
      const idToken = await getIdToken();

      await deleteUser(userId, idToken).then((results) => {
        setIsDeleting(false);
        setIsLoading(false);

        if (results.deletedCount && results.deletedCount > 0) {
          setIsDeleted(true);
        } else {
          showCustomModal({
            title: "Error Deleting User",
            message:
              "Something wrong happened trying to delete user. Please contact the administrator."
          });
        }
      });
    };

    const handleCancelDelete = () => {
      setIsDeleting(false);
    };

    const handleRole = (role) => {
      if (adminRoles.includes(role)) {
        setRole(role);
        setShowAdminGrant(true);
      } else {
        setRole(role);
      }

      if (role === "guest") {
        setRegistration("pending");
      }
    };

    const RoleOption = () => {
      const isApproved = registration === "approved";
      const isPending = registration === "pending";
      const isRejected = registration === "rejected";
      const roleClass = isApproved
        ? "success"
        : isPending
        ? "warning"
        : isRejected
        ? "danger"
        : "default";

      return isEditing ? (
        <Input
          className="Role"
          value={role}
          type="select"
          id={`role-${userId}`}
          onChange={(e) => handleRole(e.target.value)}
        >
          {roleOptions.map((el, i) => {
            return (
              <option key={i} value={el.role}>
                {el.label}
              </option>
            );
          })}
        </Input>
      ) : (
        <Badge color={roleClass} className="text-uppercase text-white">
          {phraseToProperCase(role)}
        </Badge>
      );
    };

    const handleAdminGrant = (role, status) => {
      setRole(role);
      setRegistration(status);
    };

    const RegistrationSelect = () => {
      const guestOptions = ["pending", "rejected"];
      let defOptions = ["approved", "revoked"];

      if (role === "guest") defOptions = guestOptions;

      let overideRegistration = registration;
      if (!defOptions.includes(registration)) {
        overideRegistration = defOptions[0];

        setRegistration(overideRegistration);
      }

      return (
        <Input
          className="text-capitalize Registration"
          defaultValue={overideRegistration}
          type="select"
          id={`registration-${userId}`}
          onChange={(e) => setRegistration(e.target.value)}
        >
          {defOptions.map((o, i) => (
            <option key={i} value={o}>
              {o}
            </option>
          ))}
        </Input>
      );
    };

    const RegistrationOption = () => {
      const isApproved = registration === "approved";
      const isPending = registration === "pending";
      const isRejected = registration === "rejected";
      const registrationClass = isApproved
        ? "success"
        : isPending
        ? "warning"
        : isRejected
        ? "danger"
        : "default";

      return isEditing ? (
        <RegistrationSelect />
      ) : (
        <Badge color={registrationClass} className="text-uppercase text-white">
          {registration}
        </Badge>
      );
    };

    const handleOrganization = (organization) => {
      const isEmpty = organization === "";
      const passedTest = /^[A-Za-z0-9 ]*$/.test(organization);

      if (isEmpty || !passedTest) {
        setOrgError("Field cannot be empty or the last character is invalid");
        setOrganization(organization);

        return false;
      } else {
        setOrgError(null);
        setOrganization(organization);

        return true;
      }
    };

    const ShowEditDelegation = () => {
      const handleRemove = (index) => {
        let copyDelegation = [];

        // Remove item from array
        for (let i = 0; i < delegation.length; i++) {
          if (i !== index) copyDelegation.push(delegation[i]);
        }

        setDelegation(copyDelegation);
      };

      return (
        <>
          <Button
            className="mb-05rem"
            color="link"
            size="sm"
            onClick={() => setIsAdding(true)}
          >
            + Add new delegation
          </Button>
          <ListGroup className="mb-1rem overflow-300">
            {delegation.map((leader, i) => {
              return (
                <ListGroupItem key={i}>
                  {leader.displayName}
                  <hr className="hr-margin" />
                  <Button
                    outline
                    className="mt-05rem"
                    color="danger"
                    size="sm"
                    onClick={() => handleRemove(i)}
                  >
                    remove
                  </Button>
                </ListGroupItem>
              );
            })}
          </ListGroup>
        </>
      );
    };

    const ShowDelegation = () => {
      return (
        <ListGroup className="mt-1rem overflow-300">
          {delegation.map((leader, i) => {
            return (
              <ListGroupItem key={i}>
                {leader.displayName}
                {leader.updatedAt && (
                  <>
                    <br />
                    <small>
                      <i>
                        Last update:{" "}
                        <Moment format="MM/DD/YYYY HH:mm:ss">
                          {leader.updatedAt}
                        </Moment>
                      </i>
                    </small>
                  </>
                )}
              </ListGroupItem>
            );
          })}
        </ListGroup>
      );
    };

    const ShowAddDelegation = () => {
      const [term, setTerm] = useState("");
      const [results, setResults] = useState(null);
      const [isSearching, setIsSearching] = useState(false);
      const [selectedLeader, setSelectedLeader] = useState(null);

      const handleSearchTerm = (term) => {
        setTerm(term);
      };

      const handleDelegation = (option) => {
        const [id, displayName, mail] = option.split(",");

        setSelectedLeader({ id, displayName, mail });
      };

      const handleSearch = async () => {
        setIsSearching(true);

        // Request access token
        const accessToken = await getAccessToken();

        // Get users
        const usersRes = await getUsersContains(term, accessToken);

        // Transform results
        const results = usersRes.value.map((item) => {
          return transformLeaderObj(item);
        });

        setResults(results);

        setIsSearching(false);
      };

      const saveDelegation = () => {
        let copyDelegation = [...delegation];

        copyDelegation.push({ ...selectedLeader, updatedAt: null });

        copyDelegation = _.sortBy(copyDelegation, "displayName");

        setIsAdding(false);
        setDelegation(copyDelegation);
      };

      // Switches
      const isResults = results && results.length > 0;
      const emptyResults = results && results.length === 0;

      // Get delegation ids
      const ids = delegation.map((item) => item.id);

      return (
        <Card className="mt-1rem">
          <CardBody>
            <FormGroup className="mb-0rem">
              <Label>
                <b>Search leaders:</b>
              </Label>
            </FormGroup>
            <InputGroup>
              <Input
                type="text"
                bsSize="sm"
                name="search-leaders"
                id="search-leaders"
                value={term}
                disabled={isSearching}
                onChange={(e) => handleSearchTerm(e.target.value)}
              />
              <Button
                color="primary"
                size="sm"
                disabled={isSearching || term === ""}
                onClick={handleSearch}
              >
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </InputGroup>
            {emptyResults && (
              <CardText className="mt-1rem">
                No results following that criteria...
              </CardText>
            )}
            {isResults && (
              <>
                <FormGroup className="mt-1rem">
                  <Label>
                    <b>Results:</b>
                  </Label>
                  <Input
                    bsSize="sm"
                    value={`${selectedLeader?.id ?? ""},${
                      selectedLeader?.displayName ?? ""
                    },${selectedLeader?.mail}`}
                    type="select"
                    id="delegation"
                    onChange={(e) => handleDelegation(e.target.value)}
                  >
                    <>
                      <option key="0" value="">
                        Select a leader from the list
                      </option>
                      {results.map((el, i) => {
                        return (
                          <option
                            key={i + 1}
                            value={`${el.id},${el.displayName},${el.mail}`}
                            disabled={ids.includes(el.id)}
                          >
                            {el.displayName}
                          </option>
                        );
                      })}
                    </>
                  </Input>
                </FormGroup>
              </>
            )}
          </CardBody>
          <CardFooter>
            <Button
              className=""
              color="primary"
              size="sm"
              disabled={!selectedLeader}
              onClick={saveDelegation}
            >
              Add delegation
            </Button>
            <Button
              className="ml-025rem"
              color="danger"
              size="sm"
              disabled={isSearching}
              onClick={() => setIsAdding(false)}
            >
              Cancel
            </Button>
          </CardFooter>
        </Card>
      );
    };

    const ShowDelegationWarnings = () => {
      const warning = warnings.delegation;
      const isPlural = warning.count > 1;

      return (
        <Alert className="mt-1rem mb-0rem">
          <h5 className="alert-heading">Delegation update</h5>
          <p>
            You added {warning.count} leader{isPlural ? "s" : ""} more to list
            of leaders {user.name} manages tasks for.
          </p>
          <p>OnTrack has started processing these additions.</p>
          <p>
            It might take a few minutes before {user.name} can start managing
            tasks for the newly added leaders.
          </p>
        </Alert>
      );
    };

    return (
      <>
        <TabPane tabId="1">
          <CardBody>
            <CardText>
              <b>Name:</b> {user.name}
            </CardText>
            <CardText>
              <b>Email:</b> {user.email}
            </CardText>
            <CardText>
              <b>Registered since:</b> <Moment>{user.createdAt}</Moment>
            </CardText>
            <CardText>
              <b>Organization:</b>{" "}
              {!isEditing && (organization === "" ? "Not set" : organization)}
            </CardText>
            {isEditing && (
              <>
                <Input
                  className="Org"
                  invalid={orgError ? true : false}
                  type="text"
                  name={`organization-name-${userId}`}
                  id={`organization-id-${userId}`}
                  value={organization}
                  disabled={!isEditing}
                  onChange={(e) => handleOrganization(e.target.value)}
                />
                {orgError && (
                  <FormFeedback className="Org">{orgError}</FormFeedback>
                )}
              </>
            )}
            <CardText>
              <b>Role:</b> <RoleOption />
            </CardText>
            <CardText>
              <b>Registration:</b> <RegistrationOption />
            </CardText>
            <CardText className="mb-0rem">
              <b>Manages tasks for:</b> {showNobody ? "Nobody" : ""}
            </CardText>
            {!isEditing && <ShowDelegation />}
            {isEditing && !isAdding && <ShowEditDelegation />}
            {isEditing && isAdding && <ShowAddDelegation />}
            {!isEditing && warnings?.delegation && <ShowDelegationWarnings />}
          </CardBody>
          <CardFooter>
            {!isEditing && !isDeleting && (
              <EditButton handleEdit={setIsEditing} />
            )}
            {isEditing && (
              <SaveCancelButtons
                handleSave={handleSave}
                handleCancel={handleCancel}
                isLoading={isLoading}
                isError={orgError !== null}
              />
            )}
            {!isDeleting && !itsMe && !isEditing && (
              <DeleteButton handleDelete={setIsDeleting} />
            )}
            {isDeleting && (
              <DeleteCancelButtons
                handleDelete={handleDelete}
                handleCancelDelete={handleCancelDelete}
                isLoading={isLoading}
              />
            )}
          </CardFooter>
        </TabPane>
        <AdminGrantModal
          role={role}
          show={showAdminGrant}
          setShow={setShowAdminGrant}
          onYes={handleAdminGrant}
          prevGrant={{
            role: selectedUser.role,
            status: selectedUser.registration
          }}
        />
      </>
    );
  };

  return (
    !isDeleted && (
      <div className="User">
        <Card className="UserCard">
          <CardHeader tag="h5" className={getHeaderClass()}>
            {user.name}
          </CardHeader>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggleTabs("1");
                }}
              >
                General
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <GeneralTab
              userId={user.id}
              systemUser={systemUser}
              role={user.role}
              org={user.org}
              registration={user.registration}
              prefs={user.prefs}
              delegation={user.delegation}
              handleUserUpdate={handleSetUser}
            />
          </TabContent>
        </Card>
        <ErrorModal />
      </div>
    )
  );
};

export default User;
