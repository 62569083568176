import React, { useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardText,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  Spinner
} from "reactstrap";
import { createUser } from "../services/userService";
import { getAdminMailPrefs } from "../services/mail";
import { useGetAppSettings } from "../components/useGetAppSettings";
import { useMsal } from "@azure/msal-react";
import { MailMessage } from "../models/Message";
import { appInfo } from "../appInfo";
import "./Guest.css";

const stage = appInfo.stage;

const getRegRecipients = (adminMailPrefs) => {
  const admins = adminMailPrefs.users.map((u) => {
    if (u.notifications.registration) return { name: u.name, address: u.email };

    return null;
  });

  return admins.filter((u) => u);
};

const Guest = (props) => {
  const { user, sendEmail } = props;

  const { instance, accounts } = useMsal();

  // const registration = user.registration ?? null;
  const [registration, setRegistration] = useState(user.registration ?? null);
  const [requested, setRequested] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [error, setError] = useState(false);
  const [areThereAdmins, setAreThereAdmins] = useState(true);

  // Pull all app settings
  const appSettings = useGetAppSettings();

  // Check for registration mode
  const regSetting = appSettings?.registration;
  const regModeAutomatic = regSetting?.automatic;

  const getIdToken = async () => {
    const res = await instance.acquireTokenSilent({
      scopes: ["User.Read", "User.ReadBasic.All"],
      account: accounts[0]
    });

    return res.idToken;
  };

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/"
    });
  };

  const sendRequest = async (adminMailPrefs) => {
    const regRecipients = getRegRecipients(adminMailPrefs);

    // Set mail delegate
    const fromRecipient = {
      name: user.name,
      address: user.email
    };

    // Set to recipients
    const toRecipients = regRecipients;

    // Set message subject
    const subject = `${appInfo[stage].longName}: Access request`;

    const adminGreet = () => {
      return regRecipients.length === 1
        ? regRecipients[0].name
        : "Administrators";
    };

    // Set message body
    const body = {
      content: `Dear ${adminGreet()},\n\nI'm requesting access to the ${
        appInfo[stage].longName
      } System.\n\nThank you,\n${user.name}`
    };

    // Set one attachment
    const attachments = [];

    // Set Cc list
    const ccRecipients = [];

    // Set Bcc list
    const BccRecipients = [];

    const message = MailMessage(
      fromRecipient,
      toRecipients,
      subject,
      body,
      attachments,
      ccRecipients,
      BccRecipients,
      true
    );

    // Send email request
    sendEmail(message, setResults);
  };

  const handleRequest = async () => {
    // Request access token
    const idToken = await getIdToken();

    // Set def admin mail prefs
    let adminMailPrefs = null;

    if (!regModeAutomatic) {
      adminMailPrefs = await getAdminMailPrefs(idToken);

      if (adminMailPrefs === null) {
        setAreThereAdmins(false);

        return false;
      }
    }

    // Set guest user object
    let guest = null;

    if (regModeAutomatic) {
      // Approves DTE authenticated leaders
      guest = {
        id: user.id,
        name: user.name,
        org: regSetting.approve.org,
        email: user.email,
        role: regSetting.approve.role,
        prefs: {
          notifications: {
            registration: false
          }
        },
        delegation: [],
        registration: "approved"
      };
    } else {
      // Goes through registration approval
      guest = {
        id: user.id,
        name: user.name,
        org: "DTE",
        email: user.email,
        role: user.role,
        prefs: {
          notifications: {
            registration: false
          }
        },
        delegation: [],
        registration: "pending"
      };
    }

    setIsLoading(true);

    await createUser(guest, idToken)
      .then(async () => {
        if (!regModeAutomatic) await sendRequest(adminMailPrefs);

        setIsLoading(false);

        if (!regModeAutomatic) setRequested(true);
        if (regModeAutomatic) setRegistration("approved");
      })
      .catch((e) => {
        setError(new Error("Asynchronous error"));
      });
  };

  const RequestRegistration = () => {
    return (
      <Card>
        <CardHeader className="bg-primary text-white" tag="h5">
          User access
        </CardHeader>
        <CardBody>
          <CardTitle>
            {user.name}, you are currently not authorized to use this
            application.
          </CardTitle>
          <CardText>
            If you wish to request access, please click on the button below.
          </CardText>
          {error && (
            <Alert color="danger">
              Having trouble contacting the server. Please contact the
              administrator or try again later.
            </Alert>
          )}
          {!results && (
            <Button
              color="primary"
              disabled={isLoading}
              onClick={handleRequest}
            >
              Request access{" "}
              {isLoading && <Spinner size="sm" color="primary" />}
            </Button>
          )}
        </CardBody>
      </Card>
    );
  };

  const RequestRegistrationAuto = () => {
    return (
      <Card>
        <CardHeader className="bg-primary text-white" tag="h5">
          User access
        </CardHeader>
        <CardBody>
          <CardTitle>{user.name}, you logged in for the first time.</CardTitle>
          <CardText>
            If you wish to request access, please click on the button below.
          </CardText>
          {error && (
            <Alert color="danger">
              Having trouble contacting the server. Please contact the
              administrator or try again later.
            </Alert>
          )}
          {!results && (
            <Button
              color="primary"
              disabled={isLoading}
              onClick={handleRequest}
            >
              Request access{" "}
              {isLoading && <Spinner size="sm" color="primary" />}
            </Button>
          )}
        </CardBody>
      </Card>
    );
  };

  const ApprovedRegistration = () => {
    return (
      <Card>
        <CardHeader className="bg-primary text-white" tag="h5">
          User access
        </CardHeader>
        <CardBody>
          <Alert color="success">
            <CardText>
              {user.name}, your request for access has been approved by the
              administrators.
            </CardText>
            <Nav pills>
              <NavItem>
                <NavLink active href="/">
                  Continue
                </NavLink>
              </NavItem>
            </Nav>
          </Alert>
        </CardBody>
      </Card>
    );
  };

  const PendingRegistration = () => {
    return (
      <Card>
        <CardHeader className="bg-primary text-white" tag="h5">
          User access
        </CardHeader>
        <CardBody>
          <Alert color="danger">
            <h4 className="alert-heading">Important!</h4>
            <p className="mb-0">
              {user.name}, your request for access is currently being reviewed
              by the administrators. Please log out now and come back after you
              receive the welcome email from the administrator who approved your
              request.
            </p>
            <Button
              className="mt-1rem"
              size="sm"
              color="danger"
              onClick={handleLogout}
            >
              Log Out
            </Button>
          </Alert>
        </CardBody>
      </Card>
    );
  };

  const RejectedRegistration = () => {
    return (
      <Card>
        <CardHeader className="bg-primary text-white" tag="h5">
          User access
        </CardHeader>
        <CardBody>
          <CardTitle>
            {user.name}, your request for access was rejected by the
            administrator.
          </CardTitle>
        </CardBody>
      </Card>
    );
  };

  const SuspendedRegistration = () => {
    return (
      <Card>
        <CardHeader className="bg-primary text-white" tag="h5">
          User access
        </CardHeader>
        <CardBody>
          <CardTitle>
            {user.name}, your access to this application is currently suspended
            until further notice.
          </CardTitle>
        </CardBody>
      </Card>
    );
  };

  return appSettings ? (
    <div className="Guest">
      {!regModeAutomatic && !areThereAdmins && <SuspendedRegistration />}
      {!regModeAutomatic && !registration && areThereAdmins && !requested && (
        <RequestRegistration />
      )}
      {regModeAutomatic && !registration && <RequestRegistrationAuto />}
      {(registration === "pending" || requested) && <PendingRegistration />}
      {registration === "rejected" && <RejectedRegistration />}
      {registration === "approved" && <ApprovedRegistration />}
    </div>
  ) : (
    <div className="Guest">
      <div className="container">
        <Alert color="primary">
          <Spinner color="primary" size="sm" type="grow" /> Loading app
          settings...
        </Alert>
      </div>
    </div>
  );
};

export default Guest;
