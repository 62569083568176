import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  Collapse,
  Button,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Badge
} from "reactstrap";
import { useGetTopTree } from "../components/useGetTopTree";
import {
  getUsersContains,
  transformLeaderObj
} from "../services/msGraphServices";
import {
  getTaskWithinStartDate,
  getTaskAssignees,
  findNode
} from "../services/orgTreeServices";
import {
  pushToEndOfDayUnixms,
  getPastDateFromUnixms,
  getFutureDateFromUnixms,
  getTodayDateUnixms,
  getDiffFromNowInMs
} from "../services/dateTimeUtils";
import {
  STATUS_ASSIGNED,
  STATUS_COMPLETED,
  STATUS_NOT_COMPLETED
} from "../models/taskModels";
import { useMsal } from "@azure/msal-react";
import TreeModel from "tree-model";
import { PieChart } from "react-minimal-pie-chart";
import { phraseToProperCase } from "../libs/case-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import Moment from "react-moment";
import "moment-timezone";
import "./UserCompletionReportComp.css";

// Set the timezone for every instance.
Moment.globalTimezone = "America/Detroit";

// Set the output format for every react-moment instance.
Moment.globalFormat = "MM/DD/YYYY HH:mm";

const todayMs = pushToEndOfDayUnixms(getTodayDateUnixms());
const inThreeDaysMs = getFutureDateFromUnixms(todayMs, 3, "days");
const defStartDate = getPastDateFromUnixms(todayMs, 7, "days");

const startDateOptions = [
  { startDate: defStartDate, label: "last 7 days" },
  {
    startDate: getPastDateFromUnixms(todayMs, 1, "months"),
    label: "last month"
  },
  {
    startDate: getPastDateFromUnixms(todayMs, 3, "months"),
    label: "last 3 months"
  },
  {
    startDate: getPastDateFromUnixms(todayMs, 6, "months"),
    label: "last 6 months"
  },
  {
    startDate: getPastDateFromUnixms(todayMs, 1, "years"),
    label: "last 12 months"
  }
];

const UserCompletionReportComp = () => {
  const { instance, accounts } = useMsal();

  const [leader, setLeader] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [assignees, setAssignees] = useState(null);

  // Create tree object for management
  const treeObj = new TreeModel({
    childrenPropertyName: "children"
  });

  // Pull CEO org tree
  const topTree = useGetTopTree(treeObj);

  // Handle collapse
  const [isSearchOpen, setIsSearchOpen] = useState(true);
  const toggleSearch = () => setIsSearchOpen(!isSearchOpen);

  const getIdToken = async () => {
    const res = await instance.acquireTokenSilent({
      scopes: ["User.Read", "User.ReadBasic.All"],
      account: accounts[0]
    });

    return res.idToken;
  };

  const getAccessToken = async () => {
    const res = await instance.acquireTokenSilent({
      scopes: ["User.Read", "User.Read.All", "Directory.Read.All", "Mail.Send"],
      account: accounts[0]
    });

    return res.accessToken;
  };

  const ShowSearch = (props) => {
    const { updateLeader, updateTasks, updateAssignees } = props;

    const [term, setTerm] = useState("");
    const [results, setResults] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedLeader, setSelectedLeader] = useState(null);
    const [selectedStartDate, setSelectedStartDate] = useState(defStartDate);

    const handleSearchTerm = (term) => {
      setTerm(term);
    };

    const handleSelectedLeader = async (option) => {
      const leader = JSON.parse(option);

      setSelectedLeader(leader);
    };

    const handleSelectStartDate = (option) => {
      setSelectedStartDate(+option);
    };

    const handleSearch = async () => {
      setIsLoading(true);

      // Request access token
      const accessToken = await getAccessToken();

      // Get users
      const usersRes = await getUsersContains(term, accessToken);

      const res = usersRes.value.map((item) => {
        return transformLeaderObj(item);
      });

      setResults(res);
      setIsLoading(false);
    };

    const handleReport = async () => {
      setIsLoading(true);

      // Get Id token
      const idToken = await getIdToken();

      // Get tasks
      const tasks = await getTaskWithinStartDate(selectedStartDate, idToken);
      // const tasks = await getTaskWithinStartDate(todayMs, idToken);

      if (tasks && tasks.length > 0) {
        // Gather task assignees
        const assignees = {};

        for (let i = 0; i < tasks.length; i++) {
          const task = tasks[i];

          // Get asssignees
          const taskAssigness = await getTaskAssignees(task.id, idToken);

          assignees[task.id] = taskAssigness;
        }

        setIsLoading(false);
        updateLeader(selectedLeader);
        updateTasks(tasks);
        updateAssignees(assignees);

        toggleSearch();
      } else {
        setIsLoading(false);

        showCustomModal({
          title: "Search results",
          message: "No results were found following that criteria"
        });
      }
    };

    // Switches
    const isResults = results && results.length > 0;
    const emptyResults = results && results.length === 0;

    return (
      <Card className="mt-1rem">
        <CardBody>
          <FormGroup className="mb-0rem">
            <Label>
              <b>Search leader:</b>
            </Label>
          </FormGroup>
          <InputGroup>
            <Input
              type="text"
              bsSize="sm"
              name="search-leaders"
              id="search-leaders"
              value={term}
              disabled={isLoading}
              onChange={(e) => handleSearchTerm(e.target.value)}
            />
            <Button
              color="primary"
              size="sm"
              disabled={isLoading || term === ""}
              onClick={handleSearch}
            >
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </InputGroup>
          {emptyResults && (
            <CardText className="mt-1rem">
              No results following that criteria...
            </CardText>
          )}
          {isResults && (
            <>
              <FormGroup className="mt-1rem">
                <Label>
                  <b>Pick a leader:</b>
                </Label>
                <Input
                  bsSize="sm"
                  value={JSON.stringify(selectedLeader)}
                  type="select"
                  id="select-leader"
                  disabled={isLoading}
                  onChange={(e) => handleSelectedLeader(e.target.value)}
                >
                  <>
                    <option key="0" value="">
                      Select a leader from the list
                    </option>
                    {results.map((el, i) => {
                      return (
                        <option key={i + 1} value={JSON.stringify(el)}>
                          {el.displayName}
                        </option>
                      );
                    })}
                  </>
                </Input>
              </FormGroup>
              {selectedLeader && (
                <FormGroup tag="fieldset">
                  <Label>
                    <b>With start date falling in:</b>
                  </Label>
                  {startDateOptions.map((el, i) => (
                    <FormGroup key={i} check>
                      <Label check>
                        <Input
                          type="radio"
                          name="startDate"
                          value={el.startDate}
                          checked={el.startDate === selectedStartDate}
                          disabled={isLoading}
                          onChange={(e) =>
                            handleSelectStartDate(e.target.value)
                          }
                        />{" "}
                        {el.label}
                      </Label>
                    </FormGroup>
                  ))}
                </FormGroup>
              )}
            </>
          )}
        </CardBody>
        <CardFooter>
          <Button
            color="primary"
            size="sm"
            disabled={isLoading || !selectedLeader}
            onClick={handleReport}
          >
            Get report
          </Button>
        </CardFooter>
      </Card>
    );
  };

  const ShowReport = (props) => {
    const { defLeader, updateLeader, updateTasks, updateAssignees } = props;

    const [leader, setLeader] = useState(defLeader);

    // Build CEO tree
    const tree = treeObj.parse(topTree.tree);

    // Last update
    const treeLastUpdate = topTree.updatedAt;

    // Find selected leader in top tree
    const leaderPath = findNode(leader.id, tree).getPath();

    // Convert to JSON
    const leaderPathJson = leaderPath.map((node) => node.model);

    // Get leader names
    const leaderNames = leaderPathJson.map((leader) => leader.displayName);

    // Get last leader
    const lastLeader = leaderPathJson[leaderPathJson.length - 1];

    // Get last leader children
    const lastLeaderChildren = lastLeader.children ? lastLeader.children : [];

    // Get last leader children names
    const lastLeaderChildNames = lastLeaderChildren.map(
      (leader) => leader.displayName
    );

    const newSearch = () => {
      updateLeader(null);
      updateTasks(null);
      updateAssignees(null);

      toggleSearch();
    };

    const getPad = (i) => {
      return { paddingLeft: `${i * 0.75 + 0.3}rem` };
    };

    const getChart = (stats) => {
      const { status, dueDate, assignments, onTime, late, notCompleted } =
        stats;

      // Set chart data
      let chartData = [];

      // Switches
      const isOpen = status === STATUS_ASSIGNED;
      const isClose = [STATUS_COMPLETED, STATUS_NOT_COMPLETED].includes(status);
      const isDueToday = dueDate === todayMs;
      const isDueIn3Days = !isDueToday && dueDate <= inThreeDaysMs;

      // Define color codes
      const cOnTime = "#28a745"; // Green
      const cLate = "#28a74582"; // Light green
      const cOverdue = "#dc3545"; // Red
      const cIn3Days = "#ffc107"; // Yellow
      const cDueToday = "#dc35458a"; // Light red
      const cAssigned = "#6c757d"; // Grey

      // Open task
      if (isOpen) {
        // Assigned
        if (notCompleted > 0)
          chartData.push({
            title: `${notCompleted} assigned`,
            value: notCompleted,
            color: isDueToday ? cDueToday : isDueIn3Days ? cIn3Days : cAssigned
          });

        // Completed on time
        if (onTime > 0)
          chartData.push({
            title: `${onTime} completed on time`,
            value: onTime,
            color: cOnTime
          });
      }

      // Close task
      if (isClose) {
        // Completed on time
        if (onTime > 0)
          chartData.push({
            title: `${onTime} completed on time`,
            value: onTime,
            color: cOnTime
          });

        // Completed late
        if (late > 0)
          chartData.push({
            title: `${late} completed late`,
            value: late,
            color: cLate
          });

        // Not completed
        if (notCompleted > 0)
          chartData.push({
            title: `${notCompleted} past due`,
            value: notCompleted,
            color: cOverdue
          });
      }

      return (
        assignments > 0 && (
          <PieChart
            data={chartData}
            radius={30}
            labelPosition={112}
            style={{ height: "60px" }}
            label={({ dataEntry }) => Math.round(dataEntry.percentage) + "%"}
          />
        )
      );
    };

    const handleSelection = (leader, isName) => {
      if (!isName) setLeader(leader);
    };

    const MatchLeaderName = (props) => {
      const { name, index } = props;

      const isName = name === leader.displayName;

      return (
        <Badge
          className={`font-085rem ${
            isName ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          color={isName ? "primary" : "secondary"}
          onClick={() => handleSelection(leaderPathJson[index], isName)}
        >
          {index > 0 && <FontAwesomeIcon icon={faCaretRight} />} {name}
        </Badge>
      );
    };

    const getPeopleUnderLeaderIds = (node) => {
      let people = [];

      // Walk branch
      node.walk((node) => people.push(node.model.id));

      return people;
    };

    const getTaskStats = (id, status, dueDate, peopleUnderIds) => {
      const peopleInTask = assignees[id];

      const found = peopleInTask.filter((person) => {
        return peopleUnderIds.includes(person.id);
      });

      // Set stats
      const assignments = found.length;
      let completed = 0;
      let onTime = 0;
      let late = 0;
      let notCompleted = 0;

      for (let i = 0; i < found.length; i++) {
        const person = found[i];

        if (person.completed) {
          completed += 1;

          if (person.onTime) {
            onTime += 1;
          } else {
            late += 1;
          }
        } else {
          notCompleted += 1;
        }
      }

      return {
        status,
        dueDate,
        assignments,
        completed,
        onTime,
        late,
        notCompleted
      };
    };

    const showTaskDetails = (index) => {
      const task = tasks[index];

      const styles = {
        marginBottom: "0rem"
      };

      const stylesPadded = {
        marginLeft: "0.75rem"
      };

      const isClose = [STATUS_COMPLETED, STATUS_NOT_COMPLETED].includes(
        task.status
      );

      showCustomModal({
        title: "Task info",
        message: (
          <div style={{ fontSize: "small" }}>
            <FormGroup style={styles}>
              <Label>
                <b>Title:</b>
                <br />
                {task.taskTitle}
              </Label>
            </FormGroup>
            <FormGroup style={styles}>
              <Label>
                <b>Owner:</b>
                <br />
                {task.leader.displayName}
              </Label>
            </FormGroup>
            <FormGroup style={styles}>
              <Label>
                <b>Due date:</b>{" "}
                <Moment format="MM/DD/YYYY">{task.dueDate}</Moment>
              </Label>
            </FormGroup>
            <FormGroup style={styles}>
              <Label>
                <b>Start date:</b>{" "}
                <Moment format="MM/DD/YYYY">{task.startDate}</Moment>
              </Label>
            </FormGroup>
            <FormGroup style={styles}>
              <Label>
                <b>Status:</b> {phraseToProperCase(task.status)}
              </Label>
            </FormGroup>
            <FormGroup style={styles}>
              <Label>
                <b>Assigments:</b> {task.stats.assignments}
              </Label>
            </FormGroup>
            {task.stats.completed > 0 && (
              <>
                <FormGroup style={styles}>
                  <Label>
                    <b>Completed:</b> {task.stats.completed}
                  </Label>
                </FormGroup>
                {task.stats.onTime > 0 && (
                  <FormGroup style={styles}>
                    <Label style={stylesPadded}>
                      <b>On-time:</b> {task.stats.onTime}
                    </Label>
                  </FormGroup>
                )}
                {isClose && task.stats.late > 0 && (
                  <FormGroup style={styles}>
                    <Label style={stylesPadded}>
                      <b>Late:</b> {task.stats.late}
                    </Label>
                  </FormGroup>
                )}
              </>
            )}
            {task.stats.notCompleted > 0 && (
              <FormGroup style={styles}>
                <Label>
                  <b>Not completed:</b> {task.stats.notCompleted}
                </Label>
              </FormGroup>
            )}
          </div>
        )
      });
    };

    const ShowLastUpdate = () => {
      const aDayInMs = 24 * 3600 * 1000;
      const diff = getDiffFromNowInMs(treeLastUpdate);
      let color = "primary";

      if (diff > aDayInMs) color = "danger";

      return (
        <CardText className="last-update">
          <Badge color={color}>
            <b>Last update: </b>{" "}
            <Moment format="MM/DD/YYYY hh:mm:ss A">{treeLastUpdate}</Moment>
          </Badge>
        </CardText>
      );
    };

    return (
      <Card>
        <CardHeader className="bg-primary text-white" tag="h5">
          {leader.displayName}
          <Button
            outline
            size="sm"
            onClick={newSearch}
            color="light"
            style={{ float: "right" }}
          >
            New Search
          </Button>
        </CardHeader>
        <CardBody>
          <div className="overflow-500">
            <ShowLastUpdate />
            <Table responsive size="sm">
              <thead>
                <tr>
                  <th>Leader</th>
                  {tasks.map((task, i) => {
                    return (
                      <th key={i} className="text-center">
                        <Badge
                          color="primary"
                          className="task-badge"
                          onClick={() => showTaskDetails(i)}
                        >
                          Task #{i + 1}
                        </Badge>
                        <br />
                        <small>
                          <i>
                            (Due on{" "}
                            <Moment format="MM/DD">{task.dueDate}</Moment>)
                          </i>
                        </small>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {leaderNames.map((name, i) => {
                  const peopleUnderIds = getPeopleUnderLeaderIds(leaderPath[i]);
                  const isCeo = i === 0;

                  return (
                    <tr key={i}>
                      <td style={getPad(i)}>
                        <MatchLeaderName name={name} index={i} />
                      </td>
                      {tasks.map((task, i) => {
                        let stats = {
                          status: task.status,
                          dueDate: task.dueDate,
                          ...task.stats
                        };

                        if (!isCeo) {
                          stats = getTaskStats(
                            task.id,
                            task.status,
                            task.dueDate,
                            peopleUnderIds
                          );
                        }

                        return <td key={i}>{getChart(stats)}</td>;
                      })}
                    </tr>
                  );
                })}
                {lastLeaderChildNames.map((name, i) => {
                  const peopleUnderIds = getPeopleUnderLeaderIds(
                    findNode(lastLeaderChildren[i].id, tree)
                  );

                  return (
                    <tr key={i}>
                      <td style={getPad(leaderNames.length)}>
                        <Badge
                          className="font-085rem cursor-pointer"
                          color="light"
                          onClick={() => handleSelection(lastLeaderChildren[i])}
                        >
                          <FontAwesomeIcon icon={faCaretRight} /> {name}
                        </Badge>
                      </td>
                      {tasks.map((task, i) => {
                        let stats = getTaskStats(
                          task.id,
                          task.status,
                          task.dueDate,
                          peopleUnderIds
                        );

                        return <td key={i}>{getChart(stats)}</td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </CardBody>
        <CardFooter className="text-center">
          <Badge color="secondary">Assigned</Badge>{" "}
          <Badge color="warning">Due within 3 days</Badge>{" "}
          <Badge color="danger legend-dueToday">Due today</Badge>{" "}
          <Badge color="danger">Past due</Badge>{" "}
          <Badge color="success">Completed on-time</Badge>{" "}
          <Badge color="success legend-late">Completed late</Badge>
        </CardFooter>
      </Card>
    );
  };

  // Control modal
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const toggleModal = () => setOpenModal(!openModal);
  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      &times;
    </button>
  );

  const showCustomModal = (params) => {
    const { title, message } = params;

    setModalTitle(title);
    setModalMsg(message);
    toggleModal();
  };

  const CustomModal = () => {
    return (
      <Modal
        className="msgModal"
        returnFocusAfterClose={true}
        isOpen={openModal}
      >
        <ModalHeader toggle={toggleModal} close={closeBtn}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>{modalMsg}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal} block>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  return (
    <div className="UserCompletionReportComp">
      <Card>
        <CardHeader className="bg-primary text-white" tag="h5">
          User Completion Report
        </CardHeader>
        <CardBody>
          {topTree && (
            <>
              <Collapse isOpen={isSearchOpen}>
                <ShowSearch
                  updateLeader={setLeader}
                  updateTasks={setTasks}
                  updateAssignees={setAssignees}
                />
              </Collapse>
              {!isSearchOpen && (
                <ShowReport
                  defLeader={leader}
                  updateLeader={setLeader}
                  updateTasks={setTasks}
                  updateAssignees={setAssignees}
                />
              )}
            </>
          )}
        </CardBody>
      </Card>
      <CustomModal />
    </div>
  );
};

export default UserCompletionReportComp;
