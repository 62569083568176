import React, { useState, useEffect, useRef } from "react";
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  // CardTitle,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Spinner
} from "reactstrap";
import TaskWebReferencesComp from "./TaskWebReferencesComp";
import { getLeaderOrgTree } from "../services/orgTreeServices";
import TreeModel from "tree-model";
import { bulkUpdate } from "../services/bulkServices";
import {
  MAX_TTL,
  MODE_CREATE,
  getInitErrors,
  getTaskDefinitions,
  getFurthestDueDate
} from "../models/taskModels";
import { useMsal } from "@azure/msal-react";
import Calendar from "react-calendar";
import Moment from "react-moment";
import {
  getTodayDateObj,
  pushToEndOfDayUnixms,
  dateObjToUnixms,
  getFutureDateFromNowObj,
  getFutureDateObjFromUnixms,
  getTodayDateUnixms
} from "../services/dateTimeUtils";
import "moment-timezone";
import "./TaskFormComp.css";
import "react-calendar/dist/Calendar.css";

// Set the timezone for every instance.
Moment.globalTimezone = "America/Detroit";

// Set the output format for every react-moment instance.
Moment.globalFormat = "MM/DD/YYYY HH:mm";

const DEBUG = false;

const getTimestamp = () => new Date().getTime();
const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;
// const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const TaskFormComp = (props) => {
  const {
    user,
    task,
    appSettings,
    mode = MODE_CREATE,
    callback = null,
    handleGoBack = null
  } = props;

  const userInfo = {
    id: user.id,
    name: user.name,
    org: user.org,
    email: user.email
  };

  const { instance, accounts } = useMsal();

  // Create tree object for management
  const treeObj = new TreeModel({
    childrenPropertyName: "children"
  });

  const overrideDueDate = (d) => {
    const todayMs = pushToEndOfDayUnixms(getTodayDateUnixms());

    if (d < todayMs) {
      return todayMs;
    } else {
      return d;
    }
  };

  const overrideStartDate = (d) => {
    const todayMs = getTodayDateUnixms();

    if (d < todayMs) {
      return todayMs;
    } else {
      return d;
    }
  };

  const [leader, setLeader] = useState(task.leader);
  const [groups, setGroups] = useState(task.groups);
  const [userDirectReports, setUserDirectReports] = useState(
    task.directReports
  );
  const [withDirectReports, setWithDirectReports] = useState(
    task.withDirectReports
  );
  const [taskTitle, setTaskTitle] = useState(task.taskTitle);
  const [taskDescription, setTaskDescription] = useState(task.taskDescription);
  const [webReferences, setWebReferences] = useState(task.webReferences);
  const [dueDate, setDueDate] = useState(overrideDueDate(task.dueDate));
  const [startDate, setStartDate] = useState(overrideStartDate(task.startDate));
  const [errors, setErrors] = useState(getInitErrors(mode));
  const [isSelecting, setIsSelecting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const definitions = getTaskDefinitions();
  const scheduleWithin = appSettings?.furthestDueDate ?? getFurthestDueDate();
  const members = user.delegation.filter((member) => member.updatedAt !== null);

  // Switches
  const isMembers = members.length > 0;

  const getIdToken = async () => {
    const res = await instance.acquireTokenSilent({
      scopes: ["User.Read", "User.ReadBasic.All"],
      account: accounts[0]
    });

    return res.idToken;
  };

  const SelectLeader = (props) => {
    const {
      updateLeader,
      updateGroups,
      updateWithDirectReports,
      setIsSelecting
    } = props;

    const resetForm = () => {
      updateLeader(null);
      updateGroups([]);
      updateWithDirectReports(true);
    };

    const handleSelectLeader = async (option) => {
      const [id, displayName, mail] = option.split(",");

      let dirReps = null;

      resetForm();

      setIsSelecting(true);

      // Request id token
      const idToken = await getIdToken();

      // Check if org tree exists in db
      const leaderTree = await getLeaderOrgTree(id, idToken);

      // Initialize list of direct reports
      let members = [];

      // Traverse org tree
      if (leaderTree) {
        // Create tree root
        const root = treeObj.parse(leaderTree.tree);

        // Gather the leaves
        root.walk((node) => {
          if (node.model.id !== id) {
            const {
              id,
              displayName,
              jobTitle,
              department,
              group,
              mail,
              mobilePhone,
              directReports
            } = node.model;

            // Transform member object
            members.push({
              id,
              displayName,
              jobTitle,
              department,
              group,
              mail,
              mobilePhone,
              directReports
            });
          }
        });
      }

      if (members.length > 0) {
        const dirRepPerDept = {};

        // Re-index and clean objects
        for (let i = 0; i < members.length; i++) {
          const member = members[i];

          if (!dirRepPerDept[member.jobTitle])
            dirRepPerDept[member.jobTitle] = [];

          const filteredMember = {};
          Object.keys(member).forEach((prop) => {
            if (prop !== "@odata.type") filteredMember[prop] = member[prop];
          });

          dirRepPerDept[member.jobTitle].push({
            ...filteredMember,
            completed: false,
            onTime: null,
            completedOn: null
          });
        }

        dirReps = dirRepPerDept;
      }

      setIsSelecting(false);
      updateLeader({ id, displayName, mail });
      setUserDirectReports(dirReps);
    };

    return (
      <>
        {isMembers && (
          <FormGroup>
            <Label>
              <b>Task owner {ifRequired("leader", "1")}:</b>
            </Label>
            <Input
              type="select"
              id="leaders"
              value={`${leader?.id ?? ""},${leader?.displayName ?? ""},${
                leader?.mail ?? ""
              }`}
              disabled={isSelecting}
              onChange={(e) => handleSelectLeader(e.target.value)}
            >
              <option key={0} value=",,">
                Select a leader
              </option>
              {members.map((el, i) => {
                return (
                  <option
                    key={i + 1}
                    value={`${el.id},${el.displayName},${el.mail}`}
                  >
                    {el.displayName}
                  </option>
                );
              })}
            </Input>
            {!isSelecting && (
              <FormText className="mb-1rem">
                {ifRequired("leader", "Required. ", "1")}
                {definitions["leader"].required.info}
              </FormText>
            )}
            {isSelecting && (
              <FormText>
                <Spinner color="primary" size="sm" type="grow" /> Loading
                groups...
              </FormText>
            )}
          </FormGroup>
        )}
        {!isMembers && (
          <CardText>There are no leaders to create tasks for...</CardText>
        )}
      </>
    );
  };

  const SelectAssignees = (props) => {
    const {
      userDirectReports,
      groups,
      updateGroups,
      withDirectReports,
      updateWithDirectReports
    } = props;

    const [selectedGroups, setSelectedGroups] = useState(groups);
    const [selectedWith, setSelectedWith] = useState(withDirectReports);

    const totalGroups = Object.keys(userDirectReports ?? {}).length;
    const isDirectReports = totalGroups > 0;

    const cardRef = useRef(null);

    useEffect(() => {
      const scrollPosition = sessionStorage.getItem("scrollPosition");

      if (scrollPosition) {
        cardRef.current.scrollTo(0, parseInt(scrollPosition, 10));

        sessionStorage.removeItem("scrollPosition");
      }
    });

    const handleGroup = (checked, value) => {
      // Store scroll top
      sessionStorage.setItem("scrollPosition", cardRef.current.scrollTop);

      let copyGroups = selectedGroups;

      if (checked) {
        copyGroups = [...selectedGroups, value];
      } else {
        copyGroups.splice(copyGroups.indexOf(value), 1);
      }

      setSelectedGroups([...copyGroups]);
      updateGroups([...copyGroups]);
    };

    const selectAll = () => {
      let allGroups = [];

      if (selectedWith) {
        allGroups = Object.keys(userDirectReports)
          .sort()
          .filter((group) => {
            const assigneesCount = userDirectReports[group]
              .map((item) => item.directReports > 0)
              .reduce((a, b) => a + b, 0);

            return assigneesCount > 0;
          });
      } else {
        allGroups = Object.keys(userDirectReports).sort();
      }

      setSelectedGroups([...allGroups]);
      updateGroups([...allGroups]);
    };

    const unSelectAll = () => {
      setSelectedGroups([]);
      updateGroups([]);
    };

    const handleWithDirectReports = (checked) => {
      updateGroups([]);

      setSelectedWith(checked);
      updateWithDirectReports(checked);
    };

    const countPeopleDirectReports = () => {
      let count = 0;
      const keys = Object.keys(userDirectReports);

      keys.forEach((key) => {
        count += userDirectReports[key]
          .map((item) => item.directReports)
          .reduce((a, b) => a + b, 0);
      });

      return count;
    };

    const underDirectReports = isDirectReports ? countPeopleDirectReports() : 0;
    const isUnderDirectReports = !(underDirectReports === 0 && selectedWith);

    return (
      <>
        <FormGroup className="mb-015rem">
          <Label>
            <b>Assign task to {ifRequired("groups", "2")}:</b>
          </Label>
        </FormGroup>
        {isDirectReports && (
          <>
            <FormGroup className="ml-15rem">
              <Input
                type="checkbox"
                value="1"
                defaultChecked={selectedWith}
                onChange={(e) => handleWithDirectReports(e.target.checked)}
              />{" "}
              <Label check>
                <small>Only assign to leaders with direct reports</small>
              </Label>
            </FormGroup>
            {isUnderDirectReports && (
              <Card body innerRef={cardRef} className="p-1rem overflow-500">
                <FormGroup className="mb-025rem">
                  {selectedGroups.length < totalGroups && (
                    <Button color="link" size="sm" onClick={selectAll}>
                      Select all
                    </Button>
                  )}
                  {selectedGroups.length > 0 && (
                    <Button color="link" size="sm" onClick={unSelectAll}>
                      Unselect all
                    </Button>
                  )}
                </FormGroup>
                {Object.keys(userDirectReports)
                  .sort()
                  .map((group, i) => {
                    const assigneesCount = selectedWith
                      ? userDirectReports[group]
                          .map((item) => item.directReports > 0)
                          .reduce((a, b) => a + b, 0)
                      : userDirectReports[group].length;
                    let showCheck = true;

                    if (selectedWith && assigneesCount === 0) {
                      showCheck = false;
                    }

                    return (
                      showCheck && (
                        <FormGroup key={i} check inline>
                          <Input
                            type="checkbox"
                            value={group}
                            id={`group-${i}`}
                            name="group"
                            defaultChecked={selectedGroups.includes(group)}
                            onChange={(e) =>
                              handleGroup(e.target.checked, e.target.value)
                            }
                          />
                          <Label check>
                            {group} <i>({assigneesCount})</i>
                          </Label>
                        </FormGroup>
                      )
                    );
                  })}
              </Card>
            )}
            {!isUnderDirectReports && (
              <Card body className="p-1rem">
                <CardText>
                  People under {leader.displayName} have no direct reports...
                </CardText>
              </Card>
            )}
            <FormText className="mb-1rem">
              {ifRequired("groups", "Required. ", "2")}
              {definitions["groups"].required.info}
            </FormText>
          </>
        )}
        {!isDirectReports && (
          <Card body className="mb-1rem">
            The selected task owner has no direct reports...
          </Card>
        )}
      </>
    );
  };

  const ifRequired = (field, str = "*", notation = "*") => {
    const isRequired = definitions[field].required.value;
    const notations = ["*", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

    return isRequired ? (
      notations.includes(str) ? (
        <sup>({str})</sup>
      ) : (
        <>
          <sup>({notation})</sup> {str}
        </>
      )
    ) : (
      ""
    );
  };

  const checkErrors = () => {
    const fields = Object.keys(errors);

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      const error = errors[field];

      if (error.invalid === null || error.invalid === true) {
        return false;
      }
    }

    return true;
  };

  const defineError = (field, invalid, message = "") => {
    const copyErrors = { ...errors };

    copyErrors[field] = {
      invalid,
      message
    };

    setErrors(copyErrors);
  };

  const validateTaskTitle = (str = taskTitle) => {
    // Reset errors for field
    defineError("taskTitle", false);

    const definition = definitions["taskTitle"];

    if (definition.required.value && str === "") {
      defineError("taskTitle", true, definition.required.errMsg);

      return false;
    }

    if (str.length < definition.minLength.value) {
      defineError("taskTitle", true, definition.minLength.errMsg);

      return false;
    }

    if (str.length > definition.maxLength.value) {
      defineError("taskTitle", true, definition.maxLength.errMsg);

      return false;
    }

    if (!definition.regex.rule.test(str)) {
      defineError("taskTitle", true, definition.regex.errMsg);

      return false;
    }

    return true;
  };

  const handleTaskTitle = (str) => {
    setTaskTitle(str);

    validateTaskTitle(str);
  };

  const validateTaskDescription = (str) => {
    // Reset errors for field
    defineError("taskDescription", false);

    const definition = definitions["taskDescription"];

    if (definition.required.value && str === "") {
      defineError("taskDescription", true, definition.required.errMsg);

      return false;
    }

    if (str.length < definition.minLength.value) {
      defineError("taskDescription", true, definition.minLength.errMsg);

      return false;
    }

    if (str.length > definition.maxLength.value) {
      defineError("taskDescription", true, definition.maxLength.errMsg);

      return false;
    }

    if (!definition.regex.rule.test(str)) {
      defineError("taskDescription", true, definition.regex.errMsg);

      return false;
    }

    return true;
  };

  const handleTaskDescription = (str) => {
    setTaskDescription(str);

    validateTaskDescription(str);
  };

  const DisplayDueDateCalendar = (props) => {
    const { dueDate, updateDueDate } = props;

    const minDate = getTodayDateObj();
    const maxDate = getFutureDateFromNowObj(
      scheduleWithin.count,
      scheduleWithin.unit
    );
    const dueDateObj = new Date(dueDate);

    const handleDueDate = (date) => {
      const unixms = pushToEndOfDayUnixms(dateObjToUnixms(date));

      updateDueDate(unixms);
    };

    return (
      <FormGroup className="mb-1rem">
        <Label for="dueDate">
          <b>Due date {ifRequired("dueDate", "5")}:</b>
        </Label>
        <Calendar
          onChange={handleDueDate}
          value={dueDateObj}
          minDate={minDate}
          maxDate={maxDate}
        />
        <FormText>
          {ifRequired("dueDate", "Required. ", "5")}
          {definitions["dueDate"].required.info} Furthest due date:{" "}
          <Moment format="M/D/YYYY">
            {getFutureDateFromNowObj(scheduleWithin.count, scheduleWithin.unit)}
          </Moment>
        </FormText>
      </FormGroup>
    );
  };

  const DisplayStartDateCalendar = (props) => {
    const { startDate, dueDate, updateStartDate } = props;

    const minDate = getTodayDateObj();
    const maxDate = getFutureDateObjFromUnixms(dueDate, 0, "seconds");
    const startDateObj = new Date(startDate);

    const handleStartDate = (date) => {
      const unixms = dateObjToUnixms(date);

      updateStartDate(unixms);
    };

    return (
      <FormGroup className="mb-1rem">
        <Label for="startDate">
          <b>Start date {ifRequired("startDate", "6")}:</b>
        </Label>
        <Calendar
          onChange={handleStartDate}
          value={startDateObj}
          minDate={minDate}
          maxDate={maxDate}
        />
        <FormText>
          {ifRequired("startDate", "Required. ", "6")}
          {definitions["startDate"].required.info}
        </FormText>
      </FormGroup>
    );
  };

  const handleSaveTask = async () => {
    setIsSaving(true);

    // Request access token
    const idToken = await getIdToken();

    const set = {
      leader,
      groups,
      directReports: userDirectReports,
      withDirectReports,
      taskTitle,
      taskDescription,
      webReferences,
      dueDate,
      startDate,
      status: task.status,
      updatedBy: userInfo,
      updatedAt: getTimestamp(),
      completedOn: task.completedOn,
      onTime: task.onTime,
      late: task.late
    };

    const setOnInsert = {
      id: task.id,
      createdBy: userInfo,
      createdAt: getTimestamp(),
      stats: {
        assignments: 0,
        completed: 0,
        onTime: 0,
        late: 0,
        notCompleted: 0
      },
      ttl: MAX_TTL
    };

    const query = {
      collection: "Tasks",
      filter: {
        id: task.id
      },
      update: {
        $set: set,
        $push: {},
        $pull: {},
        $setOnInsert: setOnInsert
      },
      options: {
        upsert: true
      },
      confirm: true
    };

    const res = await bulkUpdate(query, idToken);

    if (res.status === 200) {
      setIsSaving(false);

      if (callback) {
        if (res.response.result.upsertedCount === 1) {
          callback({ ...setOnInsert, ...set });
        } else {
          callback({
            id: task.id,
            ...set,
            createdBy: task.createdBy,
            createdAt: task.createdAt
          });
        }
      }

      if (mode === MODE_CREATE) setIsDone(true);
    }
  };

  const validateForm = () => {
    return checkErrors();
  };

  const ShowFooter = () => {
    const isGroups = groups.length > 0;

    return (
      <>
        {isGroups && (
          <CardFooter>
            <Button
              outline
              color="primary"
              size="sm"
              disabled={!validateForm()}
              onClick={handleSaveTask}
            >
              Save task
            </Button>
            {handleGoBack && (
              <Button
                outline
                className="ml-05rem"
                color="primary"
                size="sm"
                disabled={isSelecting}
                onClick={handleGoBack}
              >
                Go back
              </Button>
            )}
          </CardFooter>
        )}
        {!isGroups && handleGoBack && (
          <CardFooter>
            <Button
              outline
              color="primary"
              size="sm"
              disabled={isSelecting}
              onClick={handleGoBack}
            >
              Go back
            </Button>
          </CardFooter>
        )}
      </>
    );
  };

  const getHeaderTitle = () =>
    mode === MODE_CREATE ? "Create New Task" : "Edit Task";

  return (
    <div className="TaskFormComp">
      <Card>
        <CardHeader className="bg-primary text-white" tag="h5">
          {getHeaderTitle()}
        </CardHeader>
        {!isSaving && !isDone && (
          <CardBody>
            {/* <CardTitle>Write description for this section here...</CardTitle> */}
            {isMembers && (
              <>
                <Card>
                  <CardHeader>Task Form</CardHeader>
                  <CardBody>
                    <SelectLeader
                      updateLeader={setLeader}
                      updateGroups={setGroups}
                      updateWithDirectReports={setWithDirectReports}
                      setIsSelecting={setIsSelecting}
                    />
                    {leader && (
                      <SelectAssignees
                        userDirectReports={userDirectReports}
                        groups={groups}
                        updateGroups={setGroups}
                        withDirectReports={withDirectReports}
                        updateWithDirectReports={setWithDirectReports}
                      />
                    )}
                    {groups.length > 0 && (
                      <>
                        <FormGroup className="mb-1rem">
                          <Label for="taskTitle">
                            <b>Task title {ifRequired("taskTitle", "3")}:</b>
                          </Label>
                          <Input
                            invalid={errors["taskTitle"].invalid}
                            id="taskTitle"
                            name="taskTitle"
                            value={taskTitle}
                            onChange={(e) => handleTaskTitle(e.target.value)}
                          />
                          <FormFeedback>
                            {errors["taskTitle"].message}
                          </FormFeedback>
                          <FormText>
                            {ifRequired("taskTitle", "Required. ", "3")}
                            {definitions["taskTitle"].required.info}
                          </FormText>
                        </FormGroup>
                        <FormGroup className="mb-1rem">
                          <Label for="taskDescription">
                            <b>
                              Task description{" "}
                              {ifRequired("taskDescription", "4")}:
                            </b>
                          </Label>
                          <Input
                            invalid={errors["taskDescription"].invalid}
                            id="taskDescription"
                            name="taskDescription"
                            type="textarea"
                            value={taskDescription}
                            onChange={(e) =>
                              handleTaskDescription(e.target.value)
                            }
                          />
                          <FormFeedback>
                            {errors["taskDescription"].message}
                          </FormFeedback>
                          <FormText>
                            {ifRequired("taskDescription", "Required. ", "4")}
                            {definitions["taskDescription"].required.info}
                          </FormText>
                        </FormGroup>
                        <TaskWebReferencesComp
                          webReferences={webReferences}
                          updateWebReferences={setWebReferences}
                        />
                        <DisplayDueDateCalendar
                          dueDate={dueDate}
                          updateDueDate={setDueDate}
                        />
                        <DisplayStartDateCalendar
                          startDate={startDate}
                          dueDate={dueDate}
                          updateStartDate={setStartDate}
                        />
                      </>
                    )}
                  </CardBody>
                  <ShowFooter />
                </Card>
              </>
            )}
            {!isMembers && (
              <Alert color="warning" className="mb-0rem">
                <h5 className="alert-heading">Delegation Not Available</h5>
                <p>The current user is not a delegate yet.</p>
                <p>
                  If you're sure delegation was set for you, please wait a few
                  minutes and try again; otherwise, please contact the
                  administrator for more information.
                </p>
                <Nav pills>
                  <NavItem className="mt-05rem">
                    <NavLink active href="/create-task">
                      Try again
                    </NavLink>
                  </NavItem>
                </Nav>
              </Alert>
            )}
          </CardBody>
        )}
        {isSaving && (
          <CardBody>
            <Spinner color="primary" size="sm" type="grow" /> Saving task...
          </CardBody>
        )}
        {isDone && (
          <>
            <CardBody>
              <CardText>Task successfully created.</CardText>
            </CardBody>
            <CardFooter>
              <Nav pills>
                <NavItem>
                  <NavLink active href="/create-task">
                    Create new task
                  </NavLink>
                </NavItem>
                <NavItem className="ml-05rem">
                  <NavLink active href="/tasks">
                    Manage tasks
                  </NavLink>
                </NavItem>
              </Nav>
            </CardFooter>
          </>
        )}
      </Card>
      {DEBUG && (
        <Card className="mt-1rem">
          <CardHeader>Debug Create/Update Task</CardHeader>
          <CardBody className="overflow-500">
            <Json
              data={{
                taskId: task.id,
                taskTitle,
                taskDescription,
                groups,
                userDirectReports,
                withDirectReports,
                webReferences,
                dueDate,
                startDate
              }}
            />
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default TaskFormComp;
