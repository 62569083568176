import React from "react";
import TaskFormComp from "../components/TaskFormComp";
import { getInitTaskModel, MODE_CREATE } from "../models/taskModels";
import "./TaskCreate.css";

const TaskCreate = (props) => {
  const { auth, user, appSettings } = props;

  const spitTask = (task) => {
    console.log("TaskCreate: spitTask: saved...", task);
  };

  return (
    <div className="TaskCreate">
      <div className="container">
        <TaskFormComp
          auth={auth}
          user={user}
          appSettings={appSettings}
          task={getInitTaskModel()}
          mode={MODE_CREATE}
          callback={spitTask}
        />
      </div>
    </div>
  );
};

export default TaskCreate;
