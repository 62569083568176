import React, { useState } from "react";
import {
  Button,
  ButtonDropdown,
  Card,
  CardText,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  Label,
  ListGroup,
  ListGroupItem
} from "reactstrap";
import "./TaskWebReferencesComp.css";

const schemeDefault = "https://";
const schemeOptions = ["https://", "http://"];

const definitions = {
  title: {
    unique: {
      value: true,
      errMsg: "A web reference with that title was already added"
    },
    required: {
      value: true,
      info: "Min: 8 chars. Max: 256 chars. Valid characters: A-Z a-z 0-9 -',.;:!?$%&#() and spaces",
      errMsg: "Title can't be empty"
    },
    minLength: {
      value: 8,
      errMsg: "Title can't be shorter than 8 characters"
    },
    maxLength: {
      value: 256,
      errMsg: "Title can't be longer than 256 characters"
    },
    regex: {
      rule: new RegExp(/^[A-Za-z0-9 -',.;:!?$%&#()]*$/, "i"),
      errMsg: "Invalid characters in title"
    }
  },
  url: {
    unique: {
      value: true,
      errMsg: "A web reference with that URL was already added"
    },
    required: {
      value: true,
      info: "Min: 8 chars. Max: 1024 chars. Valid characters: A-Z a-z 0-9 -',.;:!?$%&#()",
      errMsg: "URL can't be empty"
    },
    minLength: {
      value: 8,
      errMsg: "URL can't be shorter than 8 characters"
    },
    maxLength: {
      value: 1024,
      errMsg: "URL can't be longer than 1024 characters"
    },
    regex: {
      rule: new RegExp(
        /(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
        "i"
      ),
      errMsg: "Invalid characters in URL"
    }
  }
};

const TaskWebReferencesComp = (props) => {
  const { webReferences, updateWebReferences } = props;

  const [isAdding, setIsAdding] = useState(false);

  const AddReference = (props) => {
    const { updateIsAdding, curUrls, curTitles, updateWebReferences } = props;

    const [title, setTitle] = useState("");
    const [url, setUrl] = useState("");
    const [scheme, setScheme] = useState(schemeDefault);
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const initErrors = {
      title: {
        invalid: null,
        message: ""
      },
      url: {
        invalid: null,
        message: ""
      }
    };
    const [errors, setErrors] = useState(initErrors);

    const ifRequired = (field, str = "*", notation = "*") => {
      const isRequired = definitions[field].required.value;
      const notations = ["*", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

      return isRequired ? (
        notations.includes(str) ? (
          <sup>({str})</sup>
        ) : (
          <>
            <sup>({notation})</sup> {str}
          </>
        )
      ) : (
        ""
      );
    };

    const checkErrors = () => {
      const fields = Object.keys(errors);

      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        const error = errors[field];

        if (error.invalid === null || error.invalid === true) {
          return false;
        }
      }

      return true;
    };

    const defineError = (field, invalid, message = "") => {
      const copyErrors = { ...errors };

      copyErrors[field] = {
        invalid,
        message
      };

      setErrors(copyErrors);
    };

    const resetErrors = () => {
      const copyErrors = { ...errors };

      Object.keys(errors).forEach(
        (key) => (copyErrors[key] = { invalid: null, message: "" })
      );

      setErrors(copyErrors);
    };

    const validateTitle = (str) => {
      // Reset errors for field
      defineError("title", false);

      const definition = definitions["title"];

      if (definition.required.value && str === "") {
        defineError("title", true, definition.required.errMsg);

        return false;
      }

      if (str.length < definition.minLength.value) {
        defineError("title", true, definition.minLength.errMsg);

        return false;
      }

      if (str.length > definition.maxLength.value) {
        defineError("title", true, definition.maxLength.errMsg);

        return false;
      }

      if (!definition.regex.rule.test(str)) {
        defineError("title", true, definition.regex.errMsg);

        return false;
      }

      if (definition.unique.value && curTitles.includes(str)) {
        defineError("title", true, definition.unique.errMsg);

        return false;
      }

      return true;
    };

    const validateUrl = (str) => {
      // Reset errors for field
      defineError("url", false);

      const definition = definitions["url"];

      if (definition.required.value && str === "") {
        defineError("url", true, definition.required.errMsg);

        return false;
      }

      if (str.length < definition.minLength.value) {
        defineError("url", true, definition.minLength.errMsg);

        return false;
      }

      if (str.length > definition.maxLength.value) {
        defineError("url", true, definition.maxLength.errMsg);

        return false;
      }

      if (!definition.regex.rule.test(str)) {
        defineError("url", true, definition.regex.errMsg);

        return false;
      }

      if (definition.unique.value && curUrls.includes(str)) {
        defineError("url", true, definition.unique.errMsg);

        return false;
      }

      return true;
    };

    const handleSave = () => {
      if (checkErrors()) {
        const referencesCopy = [...webReferences];

        referencesCopy.push({ title, url, scheme });

        updateIsAdding(false);
        updateWebReferences(referencesCopy);
      }
    };

    const handleTitle = (str) => {
      setTitle(str);

      validateTitle(str);
    };

    const handleUrl = (str) => {
      setUrl(str);

      validateUrl(str);
    };

    const handleCancel = () => {
      resetErrors();

      updateIsAdding(false);
    };

    return (
      <ListGroupItem className="mb-1rem">
        <FormGroup>
          <Label>
            <b>Title {ifRequired("title", "1")}</b>
          </Label>
          <Input
            invalid={errors["title"].invalid}
            type="text"
            defaultValue={title}
            onChange={(e) => handleTitle(e.target.value)}
          />
          <FormFeedback>{errors["title"].message}</FormFeedback>
          <FormText>
            {ifRequired("title", "Required. ", "1")}
            {definitions["title"].required.info}
          </FormText>
        </FormGroup>
        <FormGroup className="mb-0rem">
          <Label>
            <b>URL {ifRequired("url", "2")}</b>
          </Label>
        </FormGroup>
        <InputGroup className="mb-0rem">
          <ButtonDropdown isOpen={isOpen} toggle={toggle}>
            <DropdownToggle outline split>
              <b>{scheme}</b>{" "}
            </DropdownToggle>
            <DropdownMenu>
              {schemeOptions.map((scheme, i) => {
                return (
                  <DropdownItem key={i} onClick={() => setScheme(scheme)}>
                    {scheme}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </ButtonDropdown>
          <Input
            invalid={errors["url"].invalid}
            type="text"
            defaultValue={url}
            onChange={(e) => handleUrl(e.target.value)}
          />
          <FormFeedback>{errors["url"].message}</FormFeedback>
        </InputGroup>
        <FormGroup>
          <FormText>
            {ifRequired("url", "Required. ", "2")}
            {definitions["url"].required.info}
          </FormText>
        </FormGroup>
        <FormGroup className="mb-0rem">
          <Button
            outline
            color="primary"
            size="sm"
            className="mr-05rem"
            disabled={!checkErrors()}
            onClick={handleSave}
          >
            add
          </Button>
          <Button outline color="primary" size="sm" onClick={handleCancel}>
            cancel
          </Button>
        </FormGroup>
      </ListGroupItem>
    );
  };

  const ManageReference = (props) => {
    const { reference, refIndex, curUrls, curTitles, updateWebReferences } =
      props;

    const [isEditing, setIsEditing] = useState(false);

    const [title, setTitle] = useState(reference.title);
    const [url, setUrl] = useState(reference.url);
    const [scheme, setScheme] = useState(reference.scheme);
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const initErrors = {
      title: {
        invalid: false,
        message: ""
      },
      url: {
        invalid: false,
        message: ""
      }
    };
    const [errors, setErrors] = useState(initErrors);

    const isChanged = () => {
      const titleChanged = title !== reference.title;
      const urlChanged = url !== reference.url;
      const schemeChanged = scheme !== reference.scheme;

      return titleChanged || urlChanged || schemeChanged;
    };

    const ifRequired = (field, str = "*", notation = "*") => {
      const isRequired = definitions[field].required.value;
      const notations = ["*", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

      return isRequired ? (
        notations.includes(str) ? (
          <sup>({str})</sup>
        ) : (
          <>
            <sup>({notation})</sup> {str}
          </>
        )
      ) : (
        ""
      );
    };

    const checkErrors = () => {
      const fields = Object.keys(errors);

      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        const error = errors[field];

        if (error.invalid === null || error.invalid === true) {
          return false;
        }
      }

      return true;
    };

    const defineError = (field, invalid, message = "") => {
      const copyErrors = { ...errors };

      copyErrors[field] = {
        invalid,
        message
      };

      setErrors(copyErrors);
    };

    const resetErrors = () => {
      const copyErrors = { ...errors };

      Object.keys(errors).forEach(
        (key) => (copyErrors[key] = { invalid: false, message: "" })
      );

      setErrors(copyErrors);
    };

    const validateTitle = (str) => {
      // Reset errors for field
      defineError("title", false);

      const definition = definitions["title"];

      if (definition.required.value && str === "") {
        defineError("title", true, definition.required.errMsg);

        return false;
      }

      if (str.length < definition.minLength.value) {
        defineError("title", true, definition.minLength.errMsg);

        return false;
      }

      if (str.length > definition.maxLength.value) {
        defineError("title", true, definition.maxLength.errMsg);

        return false;
      }

      if (!definition.regex.rule.test(str)) {
        defineError("title", true, definition.regex.errMsg);

        return false;
      }

      if (definition.unique.value && curTitles.includes(str)) {
        if (str !== reference.title) {
          defineError("title", true, definition.unique.errMsg);

          return false;
        }
      }

      return true;
    };

    const validateUrl = (str) => {
      // Reset errors for field
      defineError("url", false);

      const definition = definitions["url"];

      if (definition.required.value && str === "") {
        defineError("url", true, definition.required.errMsg);

        return false;
      }

      if (str.length < definition.minLength.value) {
        defineError("url", true, definition.minLength.errMsg);

        return false;
      }

      if (str.length > definition.maxLength.value) {
        defineError("url", true, definition.maxLength.errMsg);

        return false;
      }

      if (!definition.regex.rule.test(str)) {
        defineError("url", true, definition.regex.errMsg);

        return false;
      }

      if (definition.unique.value && curUrls.includes(str)) {
        if (str !== reference.url) {
          defineError("url", true, definition.unique.errMsg);

          return false;
        }
      }

      return true;
    };

    const handleSave = () => {
      if (checkErrors()) {
        const referencesCopy = [...webReferences];

        referencesCopy[refIndex] = { title, url, scheme };

        setIsEditing(false);
        updateWebReferences(referencesCopy);
      }
    };

    const handleDelete = () => {
      const referencesCopy = [...webReferences];

      const final = referencesCopy.filter((ref, i) => i !== refIndex);

      updateWebReferences(final);
    };

    const handleCancel = () => {
      setTitle(reference.title);
      setUrl(reference.url);
      setScheme(reference.scheme);

      resetErrors();

      setIsEditing(false);
    };

    const handleTitle = (str) => {
      setTitle(str);

      validateTitle(str);
    };

    const handleUrl = (str) => {
      setUrl(str);

      validateUrl(str);
    };

    return (
      <ListGroupItem color={isEditing ? "warning" : "default"}>
        {!isEditing && (
          <CardText>
            <b>{title}</b>
            <br />
            <i>
              {scheme}
              {url}
            </i>
          </CardText>
        )}
        {isEditing && (
          <>
            <FormGroup>
              <Label>
                <b>Title {ifRequired("title", "1")}</b>
              </Label>
              <Input
                invalid={errors["title"].invalid}
                type="text"
                defaultValue={title}
                onChange={(e) => handleTitle(e.target.value)}
              />
              <FormFeedback>{errors["title"].message}</FormFeedback>
              <FormText>
                {ifRequired("title", "Required. ", "1")}
                {definitions["title"].required.info}
              </FormText>
            </FormGroup>
            <FormGroup className="mb-0rem">
              <Label>
                <b>URL {ifRequired("url", "2")}</b>
              </Label>
            </FormGroup>
            <InputGroup className="mb-1rem">
              <ButtonDropdown isOpen={isOpen} toggle={toggle}>
                <DropdownToggle outline split>
                  <b>{scheme}</b>{" "}
                </DropdownToggle>
                <DropdownMenu>
                  {schemeOptions.map((scheme, i) => {
                    return (
                      <DropdownItem key={i} onClick={() => setScheme(scheme)}>
                        {scheme}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </ButtonDropdown>
              <Input
                invalid={errors["url"].invalid}
                type="text"
                defaultValue={url}
                onChange={(e) => handleUrl(e.target.value)}
              />
              <FormFeedback>{errors["url"].message}</FormFeedback>
            </InputGroup>
            <FormGroup>
              <FormText>
                {ifRequired("url", "Required. ", "2")}
                {definitions["url"].required.info}
              </FormText>
            </FormGroup>
          </>
        )}
        {!isEditing && (
          <>
            <hr className="slim-hr" />
            <FormGroup className="mb-0rem">
              <Button
                color="link"
                size="sm"
                className="mr-05rem"
                onClick={() => setIsEditing(true)}
              >
                edit
              </Button>
              <Button color="link" size="sm" onClick={handleDelete}>
                delete
              </Button>
            </FormGroup>
          </>
        )}
        {isEditing && (
          <FormGroup className="mb-0rem">
            <Button
              color="warning"
              size="sm"
              className="mr-05rem"
              disabled={!checkErrors() || !isChanged()}
              onClick={handleSave}
            >
              change
            </Button>
            <Button color="warning" size="sm" onClick={handleCancel}>
              cancel
            </Button>
          </FormGroup>
        )}
      </ListGroupItem>
    );
  };

  return (
    <div className="TaskWebReferencesComp">
      <FormGroup className="mb-015rem">
        <Label>
          <b>Resources</b>{" "}
          <Button color="link" size="sm" onClick={() => setIsAdding(true)}>
            + add new reference
          </Button>
        </Label>
      </FormGroup>
      {isAdding && (
        <AddReference
          updateIsAdding={setIsAdding}
          curUrls={webReferences.map((ref) => ref.url)}
          curTitles={webReferences.map((ref) => ref.title)}
          updateWebReferences={updateWebReferences}
        />
      )}
      {!isAdding && webReferences.length > 0 && (
        <Card body className="overflow-500 mb-1rem">
          <ListGroup>
            {webReferences.map((reference, i) => {
              return (
                <ManageReference
                  key={i}
                  reference={reference}
                  refIndex={i}
                  curUrls={webReferences.map((ref) => ref.url)}
                  curTitles={webReferences.map((ref) => ref.title)}
                  updateWebReferences={updateWebReferences}
                />
              );
            })}
          </ListGroup>
        </Card>
      )}
    </div>
  );
};

export default TaskWebReferencesComp;
