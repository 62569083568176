const DEF_APP_SETTINGS = {
  id: "app-settings",
  furthestDueDate: {
    count: 1,
    unit: "years"
  },
  registration: {
    automatic: false,
    approve: {
      role: "leader",
      org: "DTE"
    }
  }
};

export { DEF_APP_SETTINGS };
