import React from "react";
import MsGraphTest from "../components/MsGraphTest";
import "./MsGraph.css";

const MsGraph = (props) => {
  return (
    <div className="MsGraph">
      <div className="container">
        <MsGraphTest {...props} />
      </div>
    </div>
  );
};

export default MsGraph;
