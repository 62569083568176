import React from "react";
import DelegationUpdateComp from "../components/DelegationUpdateComp";
import "./DelegationUpdate.css";

const DelegationUpdate = (props) => {
  return (
    <div className="DelegationUpdate">
      <div className="container">
        <DelegationUpdateComp {...props} />
      </div>
    </div>
  );
};

export default DelegationUpdate;
