import React, { useState } from "react";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  CardBody,
  CardText,
  FormGroup,
  Label,
  Spinner,
  CardTitle
} from "reactstrap";
import { runSweeper } from "../services/taskServices";
import { useMsal } from "@azure/msal-react";
import "./SweeperComp.css";

const DEBUG = false;

const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;
// const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const SweeperComp = () => {
  const { instance, accounts } = useMsal();

  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState(null);

  const getIdToken = async () => {
    const res = await instance.acquireTokenSilent({
      scopes: ["User.Read", "User.ReadBasic.All"],
      account: accounts[0]
    });

    return res.idToken;
  };

  const handleRun = async () => {
    setIsLoading(true);

    // Request access token
    const idToken = await getIdToken();

    // Run sweeper
    const res = await runSweeper({}, idToken);

    setResults(res);

    setIsLoading(false);
  };

  const ShowResults = () => {
    const status = results.status;

    const ShowError = () => {
      return <CardText>Server responded with status: {status}</CardText>;
    };

    const ShowDetails = () => {
      const response = results.response;
      const overdue = response["update-overdue-response"].result.nModified;
      const assigned = response["pending-assignment-count"];
      const dueToday = response["due-today-count"];
      const dueTomorrow = response["due-tomorrow-count"];
      const dueNextWeek = response["due-next-week-count"];
      const dueYesterday = response["due-yesterday-count"];

      return (
        <>
          <FormGroup className="mb-0rem">
            <Label>
              <b>Tasks flagged as overdue:</b>
            </Label>{" "}
            {overdue}
          </FormGroup>
          <FormGroup className="mb-0rem">
            <Label>
              <b>Tasks assigned:</b>
            </Label>{" "}
            {assigned}
          </FormGroup>
          <FormGroup className="mb-0rem">
            <Label>
              <b>Tasks due today:</b>
            </Label>{" "}
            {dueToday}
          </FormGroup>
          <FormGroup className="mb-0rem">
            <Label>
              <b>Tasks due tomorrow:</b>
            </Label>{" "}
            {dueTomorrow}
          </FormGroup>
          <FormGroup className="mb-0rem">
            <Label>
              <b>Tasks due next week:</b>
            </Label>{" "}
            {dueNextWeek}
          </FormGroup>
          <FormGroup className="mb-0rem">
            <Label>
              <b>Tasks due yesterday:</b>
            </Label>{" "}
            {dueYesterday}
          </FormGroup>
        </>
      );
    };

    return (
      <Card className="mt-1rem">
        <CardHeader>Results</CardHeader>
        <CardBody className="overflow-300">
          {status !== 200 && <ShowError />}
          {status === 200 && <ShowDetails />}
        </CardBody>
        <CardFooter>
          <Button
            className="mr-05rem"
            color="primary"
            size="sm"
            onClick={() => setResults(null)}
          >
            Clear results
          </Button>
        </CardFooter>
      </Card>
    );
  };

  const Debug = () => {
    return (
      <Card className="mt-1rem">
        <CardHeader>Debug</CardHeader>
        <CardBody className="overflow-300">
          <Json data={results} />
        </CardBody>
      </Card>
    );
  };

  return (
    <div className="SweeperComp">
      <Card>
        <CardHeader className="bg-primary text-white" tag="h5">
          Task Sweeper
        </CardHeader>
        <CardBody>
          <CardTitle>Description of functionality...</CardTitle>
          <Button
            className="mr-05rem"
            color="primary"
            size="sm"
            disabled={isLoading}
            onClick={handleRun}
          >
            Run Task Sweeper {isLoading && <Spinner size="sm" color="light" />}
          </Button>
          {results && <ShowResults />}
          {DEBUG && <Debug />}
        </CardBody>
      </Card>
    </div>
  );
};

export default SweeperComp;
