import React from "react";
import { Alert, Spinner } from "reactstrap";
import SettingsComp from "../components/SettingsComp";
import { useGetAppSettings } from "../components/useGetAppSettings";
import "./Settings.css";

const Settings = (props) => {
  let newProps = null;

  const appSettings = useGetAppSettings();

  newProps = { ...props, defAppSettings: appSettings };

  return appSettings ? (
    <div className="Settings">
      <div className="container">
        <SettingsComp {...newProps} />
      </div>
    </div>
  ) : (
    <div className="Settings">
      <div className="container">
        <Alert color="primary">
          <Spinner color="primary" size="sm" type="grow" /> Loading app
          settings...
        </Alert>
      </div>
    </div>
  );
};

export default Settings;
