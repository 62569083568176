import moment from "moment";

const padNumber = (number, pad = 2) => {
  return String(number).padStart(pad, "0");
};

const getTodayDateObj = () => {
  const d = new Date();

  return new Date(d.getFullYear(), d.getMonth(), d.getDate());
};

const pushToEndOfDayUnixms = (unixms) => {
  return +moment(unixms).add(86399, "seconds").format("x");
};

const getTodayDateUnixms = () => {
  const d = new Date();
  const year = d.getFullYear();
  const month = padNumber(d.getMonth() + 1);
  const day = padNumber(d.getDate());

  const str = `${year}-${month}-${day}`;

  return +moment(str).format("x");
};

const dateObjToUnixms = (d) => {
  return +moment(d, "ddd, DD MMM YYYY HH:mm:ss ZZ").format("x");
};

const getFutureDateFromNowObj = (count, unit) => {
  return new Date(moment().add(count, unit).format());
};

const getFutureDateObjFromUnixms = (unixms, count, unit) => {
  return new Date(moment(unixms).add(count, unit).format());
};

const getFutureDateFromUnixms = (unixms, count, unit) => {
  return +moment(unixms).add(count, unit);
};

const getPastDateFromUnixms = (unixms, count, unit) => {
  return +moment(unixms).subtract(count, unit);
};

const getTimeFromX = (dateMs) => {
  const a = moment(getTodayDateUnixms());
  const b = moment(dateMs);

  return b.isSame(a) ? "today" : b.from(a);
};

const getDiffFromNowInMs = (b) => {
  const aObj = new Date();
  const a = +moment(aObj).format("x");

  return moment(a).diff(moment(b));
};

export {
  getTodayDateObj,
  pushToEndOfDayUnixms,
  getTodayDateUnixms,
  dateObjToUnixms,
  getFutureDateFromNowObj,
  getFutureDateObjFromUnixms,
  getFutureDateFromUnixms,
  getPastDateFromUnixms,
  getTimeFromX,
  getDiffFromNowInMs
};
