import React from "react";
import { Route, Switch } from "react-router-dom";

// Containers
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import TaskCreate from "./containers/TaskCreate";
import Tasks from "./containers/Tasks";
import UserCompletionReport from "./containers/UserCompletionReport";
import DelegationsReport from "./containers/DelegationsReport";
import Users from "./containers/Users";
import Settings from "./containers/Settings";
import Sweeper from "./containers/Sweeper";
import DelegationUpdate from "./containers/DelegationUpdate";
import Seed from "./containers/Seed";
import Mail from "./containers/Mail";
import SignalR from "./containers/SignalR";
import MsGraph from "./containers/MsGraph";
import OrgChart from "./containers/OrgChart";

// Authentication
import AuthenticatedRoute from "./components/AuthenticatedRoute";

const Routes = ({ appProps }) => {
  return (
    <Switch>
      <AuthenticatedRoute path="/" exact component={Home} appProps={appProps} />
      <AuthenticatedRoute
        path="/create-task"
        exact
        component={TaskCreate}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/tasks"
        exact
        component={Tasks}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/user-completion"
        exact
        component={UserCompletionReport}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/delegations"
        exact
        component={DelegationsReport}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/users"
        exact
        component={Users}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/settings"
        exact
        component={Settings}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/sweeper"
        exact
        component={Sweeper}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/delegation-update"
        exact
        component={DelegationUpdate}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/seed"
        exact
        component={Seed}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/mail"
        exact
        component={Mail}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/signalr-test"
        exact
        component={SignalR}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/msgraph-test"
        exact
        component={MsGraph}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/org-chart"
        exact
        component={OrgChart}
        appProps={appProps}
      />
      {/* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
