import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import MyTasksComp from "../components/MyTasksComp";
import "./ItAdmin.css";

const DEBUG = false;
const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;

const ItAdmin = (props) => {
  const { user, auth } = props;

  const Debug = () => {
    return (
      <Card className="mt-1rem">
        <CardHeader>Debug</CardHeader>
        <CardBody className="overflow-500">
          <Json
            data={{
              idToken: auth.idToken,
              accessToken: auth.accessToken,
              user
            }}
          />
        </CardBody>
      </Card>
    );
  };

  return (
    <div className="ItAdmin">
      <MyTasksComp {...props} />
      {DEBUG && <Debug />}
    </div>
  );
};

export default ItAdmin;
