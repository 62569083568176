import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Spinner
} from "reactstrap";
import {
  getUsersContains,
  getUserDirectReports,
  transformLeaderObj,
  getUserDirectReportsCount
} from "../services/msGraphServices";
import { getLeaderOrgTree } from "../services/orgTreeServices";
import { useMsal } from "@azure/msal-react";
import OrgChart from "react-orgchart";
import "react-orgchart/index.css";
import "./OrgChartTest.css";
import TreeModel from "tree-model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const DEBUG = true;
const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;
// const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const OrgChartTest = () => {
  const { instance, accounts } = useMsal();

  const [tree, setTree] = useState(null);

  // Create tree object for management
  const treeObj = new TreeModel({
    childrenPropertyName: "children"
  });

  const Debug = () => {
    return (
      <Card className="mt-1rem">
        <CardHeader>Debug</CardHeader>
        <CardBody className="overflow-500">
          <Json data={tree?.model} />
        </CardBody>
      </Card>
    );
  };

  const getIdToken = async () => {
    const res = await instance.acquireTokenSilent({
      scopes: ["User.Read", "User.ReadBasic.All"],
      account: accounts[0]
    });

    return res.idToken;
  };

  const getAccessToken = async () => {
    const res = await instance.acquireTokenSilent({
      scopes: ["User.Read", "User.Read.All", "Directory.Read.All", "Mail.Send"],
      account: accounts[0]
    });

    return res.accessToken;
  };

  const ShowSearch = () => {
    const [term, setTerm] = useState("");
    const [results, setResults] = useState(null);
    const [isSearching, setIsSearching] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedLeader, setSelectedLeader] = useState(null);

    const handleSearchTerm = (term) => {
      setTerm(term);
    };

    const handleSelection = async (option) => {
      const leader = JSON.parse(option);

      setSelectedLeader(leader);
    };

    const handleSearch = async () => {
      setIsSearching(true);

      // Request access token
      const accessToken = await getAccessToken();

      // Get users
      const usersRes = await getUsersContains(term, accessToken);

      const res = usersRes.value.map((item) => {
        return transformLeaderObj(item);
      });

      setResults(res);
      setIsSearching(false);
    };

    const populateTree = async (root, accessToken) => {
      // Get direct reports
      const res = await getUserDirectReports(root.model.id, accessToken);

      let parentChildren = [];

      // Loop through results
      for (let i = 0; i < res.value.length; i++) {
        const item = res.value[i];
        const leader = transformLeaderObj(item);

        // Get direct reports count
        const count = await getUserDirectReportsCount(leader.id, accessToken);

        parentChildren.push({ ...leader, directReports: count });
      }

      // Loop through children
      for (let i = 0; i < parentChildren.length; i++) {
        // Create child node
        const childNode = treeObj.parse(parentChildren[i]);

        // Add child to tree
        await populateTree(root.addChild(childNode), accessToken);
      }
    };

    const handleChart = async () => {
      setIsLoading(true);

      // Initialize response
      let root = null;

      // Request access token
      const idToken = await getIdToken();

      // Check if org tree exists in db
      const leaderTree = await getLeaderOrgTree(selectedLeader.id, idToken);

      if (leaderTree) {
        // Create tree root
        root = treeObj.parse(leaderTree.tree);
      } else {
        // Request access token
        const accessToken = await getAccessToken();

        // Get direct reports count
        const count = await getUserDirectReportsCount(
          selectedLeader.id,
          accessToken
        );

        // Create tree root
        root = treeObj.parse({ ...selectedLeader, directReports: count });

        // Start recursive search
        await populateTree(root, accessToken);

        // root.walk((node) => console.log(node.model.displayName));
      }

      setIsLoading(false);
      setTree(root);
    };

    // Switches
    const isResults = results && results.length > 0;
    const emptyResults = results && results.length === 0;

    return (
      <Card className="mt-1rem">
        <CardBody>
          <FormGroup className="mb-0rem">
            <Label>
              <b>Search leaders:</b>
            </Label>
          </FormGroup>
          <InputGroup>
            <Input
              type="text"
              bsSize="sm"
              name="search-leaders"
              id="search-leaders"
              value={term}
              disabled={isSearching}
              onChange={(e) => handleSearchTerm(e.target.value)}
            />
            <Button
              color="primary"
              size="sm"
              disabled={isSearching || term === ""}
              onClick={handleSearch}
            >
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </InputGroup>
          {emptyResults && (
            <CardText className="mt-1rem">
              No results following that criteria...
            </CardText>
          )}
          {isResults && (
            <FormGroup className="mt-1rem">
              <Label>
                <b>Results:</b>
              </Label>
              <Input
                bsSize="sm"
                value={JSON.stringify(selectedLeader)}
                type="select"
                id="delegation"
                onChange={(e) => handleSelection(e.target.value)}
              >
                <>
                  <option key="0" value="">
                    Select a leader from the list
                  </option>
                  {results.map((el, i) => {
                    return (
                      <option key={i + 1} value={JSON.stringify(el)}>
                        {el.displayName}
                      </option>
                    );
                  })}
                </>
              </Input>
            </FormGroup>
          )}
        </CardBody>
        <CardFooter>
          <Button
            color="primary"
            size="sm"
            disabled={!selectedLeader || isLoading}
            onClick={handleChart}
          >
            Chart leader {isLoading && <Spinner size="sm" color="light" />}
          </Button>
        </CardFooter>
      </Card>
    );
  };

  const NodeComp = ({ node }) => {
    return (
      <Card className="initechNode">
        <CardBody>
          <CardText className="mb-0rem">
            <b>{node.model.displayName}</b>
          </CardText>
          <CardText className="mb-0rem">
            <small>{node.model.group}</small>
          </CardText>
          <CardText>
            <small>
              <i>{node.model.mail}</i>
            </small>
          </CardText>
        </CardBody>
      </Card>
    );
  };

  const ShowOrgChart = () => {
    if (tree === null) return null;

    return (
      <Card className="mt-1rem">
        <CardHeader>One-level Chart</CardHeader>
        <CardBody className="overflow-500">
          <OrgChart tree={tree} NodeComponent={NodeComp} />
        </CardBody>
      </Card>
    );
  };

  return (
    <div className="OrgChartTest">
      <Card>
        <CardHeader tag="h5">Organizational Charts</CardHeader>
        <CardBody>
          <ShowSearch />
          <ShowOrgChart />
        </CardBody>
      </Card>
      {DEBUG && <Debug />}
    </div>
  );
};

export default OrgChartTest;
