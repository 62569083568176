import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Spinner,
  CardText
} from "reactstrap";
import { MailMessage } from "../models/Message";
import { appInfo } from "../appInfo";
import "./MailTest.css";

const MSGRAPH_MAILACTIVE = process.env.REACT_APP_MSGRAPH_MAILACTIVE === "true";
const stage = appInfo.stage;

const MailTest = (props) => {
  const { user, sendEmail } = props;

  const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;

  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState(null);

  const handleResults = () => {
    setIsLoading(false);

    return setResults;
  };

  const sendRequest = async () => {
    setIsLoading(true);

    // Set mail delegate
    const fromRecipient = {
      name: user.name,
      address: user.email
    };

    // Set to recipients2
    const toRecipients = [
      {
        name: "Alonso Rivel",
        address: "alonso.rivel@gmail.com"
      }
    ];

    // Set message subject
    const subject = `${appInfo[stage].longName}: Send mail test...`;

    // Set message body
    const body = {
      content: `Dear,\n\nI'm requesting to be a registered user of the ${appInfo[stage].longName} System.\n\nThank you,\n${user.name}`
    };

    // Set one attachment
    const attachments = [];

    // Set Cc list
    const ccRecipients = [];

    // Set Bcc list
    const BccRecipients = [];

    const message = MailMessage(
      fromRecipient,
      toRecipients,
      subject,
      body,
      attachments,
      ccRecipients,
      BccRecipients,
      true
    );

    // Send email request
    sendEmail(message, handleResults);
  };

  return (
    <div className="MailTest">
      <Card>
        <CardHeader tag="h5">Mail Test</CardHeader>
        {!MSGRAPH_MAILACTIVE && (
          <CardBody>
            <CardText>MS GRAPH Mail is turned off in this App</CardText>
          </CardBody>
        )}
        {MSGRAPH_MAILACTIVE && (
          <CardBody>
            <CardTitle>{user.name}, you are logged on as developer.</CardTitle>
            {!results && (
              <Button
                color="primary"
                disabled={isLoading}
                onClick={sendRequest}
              >
                Request registration{" "}
                {isLoading && <Spinner size="sm" color="primary" />}
              </Button>
            )}
            {results && <Json data={results} />}
          </CardBody>
        )}
      </Card>
    </div>
  );
};

export default MailTest;
