import React from "react";
import DelegationsReportComp from "../components/DelegationsReportComp";
import "./DelegationsReport.css";

const DelegationsReport = (props) => {
  return (
    <div className="DelegationsReport">
      <div className="container">
        <DelegationsReportComp {...props} />
      </div>
    </div>
  );
};

export default DelegationsReport;
