import React from "react";
import TasksComp from "../components/TasksComp";
import "./Tasks.css";

const Tasks = (props) => {
  return (
    <div className="Tasks">
      <div className="container">
        <TasksComp {...props} />
      </div>
    </div>
  );
};

export default Tasks;
