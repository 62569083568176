import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from "reactstrap";
import {
  STATUS_ASSIGNED,
  STATUS_PENDING,
  STATUS_CANCELED,
  STATUS_COMPLETED,
  STATUS_NOT_COMPLETED
} from "../models/taskModels";
import Task from "./Task";
import TaskFormComp from "../components/TaskFormComp";
import { bulkGet } from "../services/bulkServices";
import { getCollectionCount } from "../services/collectionService";
import { getMyProfile, transformLeaderObj } from "../services/msGraphServices";
import {
  getTodayDateUnixms,
  getFutureDateObjFromUnixms,
  dateObjToUnixms
} from "../services/dateTimeUtils";
import { useMsal } from "@azure/msal-react";
import GetPagination from "./GetPagination";
import { phraseToProperCase } from "../libs/case-utils";
import "./MyTasksComp.css";

const STATUS_PAST_TASKS = "pastTasks";

// Define status options
const statusOptions = [
  // { status: "all", label: "Any" },
  { status: STATUS_ASSIGNED, label: phraseToProperCase(STATUS_ASSIGNED) },
  { status: STATUS_PAST_TASKS, label: phraseToProperCase(STATUS_PAST_TASKS) },
  { status: STATUS_PENDING, label: phraseToProperCase(STATUS_PENDING) },
  { status: STATUS_CANCELED, label: phraseToProperCase(STATUS_CANCELED) }
];

// Define affiliation options
const affiliationOptions = [
  { status: "recipient", label: "I'm an assignee" },
  { status: "delegate", label: "I'm the delegate" },
  { status: "owner", label: "I'm the owner" }
];

// Define due date options
const dueDateOptions = {};
dueDateOptions["all"] = null;
dueDateOptions[STATUS_PENDING] = null;
dueDateOptions[STATUS_CANCELED] = null;
dueDateOptions[STATUS_ASSIGNED] = [
  { status: "all", label: "Any" },
  { status: "dueToday", label: "Due today" },
  { status: "dueInOneDay", label: "Due in one day" },
  { status: "dueInOneWeek", label: "Due in one week" }
];
dueDateOptions[STATUS_PAST_TASKS] = [
  { status: "all", label: "Any" },
  { status: "onTime", label: "On-time" },
  { status: "late", label: "Late" }
];

const MyTasksComp = (props) => {
  const { user, appSettings } = props;

  const { instance, accounts } = useMsal();

  const [isSearchOpen, setIsSearchOpen] = useState(true);
  const toggleSearch = () => setIsSearchOpen(!isSearchOpen);

  const [selectStatus, setSelectStatus] = useState(STATUS_ASSIGNED);
  const [selectNotCompleted, setSelectNotCompleted] = useState(true);
  const [selectDueDate, setSelectDueDate] = useState("all");
  const [selectAffiliation, setSelectAffiliation] = useState("recipient");
  const [isLoading, setIsLoading] = useState(false);
  const [editTask, setEditTask] = useState(null);
  const [items, setItems] = useState([]);

  // Manage delegation
  const curUserDelegation = user.delegation;

  // Switches
  const isDelegate = curUserDelegation.length > 0;

  const getIdToken = async () => {
    const res = await instance.acquireTokenSilent({
      scopes: ["User.Read", "User.ReadBasic.All"],
      account: accounts[0]
    });

    return res.idToken;
  };

  const getAccessToken = async () => {
    const res = await instance.acquireTokenSilent({
      scopes: ["User.Read", "User.Read.All", "Directory.Read.All", "Mail.Send"],
      account: accounts[0]
    });

    return res.accessToken;
  };

  const handleGoBack = () => {
    setEditTask(null);
  };

  const updateItem = (task) => {
    let itemsCopy = [...items];

    // Find item index
    let index = null;

    itemsCopy.every((item, i) => {
      if (item.id === task.id) {
        index = i;
        return false;
      }

      return true;
    });

    if (index !== null) {
      itemsCopy[index] = task;

      setEditTask(null);
      setItems(itemsCopy);
    } else {
      setEditTask(null);
    }
  };

  const deleteItem = (id) => {
    const itemsCopy = items.filter((item) => item.id !== id);

    setItems(itemsCopy);
  };

  const newSearch = () => {
    setIsLoading(false);

    setItems([]);
    toggleSearch();
  };

  // Control pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsCount, setItemsCount] = useState(0);

  const totalItemsPerPage = 20;

  const nextPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setItems([]);

    listItems(pageNumber, totalItemsPerPage);
  };

  const incChange = (pageNumber, inc, isposOrneg) => {
    let finalPage;
    const residual = pageNumber % inc;
    const control = residual === 0 ? inc : residual;

    if (isposOrneg > 0) {
      finalPage = pageNumber + inc - control + 1;
    } else {
      finalPage = pageNumber - inc - control + 1;
    }

    setCurrentPage(finalPage);
    setItems([]);

    listItems(finalPage, totalItemsPerPage);
  };

  const firstChange = () => {
    let finalPage = 1;

    setCurrentPage(finalPage);
    setItems([]);

    listItems(finalPage, totalItemsPerPage);
  };

  const lastChange = (finalPage) => {
    setCurrentPage(finalPage);
    setItems([]);

    listItems(finalPage, totalItemsPerPage);
  };

  // Control modal
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const toggleModal = () => setOpenModal(!openModal);
  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      &times;
    </button>
  );

  const showCustomModal = (params) => {
    const { title, message } = params;

    setModalTitle(title);
    setModalMsg(message);
    toggleModal();
  };

  const listItems = async (page, count) => {
    let res = [];
    let query = {};
    let totalItems = 0;
    const todayMs = getTodayDateUnixms();
    const inOneDayMs = dateObjToUnixms(
      getFutureDateObjFromUnixms(todayMs, 1, "days")
    );
    const inOneWeekMs = dateObjToUnixms(
      getFutureDateObjFromUnixms(todayMs, 1, "weeks")
    );

    // Request access token
    const accessToken = await getAccessToken();

    // Get current user's profile
    const myProfile = transformLeaderObj(await getMyProfile(accessToken));

    // Get only delegation user ids
    const curUserDelegationIds = curUserDelegation.map((d) => d.id);

    // Add delegation filter
    switch (selectAffiliation) {
      case "recipient":
        query["directReports"] = [
          { key: `directReports.${myProfile.jobTitle}.id`, value: user.id }
        ];

        if (selectStatus === "assigned") {
          query["directReports"].push({
            key: `directReports.${myProfile.jobTitle}.completed`,
            value: false
          });
        }
        break;
      case "delegate":
        query["leader.id"] = {
          $in: curUserDelegationIds
        };
        break;
      case "owner":
        query["leader.id"] = user.id;
        break;
      default:
      // Do nothing
    }

    if (selectStatus !== "all") {
      if (selectStatus === STATUS_ASSIGNED && selectNotCompleted) {
        query.status = `${selectStatus}|${STATUS_NOT_COMPLETED}`;
      } else {
        if (selectStatus === STATUS_PAST_TASKS) {
          query.status = `${STATUS_COMPLETED}|${STATUS_NOT_COMPLETED}`;
        } else {
          query.status = selectStatus;
        }
      }
    }
    if (dueDateOptions[selectStatus]) {
      if (selectDueDate !== "all") {
        switch (selectDueDate) {
          case "dueToday":
            query.dueDate = todayMs;
            break;
          case "dueInOneDay":
            query.dueDate = inOneDayMs;
            break;
          case "dueInOneWeek":
            query.dueDate = inOneWeekMs;
            break;
          case "onTime":
            query.onTime = true;
            break;
          case "late":
            query.late = true;
            break;
          default:
          // Do nothing
        }
      }
    }

    // Request id token
    const idToken = await getIdToken();

    try {
      const resItemsCount = await getCollectionCount(
        {
          collection: "Tasks",
          ...query
        },
        idToken
      );

      setItemsCount(resItemsCount.count);
    } catch (e) {}

    // Re-define query
    query = {
      collection: "Tasks",
      filter: {},
      sort: { status: 1 },
      skip: (page - 1) * count,
      limit: count,
      project: {
        _id: 0
      },
      confirm: true
    };

    // Add delegation filter
    switch (selectAffiliation) {
      case "recipient":
        query.filter[`directReports.${myProfile.jobTitle}.id`] = user.id;

        if (selectStatus === "assigned") {
          query.filter[`directReports.${myProfile.jobTitle}.completed`] = false;
        }
        break;
      case "delegate":
        query.filter["leader.id"] = {
          $in: curUserDelegationIds
        };
        break;
      case "owner":
        query.filter["leader.id"] = user.id;
        break;
      default:
      // Do nothing
    }

    if (selectStatus !== "all") {
      if (selectStatus === STATUS_ASSIGNED && selectNotCompleted) {
        query.filter.status = {
          $in: [selectStatus, STATUS_NOT_COMPLETED]
        };
      } else {
        if (selectStatus === STATUS_PAST_TASKS) {
          query.filter.status = {
            $in: [STATUS_COMPLETED, STATUS_NOT_COMPLETED]
          };
        } else {
          query.filter.status = selectStatus;
        }
      }
    }
    if (dueDateOptions[selectStatus]) {
      if (selectDueDate !== "all") {
        switch (selectDueDate) {
          case "dueToday":
            query.filter.dueDate = todayMs;
            break;
          case "dueInOneDay":
            query.filter.dueDate = inOneDayMs;
            break;
          case "dueInOneWeek":
            query.filter.dueDate = inOneWeekMs;
            break;
          case "onTime":
            query.filter.onTime = true;
            break;
          case "late":
            query.filter.late = true;
            break;
          default:
          // Do nothing
        }
      }
    }

    try {
      res = await bulkGet(query, idToken);

      if (res.status === 200) {
        setItems(res.response);

        totalItems = res.response.length;
      } else {
        setItems([]);

        totalItems = 0;
      }
    } catch (e) {}

    return totalItems;
  };

  const handleSelectStatus = (option) => {
    setSelectStatus(option);
    setSelectDueDate("all");
  };

  const handleSelectAffiliation = (option) => {
    setSelectAffiliation(option);
  };

  const validateForm = () => {
    const isRecipient = selectAffiliation === "recipient";
    const isPending = selectStatus === STATUS_PENDING;
    let passed = true;

    if (isRecipient && isPending) passed = false;

    return selectStatus.length > 0 && selectDueDate.length > 0 && passed;
  };

  const handleSubmitDefault = async () => {
    setIsLoading(true);

    try {
      const numItems = await listItems(1, totalItemsPerPage);

      if (numItems) {
        toggleSearch();
      } else {
        showCustomModal({
          title: "Search results",
          message: "No tasks assigned or overdue were found."
        });
      }
    } catch (e) {
      console.log(e);
    }

    setIsLoading(false);
  };

  // Fire up default search
  useEffect(() => {
    handleSubmitDefault();

    // Removes the warning from eslint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event = null) => {
    if (event) event.preventDefault();

    setIsLoading(true);

    try {
      const numItems = await listItems(1, totalItemsPerPage);

      if (numItems) {
        toggleSearch();
      } else {
        showCustomModal({
          title: "Search results",
          message: "No results were found following that criteria"
        });
      }
    } catch (e) {
      console.log(e);
    }

    setIsLoading(false);
  };

  const CustomModal = () => {
    return (
      <Modal
        className="msgModal"
        returnFocusAfterClose={true}
        isOpen={openModal}
      >
        <ModalHeader toggle={toggleModal} close={closeBtn}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>
          <p>{modalMsg}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal} block>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const SearchResults = () => {
    let numberOfPages = 0;
    if (itemsCount % totalItemsPerPage === 0) {
      numberOfPages = Math.floor(itemsCount / totalItemsPerPage);
    } else {
      numberOfPages = Math.floor(itemsCount / totalItemsPerPage) + 1;
    }

    return items.length ? (
      <>
        <div className="new-search">
          <Button
            outline
            size="sm"
            onClick={newSearch}
            color="primary"
            style={{ float: "right" }}
          >
            New Search
          </Button>
        </div>
        <h5 className="card-title">Search results</h5>
        <GetPagination
          pages={numberOfPages}
          nextPage={nextPage}
          currentPage={currentPage}
          firstChange={firstChange}
          lastChange={lastChange}
          incChange={incChange}
        />
        <div className="mt-1rem">
          {items.map((item, i) => {
            const crudOn = curUserDelegation
              .map((d) => d.id)
              .includes(item.leader.id);

            return (
              <Task
                key={i}
                user={user}
                defTask={item}
                setEditTask={setEditTask}
                callback={updateItem}
                handleDeleteItem={deleteItem}
                crudOn={crudOn}
                affiliation={selectAffiliation}
              />
            );
          })}
        </div>
      </>
    ) : (
      !isSearchOpen && (
        <>
          <div className="new-search">
            <Button
              outline
              size="sm"
              onClick={newSearch}
              color="primary"
              style={{ float: "right" }}
            >
              New Search
            </Button>
          </div>
          <h5 className="card-title">Search results</h5>
          <div className="mt-1rem">
            <CardText>
              No tasks left to display from the last search results...
            </CardText>
          </div>
        </>
      )
    );
  };

  return (
    <div className="MyTasksComp">
      <h5 className="bg-warning text-white banner">If your job title changed recently, the tasks may not appear. Team is working on the fix, look forward for an update. Reach out to HelpDesk if you are impacted.</h5>
      <Card>
        <CardHeader className="bg-primary text-white" tag="h5">
          My Tasks
        </CardHeader>
        <CardBody>
          <Collapse isOpen={isSearchOpen}>
            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit} id="search-form">
                  <FormGroup tag="fieldset">
                    <h5 className="card-title">Select task status</h5>
                    {statusOptions.map((el, i) => {
                      let show = true;

                      const isAssignedOption = el.status === STATUS_ASSIGNED;
                      const isAssigned = selectStatus === STATUS_ASSIGNED;
                      const isPending = el.status === STATUS_PENDING;
                      const isRecipient = selectAffiliation === "recipient";

                      if (isRecipient && isPending) show = false;

                      return (
                        show && (
                          <div key={i}>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="status"
                                  value={el.status}
                                  defaultChecked={el.status === selectStatus}
                                  disabled={isLoading}
                                  onChange={(e) =>
                                    handleSelectStatus(e.target.value)
                                  }
                                />{" "}
                                {el.label}
                              </Label>
                            </FormGroup>
                            {isAssignedOption && isAssigned && (
                              <FormGroup check className="ml-1rem">
                                <Input
                                  type="checkbox"
                                  defaultChecked={selectNotCompleted}
                                  disabled={isLoading}
                                  onChange={(e) =>
                                    setSelectNotCompleted(e.target.checked)
                                  }
                                />{" "}
                                <Label check>Include Not Completed</Label>
                              </FormGroup>
                            )}
                          </div>
                        )
                      );
                    })}
                  </FormGroup>
                  {dueDateOptions[selectStatus] && (
                    <FormGroup tag="fieldset">
                      <h5 className="card-title">
                        Select task due date criteria
                      </h5>
                      {dueDateOptions[selectStatus].map((el, i) => (
                        <FormGroup key={i} check>
                          <Label check>
                            <Input
                              type="radio"
                              name="dueDate"
                              value={el.status}
                              checked={el.status === selectDueDate}
                              disabled={isLoading}
                              onChange={(e) => setSelectDueDate(e.target.value)}
                            />{" "}
                            {el.label}
                          </Label>
                        </FormGroup>
                      ))}
                    </FormGroup>
                  )}
                  <FormGroup tag="fieldset">
                    <h5 className="card-title">Select my affiliation</h5>
                    {affiliationOptions.map((el, i) => {
                      let show = true;

                      if (el.status === "delegate" && !isDelegate) show = null;

                      return (
                        show && (
                          <FormGroup key={i} check>
                            <Label check>
                              <Input
                                type="radio"
                                name="affiliation"
                                value={el.status}
                                defaultChecked={el.status === selectAffiliation}
                                disabled={isLoading}
                                onChange={(e) =>
                                  handleSelectAffiliation(e.target.value)
                                }
                              />{" "}
                              {el.label}
                            </Label>
                          </FormGroup>
                        )
                      );
                    })}
                  </FormGroup>
                  <Button
                    className="btn-lg"
                    disabled={!validateForm() || isLoading}
                    color="primary"
                    block
                  >
                    Search {isLoading && <Spinner color="light" />}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Collapse>
          {!editTask && <SearchResults />}
          {editTask && (
            <TaskFormComp
              user={user}
              appSettings={appSettings}
              task={editTask}
              mode="update"
              callback={updateItem}
              handleGoBack={handleGoBack}
            />
          )}
        </CardBody>
      </Card>
      <CustomModal />
    </div>
  );
};

export default MyTasksComp;
