import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import NotFound from "../containers/NotFound";
import Revoked from "../containers/Revoked";
import { getContainerAccessRoles, isOnlyDelegates } from "../models/RoleAccess";

const AuthenticatedRoute = ({ component: C, appProps, ...rest }) => {
  const isAuthenticated = useIsAuthenticated();
  const { user } = appProps;
  const { path } = { ...rest };

  const isDelegate = user.delegation.length > 0;
  const isAuthorized = getContainerAccessRoles(path).includes(user.role);
  let show = isAuthorized;

  if (isOnlyDelegates(path) && !isDelegate) show = false;

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          user.registration === "revoked" ? (
            <Revoked {...props} {...appProps} />
          ) : show ? (
            <C {...props} {...appProps} />
          ) : (
            <NotFound {...props} {...appProps} />
          )
        ) : (
          <Redirect
            to={`/login?redirect=${props.location.pathname}${props.location.search}`}
          />
        )
      }
    />
  );
};

export default AuthenticatedRoute;
