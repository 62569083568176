const roles = [
  { role: "admin", label: "Admin" },
  { role: "it-admin", label: "IT Admin" },
  { role: "leader", label: "Leader" },
  { role: "dev", label: "Developer" },
  { role: "guest", label: "Requested access" }
];

const getUserRoles = (role) => {
  switch (role) {
    case "dev":
      return roles;
    case "it-admin":
      return roles.filter((item) => item.role !== "dev");
    case "admin":
      return roles.filter(
        (item) => item.role !== "dev" && item.role !== "it-admin"
      );
    default:
      return [];
  }
};

export { getUserRoles };
