import axios from "axios";

const baseUrl = process.env.REACT_APP_APPREG_APIBASEURL;
const hostKey = process.env.REACT_APP_AZFUNCTIONS_HOSTKEY;
const masterKey = process.env.REACT_APP_AZFUNCTIONS_MASTERKEY;

const cancelTask = async (payload, token = null) => {
  const url = `${baseUrl}/api/taskCancel?code=${hostKey}`;

  const { data, status } = await axios({
    method: "POST",
    url: url,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: payload
  });

  return { status, response: data };
};

const runSweeper = async (payload, token = null) => {
  const url = `${baseUrl}/admin/functions/taskSweeper`;

  const { data, status } = await axios({
    method: "POST",
    url: url,
    headers: {
      Authorization: `Bearer ${token}`,
      "x-functions-key": masterKey,
      "Content-Type": "application/json"
    },
    data: payload
  });

  return { status, response: data };
};

const runDelegationUpdate = async (token = null) => {
  const url = `${baseUrl}/admin/functions/updateDelegation`;

  const { data, status } = await axios({
    method: "POST",
    url: url,
    headers: {
      Authorization: `Bearer ${token}`,
      "x-functions-key": masterKey,
      "Content-Type": "application/json"
    },
    data: {}
  });

  return { status, response: data };
};

export { cancelTask, runSweeper, runDelegationUpdate };
