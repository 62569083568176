import React from "react";
import OrgChartTest from "../components/OrgChartTest";
import "./OrgChart.css";

const OrgChart = (props) => {
  return (
    <div className="OrgChart">
      <div className="container">
        <OrgChartTest {...props} />
      </div>
    </div>
  );
};

export default OrgChart;
