import React from "react";
import UserCompletionReportComp from "../components/UserCompletionReportComp";
import "./UserCompletionReport.css";

const UserCompletionReport = (props) => {
  return (
    <div className="UserCompletionReport">
      <div className="container">
        <UserCompletionReportComp {...props} />
      </div>
    </div>
  );
};

export default UserCompletionReport;
