import React from "react";
import Guest from "../components/Guest";
import Admin from "../components/Admin";
import ItAdmin from "../components/ItAdmin";
import Leader from "../components/Leader";
import Developer from "../components/Developer";
import "./Home.css";

const Home = (props) => {
  const { user, auth, sendEmail } = props;

  const renderLander = () => {
    return (
      <div className="container">
        <h2>Not authenticated</h2>
      </div>
    );
  };

  const renderAdminOptions = () => {
    return (
      <div className="container">
        <Admin user={user} auth={auth} />
      </div>
    );
  };

  const renderItAdminOptions = () => {
    return (
      <div className="container">
        <ItAdmin user={user} auth={auth} />
      </div>
    );
  };

  const renderLeaderOptions = () => {
    return (
      <div className="container">
        <Leader user={user} auth={auth} />
      </div>
    );
  };

  const renderDevOptions = () => {
    return (
      <div className="container">
        <Developer user={user} auth={auth} />
      </div>
    );
  };

  const renderGuestOptions = () => {
    return (
      <div className="container">
        <Guest user={user} auth={auth} sendEmail={sendEmail} />
      </div>
    );
  };

  const renderOptions = () => {
    if (!user) return renderLander();

    switch (user.role) {
      case "admin":
        return renderAdminOptions();
      case "it-admin":
        return renderItAdminOptions();
      case "leader":
        return renderLeaderOptions();
      case "dev":
        return renderDevOptions();
      case "guest":
        return renderGuestOptions();
      default:
    }
  };

  return <div className="Home">{renderOptions()}</div>;
};

export default Home;
