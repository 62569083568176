import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const AdminGrantModal = (props) => {
  const { role, show, setShow, onYes, prevGrant } = props;

  const [modal, setModal] = useState(false);

  useEffect(() => {
    setModal(show);
  }, [show]);

  const handleCancel = () => {
    onYes(prevGrant.role, prevGrant.status);
    setShow(false);
  };

  const handleOk = () => {
    onYes(role, "approved");
    setShow(false);
  };

  return (
    <div>
      <Modal isOpen={modal} backdrop={true} keyboard={false}>
        <ModalHeader>Administrators must be vetted</ModalHeader>
        <ModalBody>
          Are you sure you want to grant administrator privileges to this user?
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleOk}>
            Yes, I'm sure
          </Button>{" "}
          <Button color="danger" onClick={handleCancel}>
            Not sure
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AdminGrantModal;
