import axios from "axios";

const baseUrl = process.env.REACT_APP_APPREG_APIBASEURL;
const hostKey = process.env.REACT_APP_AZFUNCTIONS_HOSTKEY;
const dataFactoryName = process.env.REACT_APP_DATAFACTORYNAME;

const getLeaderOrgTree = async (id, token = null) => {
  const url = `${baseUrl}/api/bulkGet?code=${hostKey}`;

  // Build query
  const doc = {
    collection: "OrgTrees",
    filter: {
      id
    },
    sort: {},
    skip: null,
    limit: null,
    project: {
      _id: 0,
      id: 1,
      tree: 1,
      updatedAt: 1
    },
    confirm: true
  };

  const { data, status } = await axios({
    method: "POST",
    url: url,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: doc
  });

  if (status === 200) {
    if (data.length > 0) {
      return data[0];
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const getTaskWithinStartDate = async (startDate, token = null) => {
  const url = `${baseUrl}/api/bulkGet?code=${hostKey}`;

  // Build query
  const doc = {
    collection: "Tasks",
    filter: {
      status: {
        $in: ["assigned", "completed", "notCompleted"]
      },
      startDate: {
        $gte: startDate
      }
    },
    sort: {
      dueDate: 1
    },
    skip: null,
    limit: null,
    project: {
      _id: 0,
      id: 1,
      taskTitle: 1,
      leader: 1,
      startDate: 1,
      dueDate: 1,
      status: 1,
      stats: 1
    },
    confirm: true
  };

  const { data, status } = await axios({
    method: "POST",
    url: url,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: doc
  });

  if (status === 200) {
    return data;
  } else {
    return null;
  }
};

const getLeaderDelegates = async (leaderId, token = null) => {
  const url = `${baseUrl}/api/bulkGet?code=${hostKey}`;

  // Build query
  const doc = {
    collection: "Users",
    filter: {
      "delegation.id": leaderId
    },
    sort: {
      "delegation.displayName": 1
    },
    skip: null,
    limit: null,
    project: {
      _id: 0,
      id: 1,
      name: 1,
      email: 1,
      role: 1,
      registration: 1
    },
    confirm: true
  };

  const { data, status } = await axios({
    method: "POST",
    url: url,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: doc
  });

  if (status === 200) {
    return data;
  } else {
    return null;
  }
};

const getTaskAssignees = async (taskId, token = null) => {
  const url = `${baseUrl}/api/bulkAggregate?code=${hostKey}`;

  // Build query
  const doc = {
    collection: "Tasks",
    aggregate: [
      {
        $match: {
          id: taskId
        }
      },
      {
        $project: {
          _id: 0,
          taskId: "$id",
          groups: {
            $objectToArray: "$directReports"
          }
        }
      },
      {
        $unwind: {
          path: "$groups"
        }
      },
      {
        $project: {
          people: "$groups.v"
        }
      },
      {
        $unwind: {
          path: "$people"
        }
      },
      {
        $replaceRoot: {
          newRoot: "$people"
        }
      },
      {
        $sort: {
          completed: 1,
          onTime: 1
        }
      },
      {
        $project: {
          _id: 0,
          id: 1,
          displayName: 1,
          jobTitle: 1,
          group: 1,
          completed: 1,
          onTime: 1,
          completedOn: 1
        }
      }
    ],
    confirm: true
  };

  const { data, status } = await axios({
    method: "POST",
    url: url,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: doc
  });

  if (status === 200) {
    return data;
  } else {
    return null;
  }
};

const triggerLeaderOrdTreeUpdate = async (accnts, token = null) => {
  const url = `${baseUrl}/api/triggerDataFactoryPipeline?code=${hostKey}`;

  // Build query
  const doc = {
    datafactory: dataFactoryName,
    pipeline: "UpdateOrgTree",
    parameters: {
      mail: accnts
    }
  };

  const { data, status } = await axios({
    method: "POST",
    url: url,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: doc
  });

  if (status === 200) {
    return data;
  } else {
    return null;
  }
};

const findNode = (id, stack) => {
  return stack.first((node) => {
    return node.model.id === id;
  });
};

export {
  getLeaderOrgTree,
  getTaskWithinStartDate,
  getLeaderDelegates,
  getTaskAssignees,
  triggerLeaderOrdTreeUpdate,
  findNode
};
