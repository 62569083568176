import { v4 as uuidv4 } from "uuid";
import { getTodayDateUnixms } from "../services/dateTimeUtils";

// Tasks TTL
const MAX_TTL = process.env.REACT_APP_Tasks_ttl;
const STATUS_ASSIGNED = "assigned";
const STATUS_PENDING = "pendingAssignment";
const STATUS_CANCELED = "canceled";
const STATUS_COMPLETED = "completed";
const STATUS_NOT_COMPLETED = "notCompleted";
const MODE_CREATE = "create";
const MODE_UPDATE = "update";

const initTask = {
  id: uuidv4(),
  leader: null,
  groups: [],
  directReports: null,
  withDirectReports: true,
  // taskTitle: "Watch cute cats",
  taskTitle: "",
  // taskDescription:
  //   "Please watch the cute cats video and provide feedback.",
  taskDescription: "",
  webReferences: [],
  dueDate: getTodayDateUnixms(),
  startDate: getTodayDateUnixms(),
  status: STATUS_PENDING,
  createdBy: null,
  createdAt: null,
  updatedBy: null,
  updatedAt: null,
  completedOn: null,
  onTime: null,
  late: null,
  stats: {
    assignments: 0,
    completed: 0,
    onTime: 0,
    late: 0,
    notCompleted: 0
  },
  ttl: MAX_TTL
};

const initErrors = {
  taskTitle: {
    invalid: null,
    message: ""
  },
  taskDescription: {
    invalid: null,
    message: ""
  },
  dueDate: {
    invalid: false,
    message: ""
  },
  startDate: {
    invalid: false,
    message: ""
  }
};

const definitions = {
  leader: {
    required: {
      value: true,
      info: "Select the task owner from the list of leaders above"
    }
  },
  groups: {
    required: {
      value: true,
      info: "In parenthesis is the number of assignees for the group"
    }
  },
  taskTitle: {
    required: {
      value: true,
      info: "Min: 8 chars. Max: 256 chars. Valid characters: A-Z a-z 0-9 -',.;:!?$%&#() and spaces",
      errMsg: "Title can't be empty"
    },
    minLength: {
      value: 8,
      errMsg: "Title can't be shorter than 8 characters"
    },
    maxLength: {
      value: 256,
      errMsg: "Title can't be longer than 256 characters"
    },
    regex: {
      rule: new RegExp(/^[A-Za-z0-9 \-',.;:!?$%&#()]*$/, "i"),
      errMsg: "Invalid characters in task title"
    }
  },
  taskDescription: {
    required: {
      value: true,
      info: "Min: 16 chars. Max: 1024 chars. Valid characters: A-Z a-z 0-9 -/',.;:!?$%&#()@ and spaces",
      errMsg: "Description can't be empty"
    },
    minLength: {
      value: 16,
      errMsg: "Description can't be shorter than 16 characters"
    },
    maxLength: {
      value: 1024,
      errMsg: "Description can't be longer than 1024 characters"
    },
    regex: {
      rule: new RegExp(/^[A-Za-z0-9 \-/',.;:!?$%&#()@\r\n]*$/, "i"),
      errMsg: "Invalid characters in task description"
    }
  },
  dueDate: {
    required: {
      value: true,
      info: "",
      errMsg: "Select a due date"
    }
  },
  startDate: {
    required: {
      value: true,
      info: "The start date is recalculated according to the due date selection.",
      errMsg: "Select a start date"
    }
  }
};

const furthestDueDate = {
  count: 1,
  unit: "weeks"
};

const getInitTaskModel = () => initTask;

const getInitErrors = (mode) => {
  switch (mode) {
    case "update":
      initErrors.taskTitle.invalid = false;
      initErrors.taskDescription.invalid = false;
      break;
    default:
    // Do nothing
  }

  return initErrors;
};

const getTaskDefinitions = () => definitions;

const getFurthestDueDate = () => furthestDueDate;

export {
  MAX_TTL,
  STATUS_ASSIGNED,
  STATUS_PENDING,
  STATUS_CANCELED,
  STATUS_COMPLETED,
  STATUS_NOT_COMPLETED,
  MODE_CREATE,
  MODE_UPDATE,
  getInitTaskModel,
  getInitErrors,
  getTaskDefinitions,
  getFurthestDueDate
};
