import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from "reactstrap";
import User from "./User";
import { getUsers } from "../services/userService";
import { getUserRoles } from "../models/UserRoles";
import { getCollectionCount } from "../services/collectionService";
import { useMsal } from "@azure/msal-react";
import GetPagination from "./GetPagination";
import "./UserList.css";

const UserList = (props) => {
  const { user, sendEmail } = props;

  const { instance, accounts } = useMsal();

  const [isSearchOpen, setIsSearchOpen] = useState(true);
  const toggleSearch = () => setIsSearchOpen(!isSearchOpen);

  // const roleOptions = [{ role: "all", label: "All" }, ...getUserRoles()];
  const defRole = "admin";
  const roleOptions = getUserRoles(user.role);

  const [role, setRole] = useState(defRole);
  const [userName, setUserName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [users, setUsers] = useState([]);

  const getIdToken = async () => {
    const res = await instance.acquireTokenSilent({
      scopes: ["User.Read", "User.ReadBasic.All"],
      account: accounts[0]
    });

    return res.idToken;
  };

  const newSearch = () => {
    setIsLoading(false);

    setUsers([]);
    toggleSearch();
  };

  // Control pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [usersCount, setUsersCount] = useState(0);

  const totalUsersPerPage = 20;

  const nextPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setUsers([]);

    listUsers(pageNumber, totalUsersPerPage);
  };

  const incChange = (pageNumber, inc, isposOrneg) => {
    let finalPage;
    const residual = pageNumber % inc;
    const control = residual === 0 ? inc : residual;

    if (isposOrneg > 0) {
      finalPage = pageNumber + inc - control + 1;
    } else {
      finalPage = pageNumber - inc - control + 1;
    }

    setCurrentPage(finalPage);
    setUsers([]);

    listUsers(finalPage, totalUsersPerPage);
  };

  const firstChange = () => {
    let finalPage = 1;

    setCurrentPage(finalPage);
    setUsers([]);

    listUsers(finalPage, totalUsersPerPage);
  };

  const lastChange = (finalPage) => {
    setCurrentPage(finalPage);
    setUsers([]);

    listUsers(finalPage, totalUsersPerPage);
  };

  // Control modal
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const toggleModal = () => setOpenModal(!openModal);
  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      &times;
    </button>
  );

  const showCustomModal = (params) => {
    const { title, message } = params;

    setModalTitle(title);
    setModalMsg(message);
    toggleModal();
  };

  const listUsers = async (page, count) => {
    // Request access token
    const idToken = await getIdToken();

    let res = [];
    let query = {};
    if (role !== "all") query.role = role;
    if (userName !== "") query.name = userName;

    try {
      // Get results count
      const resUsersCount = await getCollectionCount(
        {
          collection: "Users",
          ...query
        },
        idToken
      );

      setUsersCount(resUsersCount.count);
    } catch (e) {}

    if (page) query.page = page;
    if (count) query.count = count;

    try {
      // Get search results
      res = await getUsers(query, idToken);

      setUsers(res);
    } catch (e) {}

    return res.length;
  };

  const validateForm = () => {
    return role.length > 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const numUsers = await listUsers(1, totalUsersPerPage);

      if (numUsers) {
        toggleSearch();
      } else {
        showCustomModal({
          title: "Search results",
          message: "No results were found following that criteria"
        });
      }
    } catch (e) {}

    setIsLoading(false);
  };

  const CustomModal = () => {
    return (
      <Modal
        className="msgModal"
        returnFocusAfterClose={true}
        isOpen={openModal}
      >
        <ModalHeader toggle={toggleModal} close={closeBtn}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>
          <p>{modalMsg}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal} block>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const SearchResults = () => {
    let numberOfPages = 0;
    if (usersCount % totalUsersPerPage === 0) {
      numberOfPages = Math.floor(usersCount / totalUsersPerPage);
    } else {
      numberOfPages = Math.floor(usersCount / totalUsersPerPage) + 1;
    }

    return users.length ? (
      <>
        <div className="NewSearch">
          <Button
            outline
            size="sm"
            onClick={newSearch}
            color="primary"
            style={{ float: "right" }}
          >
            New Search
          </Button>
        </div>
        <h5 className="card-title">Search results</h5>
        <GetPagination
          pages={numberOfPages}
          nextPage={nextPage}
          currentPage={currentPage}
          firstChange={firstChange}
          lastChange={lastChange}
          incChange={incChange}
        />
        <div className="UserCards">
          {users.map((o, i) => (
            <User
              key={i}
              selectedUser={o}
              systemUser={user}
              sendEmail={sendEmail}
            />
          ))}
        </div>
      </>
    ) : (
      " "
    );
  };

  return (
    <div className="UserList">
      <Card>
        <CardHeader className="bg-primary text-white" tag="h5">
          User Management
        </CardHeader>
        <CardBody>
          <Collapse isOpen={isSearchOpen}>
            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit} id="search-form">
                  <FormGroup tag="fieldset">
                    <h5 className="card-title">
                      Select the role you will be searching for
                    </h5>
                    {roleOptions.map((el, i) => (
                      <FormGroup key={i} check>
                        <Label check>
                          <Input
                            type="radio"
                            name="role"
                            value={el.role}
                            defaultChecked={el.role === defRole}
                            onChange={(e) => setRole(e.target.value)}
                          />{" "}
                          {el.label}
                        </Label>
                      </FormGroup>
                    ))}
                  </FormGroup>
                  <FormGroup tag="fieldset">
                    <h5 className="card-title">Closest match (Optional)</h5>
                    <FormGroup>
                      <Label>
                        <b>User name:</b>
                      </Label>
                      <Input
                        type="text"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                    </FormGroup>
                  </FormGroup>
                  <Button
                    className="btn-lg"
                    disabled={!validateForm() || isLoading}
                    color="primary"
                    block
                  >
                    Search {isLoading && <Spinner color="light" />}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Collapse>
          <SearchResults />
        </CardBody>
      </Card>
      <CustomModal />
    </div>
  );
};

export default UserList;
