const Access = {
  containers: {
    task: {
      roles: ["admin", "it-admin", "leader", "dev"]
    },
    tasks: {
      roles: ["admin", "it-admin", "leader", "dev"]
    },
    "create-task": {
      roles: ["admin", "it-admin", "leader", "dev"],
      onlyDelegates: true
    },
    "user-completion": {
      roles: ["admin", "it-admin", "leader", "dev"]
    },
    delegations: {
      roles: ["admin", "it-admin", "dev"]
    },
    users: {
      roles: ["admin", "it-admin", "dev"]
    },
    settings: {
      roles: ["it-admin", "dev"]
    },
    sweeper: {
      roles: ["it-admin", "dev"]
    },
    "delegation-update": {
      roles: ["it-admin", "dev"]
    },
    "signalr-test": {
      roles: ["dev"]
    },
    "msgraph-test": {
      roles: ["dev"]
    },
    "org-chart": {
      roles: ["dev"]
    }
  },
  links: [
    {
      path: "/",
      label: "Home",
      roles: ["admin", "it-admin", "leader", "dev"]
    },
    {
      label: "Admin",
      roles: ["admin", "it-admin", "dev"],
      links: [
        {
          path: "/create-task",
          label: "Create New Task",
          roles: ["admin", "it-admin", "dev"],
          onlyDelegates: true
        },
        {
          path: "/tasks",
          label: "Task Management",
          roles: ["admin", "it-admin", "dev"]
        },
        {
          path: "/users",
          label: "User Management",
          roles: ["admin", "it-admin", "dev"]
        },
        {
          path: "/settings",
          label: "Settings",
          roles: ["it-admin", "dev"]
        },
        {
          path: "/sweeper",
          label: "Task Sweeper",
          roles: ["it-admin", "dev"]
        },
        {
          path: "/delegation-update",
          label: "Delegation Update",
          roles: ["it-admin", "dev"]
        }
      ]
    },
    {
      label: "Delegate",
      roles: ["leader", "dev"],
      onlyDelegates: true,
      links: [
        {
          path: "/create-task",
          label: "Create New Task",
          roles: ["leader", "dev"],
          onlyDelegates: true
        },
        {
          path: "/tasks",
          label: "Task Management",
          roles: ["leader", "dev"],
          onlyDelegates: true
        }
      ]
    },
    {
      label: "Reports",
      roles: ["admin", "it-admin", "leader", "dev"],
      links: [
        {
          path: "/user-completion",
          label: "User Completion",
          roles: ["admin", "it-admin", "leader", "dev"]
        },
        {
          path: "/delegations",
          label: "Leader Delegations",
          roles: ["admin", "it-admin", "dev"]
        }
      ]
    },
    {
      label: "Dev",
      roles: ["dev"],
      links: [
        {
          path: "/seed",
          label: "Seed Collection",
          roles: ["dev"]
        },
        {
          path: "/mail",
          label: "Mail Test",
          roles: ["dev"]
        },
        {
          path: "/signalr-test",
          label: "Signal R",
          roles: ["dev"]
        },
        {
          path: "/msgraph-test",
          label: "MS Graph",
          roles: ["dev"]
        },
        {
          path: "/org-chart",
          label: "Org. Chart",
          roles: ["dev"]
        }
      ]
    }
  ]
};

const getContainerAccessRoles = (c) => {
  const container = c.replace("/", "");

  return Access.containers[container]
    ? Access.containers[container].roles
    : ["admin", "it-admin", "leader", "dev", "guest"];
};

const isOnlyDelegates = (c) => {
  const container = c.replace("/", "");

  return Access.containers[container]?.onlyDelegates ?? false;
};

const getLinks = () => {
  return Access.links;
};

export { getContainerAccessRoles, getLinks, isOnlyDelegates };
