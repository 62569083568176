const GRAPH_PATH = "https://graph.microsoft.com/v1.0";

const getMyProfile = async (accessToken) => {
  const url = `${GRAPH_PATH}/me?$select=id,displayName,givenName,surname,jobTitle,department,mail,mobilePhone`;
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers
  };

  return fetch(url, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

const getMyPhoto = async (accessToken) => {
  const url = `${GRAPH_PATH}/me/photo/$value`;
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers
  };

  return fetch(url, options)
    .then((response) => response.blob())
    .catch((error) => console.log(error));
};

const getMyManager = async (accessToken) => {
  const url = `${GRAPH_PATH}/me/manager?$select=id,displayName,givenName,surname,jobTitle,department,mail,mobilePhone`;
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers
  };

  return fetch(url, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

const getMyDirectReports = async (accessToken) => {
  const url = `${GRAPH_PATH}/me/directReports?$select=id,displayName,givenName,surname,jobTitle,department,mail,mobilePhone`;
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers
  };

  return fetch(url, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

const getMyGroups = async (accessToken) => {
  const url = `${GRAPH_PATH}/me/transitiveMemberOf/microsoft.graph.group?$count=true`;
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers
  };

  return fetch(url, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

const getGroups = async (accessToken) => {
  const url = `${GRAPH_PATH}/groups?$orderBy=displayName`;
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers
  };

  return fetch(url, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

const getGroupsNextLink = async (url, accessToken) => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers
  };

  return fetch(url, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

const getGroupMembers = async (groupId, accessToken) => {
  const url = `${GRAPH_PATH}/groups/${groupId}/members?$select=id,displayName,givenName,surname,jobTitle,department,mail,mobilePhone&$count=true&$orderBy=displayName`;
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("ConsistencyLevel", "eventual");

  const options = {
    method: "GET",
    headers: headers
  };

  return fetch(url, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

const getUsers = async (displayName, accessToken) => {
  // Define fields to return
  const select = [
    "id",
    "displayName",
    "givenName",
    "surname",
    "jobTitle",
    "department",
    "mail",
    "mobilePhone",
    "extension_48dd64b54c3c40f0b8a91ffbeffd8782_dteemployeeSubGrp",
    "extension_48dd64b54c3c40f0b8a91ffbeffd8782_dteemployeeSubGroupLongDesc"
  ].join(",");

  const filterAnd = [
    `startswith(displayName,'${displayName}')`,
    "extension_48dd64b54c3c40f0b8a91ffbeffd8782_dteChief eq 'Y'"
    // "extension_48dd64b54c3c40f0b8a91ffbeffd8782_dteemployeeSubGrp in ('01','02','03','05','18')"
  ].join(" AND ");

  const url = `${GRAPH_PATH}/users?$select=${select}&$filter=${filterAnd}`;
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers
  };

  return fetch(url, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

const getUsersContains = async (hint, accessToken) => {
  // Define fields to return
  const select = [
    "id",
    "directReports",
    "displayName",
    "givenName",
    "surname",
    "jobTitle",
    "department",
    "mail",
    "mobilePhone",
    "extension_48dd64b54c3c40f0b8a91ffbeffd8782_dteemployeeSubGroupLongDesc"
  ].join(",");

  const filterAnd = [
    "extension_48dd64b54c3c40f0b8a91ffbeffd8782_dteemployeeGroup eq '1'",
    "extension_48dd64b54c3c40f0b8a91ffbeffd8782_dteChief eq 'Y'"
    // "extension_48dd64b54c3c40f0b8a91ffbeffd8782_dteemployeeSubGrp in ('01','02','03','05','18')"
  ].join(" AND ");

  const url = `${GRAPH_PATH}/users?$search="displayName:${hint}"&$select=${select}&$filter=${filterAnd}&$orderBy=displayName`;
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("ConsistencyLevel", "eventual");

  const options = {
    method: "GET",
    headers: headers
  };

  return fetch(url, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

const getUserManager = async (userId, accessToken) => {
  // Define fields to return
  const select = [
    "id",
    "displayName",
    "givenName",
    "surname",
    "jobTitle",
    "department",
    "mail",
    "mobilePhone",
    "extension_48dd64b54c3c40f0b8a91ffbeffd8782_dteemployeeSubGrp",
    "extension_48dd64b54c3c40f0b8a91ffbeffd8782_dteemployeeSubGroupLongDesc"
  ].join(",");

  const url = `${GRAPH_PATH}/users/${userId}/manager?$select=${select}`;
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers
  };

  return fetch(url, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

const getUserDirectReports = async (userId, accessToken) => {
  // Define fields to return
  const select = [
    "id",
    "displayName",
    "givenName",
    "surname",
    "jobTitle",
    "department",
    "mail",
    "mobilePhone",
    "extension_48dd64b54c3c40f0b8a91ffbeffd8782_dteemployeeSubGroupLongDesc"
  ].join(",");

  const filter =
    "extension_48dd64b54c3c40f0b8a91ffbeffd8782_dteemployeeGroup eq '1' AND (extension_48dd64b54c3c40f0b8a91ffbeffd8782_dteemployeeSubGrp in ('01','02','03','05','18') OR extension_48dd64b54c3c40f0b8a91ffbeffd8782_dteChief eq 'Y')";

  const url = `${GRAPH_PATH}/users/${userId}/directReports?$select=${select}&$filter=${filter}&$count=true&$orderBy=displayName`;
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("ConsistencyLevel", "eventual");

  const options = {
    method: "GET",
    headers: headers
  };

  return fetch(url, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

const getUserDirectReportsCount = async (userId, accessToken) => {
  const filterAnd = [
    "extension_48dd64b54c3c40f0b8a91ffbeffd8782_dteemployeeGroup eq '1'"
  ].join(" AND ");

  const url = `${GRAPH_PATH}/users/${userId}/directReports/$count?$filter=${filterAnd}`;
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("ConsistencyLevel", "eventual");

  const options = {
    method: "GET",
    headers: headers
  };

  return fetch(url, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

const transformLeaderObj = (obj) => {
  const extProps = [
    "extension_48dd64b54c3c40f0b8a91ffbeffd8782_dteemployeeSubGroupLongDesc"
  ];

  return {
    id: obj.id,
    displayName: obj.displayName,
    jobTitle: obj.jobTitle.replace(/[.$]/g, ""),
    department: obj.department,
    group: obj[extProps[0]] ?? null,
    mail: obj.mail,
    mobilePhone: obj.mobilePhone
  };
};

export {
  getMyProfile,
  getMyPhoto,
  getMyManager,
  getMyDirectReports,
  getMyGroups,
  getGroups,
  getGroupsNextLink,
  getGroupMembers,
  getUsers,
  getUsersContains,
  getUserManager,
  getUserDirectReports,
  getUserDirectReportsCount,
  transformLeaderObj
};
