import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardImg,
  FormText,
  FormGroup,
  Input,
  Label,
  Spinner
} from "reactstrap";
import {
  getMyProfile,
  getMyPhoto,
  getMyManager,
  getMyDirectReports,
  getMyGroups,
  getGroups,
  getGroupMembers,
  getUsers,
  getUserManager,
  getUserDirectReports
} from "../services/msGraphServices";
import { useMsal } from "@azure/msal-react";
import Calendar from "react-calendar";
import "./MsGraphTest.css";
import "react-calendar/dist/Calendar.css";

const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;
const isEmptyObj = (obj) => {
  return Object.keys(obj).length === 0;
};

const MsGraphTest = (props) => {
  const { user } = props;

  const { instance, accounts } = useMsal();

  const initResults = {
    appUserInfo: {},
    msGraphProfile: {},
    myPhoto: { url: null },
    myManager: {},
    myDirectReports: {},
    myGroups: {},
    users: {},
    userManager: {},
    userDirectReports: {},
    groups: {}
  };

  const [results, setResults] = useState(initResults);

  const getAccessToken = async () => {
    const res = await instance.acquireTokenSilent({
      scopes: ["User.Read", "User.Read.All", "Directory.Read.All", "Mail.Send"],
      account: accounts[0]
    });

    return res.accessToken;
  };

  const updateResults = (key, value) => {
    const resultsCopy = { ...results };

    resultsCopy[key] = value;

    setResults(resultsCopy);
  };

  const handleAppUserInfo = () => {
    updateResults("appUserInfo", user);
  };

  const handleMyProfile = async () => {
    // Request access token
    const accessToken = await getAccessToken();

    // Request user profile
    const profile = await getMyProfile(accessToken);

    updateResults("msGraphProfile", profile);
  };

  const handleMyPhoto = async () => {
    // Request access token
    const accessToken = await getAccessToken();

    // Request user photo
    const content = await getMyPhoto(accessToken);
    const url = URL.createObjectURL(content);

    updateResults("myPhoto", { url });
  };

  const handleMyManager = async () => {
    // Request access token
    const accessToken = await getAccessToken();

    // Request user manager
    const manager = await getMyManager(accessToken);

    updateResults("myManager", manager);
  };

  const handleMyDirectReports = async () => {
    // Request access token
    const accessToken = await getAccessToken();

    // Request user direct reports
    const directReports = await getMyDirectReports(accessToken);

    updateResults("myDirectReports", directReports);
  };

  const handleMyGroups = async () => {
    // Request access token
    const accessToken = await getAccessToken();

    // Request user groups
    const groups = await getMyGroups(accessToken);

    updateResults("myGroups", groups);
  };

  const handleGroups = async () => {
    // Request access token
    const accessToken = await getAccessToken();

    // Request user groups
    const groups = await getGroups(accessToken);

    // Get filtered data
    const newGroups = groups.value.map((group) => {
      return { id: group.id, displayName: group.displayName };
    });

    updateResults("groups", newGroups);
  };

  const ManageGroupMembersSearch = () => {
    const [groupId, setGroupId] = useState("");
    const [groupMembers, setGroupMembers] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const handleGroups = async () => {
      setIsLoading(true);

      // Request access token
      const accessToken = await getAccessToken();

      // Request user groupMembers
      const groupMembers = await getGroupMembers(groupId, accessToken);

      setGroupMembers(groupMembers);

      setIsLoading(false);
    };

    return (
      <Card className="mb-1rem">
        <CardHeader className="bg-primary text-white">Group Members</CardHeader>
        <CardBody>
          <FormGroup>
            <Label for="exampleEmail">Group Id</Label>
            <Input
              className="mb-1rem"
              type="text"
              name="groupId"
              id="groupId"
              value={groupId}
              disabled={isLoading}
              onChange={(e) => setGroupId(e.target.value)}
            />
            <FormText>Enter group Id</FormText>
          </FormGroup>
        </CardBody>
        <Card body className="overflow-500 results-search">
          <Json data={groupMembers} />
        </Card>
        <CardFooter>
          <Button
            className="mr-05rem"
            color="primary"
            size="sm"
            disabled={groupId === ""}
            onClick={handleGroups}
          >
            Send {isLoading && <Spinner size="sm" color="primary" />}
          </Button>
          <Button
            color="primary"
            size="sm"
            disabled={isEmptyObj(groupMembers)}
            onClick={() => updateResults("groupMembers", {})}
          >
            Reset
          </Button>
        </CardFooter>
      </Card>
    );
  };

  const ManageUserSearch = () => {
    const [displayName, setDisplayName] = useState("");
    const [users, setUsers] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const handleUsers = async () => {
      setIsLoading(true);

      // Request access token
      const accessToken = await getAccessToken();

      // Request users
      const users = await getUsers(displayName, accessToken);

      setUsers(users);

      setIsLoading(false);
    };

    return (
      <Card className="mb-1rem">
        <CardHeader className="bg-primary text-white">Users</CardHeader>
        <CardBody>
          <FormGroup>
            <Label for="exampleEmail">Display name</Label>
            <Input
              className="mb-1rem"
              type="text"
              name="displayName"
              id="displayName"
              value={displayName}
              disabled={isLoading}
              onChange={(e) => setDisplayName(e.target.value)}
            />
            <FormText>
              Enter characters to match the start of the display name
            </FormText>
          </FormGroup>
        </CardBody>
        <Card body className="overflow-500 results-search">
          <Json data={users} />
        </Card>
        <CardFooter>
          <Button
            className="mr-05rem"
            color="primary"
            size="sm"
            disabled={displayName === ""}
            onClick={handleUsers}
          >
            Send {isLoading && <Spinner size="sm" color="primary" />}
          </Button>
          <Button
            color="primary"
            size="sm"
            disabled={isEmptyObj(users)}
            onClick={() => updateResults("users", {})}
          >
            Reset
          </Button>
        </CardFooter>
      </Card>
    );
  };

  const ManageUserManagerSearch = () => {
    const [userId, setUserId] = useState("");
    const [userManager, setUserManager] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const handleUserManager = async () => {
      setIsLoading(true);

      // Request access token
      const accessToken = await getAccessToken();

      // Request user manager
      const userManager = await getUserManager(userId, accessToken);

      setUserManager(userManager);

      setIsLoading(false);
    };

    return (
      <Card className="mb-1rem">
        <CardHeader className="bg-primary text-white">User Manager</CardHeader>
        <CardBody>
          <FormGroup>
            <Label for="exampleEmail">User Id</Label>
            <Input
              className="mb-1rem"
              type="text"
              name="userId"
              id="userId"
              value={userId}
              disabled={isLoading}
              onChange={(e) => setUserId(e.target.value)}
            />
            <FormText>Enter user Id</FormText>
          </FormGroup>
        </CardBody>
        <Card body className="overflow-500 results-search">
          <Json data={userManager} />
        </Card>
        <CardFooter>
          <Button
            className="mr-05rem"
            color="primary"
            size="sm"
            disabled={userId === ""}
            onClick={handleUserManager}
          >
            Send {isLoading && <Spinner size="sm" color="primary" />}
          </Button>
          <Button
            color="primary"
            size="sm"
            disabled={isEmptyObj(userManager)}
            onClick={() => updateResults("userManager", {})}
          >
            Reset
          </Button>
        </CardFooter>
      </Card>
    );
  };

  const ManageUserDirectReportsSearch = () => {
    const [userId, setUserId] = useState("");
    const [userDirectReports, setUserDirectReports] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const handleUserDirectReports = async () => {
      setIsLoading(true);

      // Request access token
      const accessToken = await getAccessToken();

      // Request user manager
      const userDirectReports = await getUserDirectReports(userId, accessToken);

      setUserDirectReports(userDirectReports);

      setIsLoading(false);
    };

    return (
      <Card className="mb-1rem">
        <CardHeader className="bg-primary text-white">
          User Direct Reports
        </CardHeader>
        <CardBody>
          <FormGroup>
            <Label for="exampleEmail">User Id</Label>
            <Input
              className="mb-1rem"
              type="text"
              name="userId"
              id="userId"
              value={userId}
              disabled={isLoading}
              onChange={(e) => setUserId(e.target.value)}
            />
            <FormText>Enter user Id</FormText>
          </FormGroup>
        </CardBody>
        <Card body className="overflow-500 results-search">
          <Json data={userDirectReports} />
        </Card>
        <CardFooter>
          <Button
            className="mr-05rem"
            color="primary"
            size="sm"
            disabled={userId === ""}
            onClick={handleUserDirectReports}
          >
            Send {isLoading && <Spinner size="sm" color="primary" />}
          </Button>
          <Button
            color="primary"
            size="sm"
            disabled={isEmptyObj(userDirectReports)}
            onClick={() => updateResults("userDirectReports", {})}
          >
            Reset
          </Button>
        </CardFooter>
      </Card>
    );
  };

  const ShowCalendar = () => {
    const [value, onChange] = useState(new Date());

    return (
      <Card className="mb-1rem">
        <CardHeader className="bg-primary text-white">Calendar</CardHeader>
        <CardBody className="overflow-500">
          <Calendar onChange={onChange} value={value} />
        </CardBody>
      </Card>
    );
  };

  return (
    <div className="MsGraphTest">
      <Card>
        <CardHeader tag="h5">MS Graph Tests</CardHeader>
        <CardBody>
          <Card className="mb-1rem">
            <CardHeader className="bg-primary text-white">
              App User Info
            </CardHeader>
            <CardBody className="overflow-500">
              <Json data={results.appUserInfo} />
            </CardBody>
            <CardFooter>
              <Button
                className="mr-05rem"
                color="primary"
                size="sm"
                onClick={handleAppUserInfo}
              >
                Send
              </Button>
              <Button
                color="primary"
                size="sm"
                disabled={isEmptyObj(results.appUserInfo)}
                onClick={() => updateResults("appUserInfo", {})}
              >
                Reset
              </Button>
            </CardFooter>
          </Card>

          <Card className="mb-1rem">
            <CardHeader className="bg-primary text-white">
              MS Graph Profile
            </CardHeader>
            <CardBody className="overflow-500">
              <Json data={results.msGraphProfile} />
            </CardBody>
            <CardFooter>
              <Button
                className="mr-05rem"
                color="primary"
                size="sm"
                onClick={handleMyProfile}
              >
                Send
              </Button>
              <Button
                color="primary"
                size="sm"
                disabled={isEmptyObj(results.msGraphProfile)}
                onClick={() => updateResults("msGraphProfile", {})}
              >
                Reset
              </Button>
            </CardFooter>
          </Card>

          <Card className="mb-1rem">
            <CardHeader className="bg-primary text-white">My Photo</CardHeader>
            <CardBody className="overflow-500">
              {results.myPhoto.url && (
                <CardImg src={results.myPhoto.url} top width="100%" />
              )}
            </CardBody>
            <CardFooter>
              <Button
                className="mr-05rem"
                color="primary"
                size="sm"
                onClick={handleMyPhoto}
              >
                Send
              </Button>
              <Button
                color="primary"
                size="sm"
                disabled={!results.myPhoto.url}
                onClick={() => updateResults("myPhoto", { url: null })}
              >
                Reset
              </Button>
            </CardFooter>
          </Card>

          <Card className="mb-1rem">
            <CardHeader className="bg-primary text-white">
              My Manager
            </CardHeader>
            <CardBody className="overflow-500">
              <Json data={results.myManager} />
            </CardBody>
            <CardFooter>
              <Button
                className="mr-05rem"
                color="primary"
                size="sm"
                onClick={handleMyManager}
              >
                Send
              </Button>
              <Button
                color="primary"
                size="sm"
                disabled={isEmptyObj(results.myManager)}
                onClick={() => updateResults("myManager", {})}
              >
                Reset
              </Button>
            </CardFooter>
          </Card>

          <Card className="mb-1rem">
            <CardHeader className="bg-primary text-white">
              My Direct Reports
            </CardHeader>
            <CardBody className="overflow-500">
              <Json data={results.myDirectReports} />
            </CardBody>
            <CardFooter>
              <Button
                className="mr-05rem"
                color="primary"
                size="sm"
                onClick={handleMyDirectReports}
              >
                Send
              </Button>
              <Button
                color="primary"
                size="sm"
                disabled={isEmptyObj(results.myDirectReports)}
                onClick={() => updateResults("myDirectReports", {})}
              >
                Reset
              </Button>
            </CardFooter>
          </Card>

          <Card className="mb-1rem">
            <CardHeader className="bg-primary text-white">My Groups</CardHeader>
            <CardBody className="overflow-500">
              <Json data={results.myGroups} />
            </CardBody>
            <CardFooter>
              <Button
                className="mr-05rem"
                color="primary"
                size="sm"
                onClick={handleMyGroups}
              >
                Send
              </Button>
              <Button
                color="primary"
                size="sm"
                disabled={isEmptyObj(results.myGroups)}
                onClick={() => updateResults("myGroups", {})}
              >
                Reset
              </Button>
            </CardFooter>
          </Card>

          <Card className="mb-1rem">
            <CardHeader className="bg-primary text-white">Groups</CardHeader>
            <CardBody className="overflow-500">
              <Json data={results.groups} />
            </CardBody>
            <CardFooter>
              <Button
                className="mr-05rem"
                color="primary"
                size="sm"
                onClick={handleGroups}
              >
                Send
              </Button>
              <Button
                color="primary"
                size="sm"
                disabled={isEmptyObj(results.groups)}
                onClick={() => updateResults("groups", {})}
              >
                Reset
              </Button>
            </CardFooter>
          </Card>

          <ManageGroupMembersSearch />

          <ManageUserSearch />

          <ManageUserManagerSearch />

          <ManageUserDirectReportsSearch />

          <ShowCalendar />
        </CardBody>
      </Card>
    </div>
  );
};

export default MsGraphTest;
