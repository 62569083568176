import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Spinner
} from "reactstrap";
import { bulkUpdate } from "../services/bulkServices";
import { useMsal } from "@azure/msal-react";
import { phraseToProperCase } from "../libs/case-utils";
import "./SettingsComp.css";

// const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;
// const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const initSettings = {
  id: "app-settings",
  furthestDueDate: {
    count: 3,
    init: "weeks"
  }
};

const SettingsComp = (props) => {
  const { defAppSettings } = props;

  const [appSettings, setAppSettings] = useState(defAppSettings);

  const { instance, accounts } = useMsal();

  const getIdToken = async () => {
    const res = await instance.acquireTokenSilent({
      scopes: ["User.Read", "User.ReadBasic.All"],
      account: accounts[0]
    });

    return res.idToken;
  };

  const FurthestDueDateSetting = (props) => {
    const { defFurthestDueDate } = props;

    const [count, setCount] = useState(defFurthestDueDate.count);
    const [unit, setUnit] = useState(defFurthestDueDate.unit);
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const handleEdit = async () => {
      setIsEditing(true);
    };

    const handleCancelEdit = () => {
      setIsEditing(false);

      setCount(defFurthestDueDate.count);
      setUnit(defFurthestDueDate.unit);
    };

    const isChanged = () => {
      return (
        defFurthestDueDate.count !== count || defFurthestDueDate.unit !== unit
      );
    };

    const handleSave = async () => {
      setIsLoading(true);

      // Request access token
      const idToken = await getIdToken();

      // Set update query
      const query = {
        collection: "Settings",
        filter: {
          id: "app-settings"
        },
        update: {
          $set: {
            furthestDueDate: {
              count,
              unit
            }
          },
          $push: {},
          $pull: {},
          $setOnInsert: {
            id: "app-settings"
          }
        },
        options: {
          upsert: true
        },
        confirm: true
      };

      const res = await bulkUpdate(query, idToken);

      if (res.status === 200) {
        const copyAppSettings = { ...appSettings };
        copyAppSettings["furthestDueDate"] = { count, unit };

        setIsLoading(false);
        setAppSettings(copyAppSettings);
      } else {
        console.log("Error updating the furthest due date...", res);
      }
    };

    const handleUnit = (option) => {
      setUnit(option);
      setCount(1);
    };

    const handleCount = (option) => {
      setCount(+option);
    };

    const validateForm = () => {
      const isCount = count !== 0;
      const isUnit = unit !== "";

      return isChanged() && isUnit && isCount;
    };

    const getCounts = (unit) => {
      let counts = [];

      switch (unit) {
        case "weeks":
          counts = Array.from({ length: 51 }, (_, i) => i + 1);
          break;
        case "months":
          counts = Array.from({ length: 11 }, (_, i) => i + 1);
          break;
        case "years":
          counts = Array.from({ length: 5 }, (_, i) => i + 1);
          break;
        default:
        // Do nothing
      }

      return counts;
    };

    return (
      <Card className="mb-1rem">
        <CardHeader className="bg-primary text-white">
          Furthest due date
        </CardHeader>
        <CardBody>
          <CardTitle>
            Defines how far into the future a task due date can go.
          </CardTitle>
          <Card body>
            {!isEditing && (
              <>
                <FormGroup className="mb-0rem">
                  <Label>
                    <b>Unit:</b> {phraseToProperCase(unit)}
                  </Label>
                </FormGroup>
                <FormGroup className="mb-0rem">
                  <Label>
                    <b>Count:</b> {count}
                  </Label>
                </FormGroup>
              </>
            )}
            {isEditing && (
              <>
                <FormGroup className="mb-1rem">
                  <Label>
                    <b>Unit:</b>
                  </Label>
                  <Input
                    bsSize="sm"
                    value={unit}
                    type="select"
                    id="unit"
                    onChange={(e) => handleUnit(e.target.value)}
                  >
                    <option value="weeks">Weeks</option>
                    <option value="months">Months</option>
                    <option value="years">Years</option>
                  </Input>
                </FormGroup>
                <FormGroup className="mb-0rem">
                  <Label>
                    <b>Count:</b>
                  </Label>
                  <Input
                    bsSize="sm"
                    value={count}
                    type="select"
                    id="count"
                    onChange={(e) => handleCount(e.target.value)}
                  >
                    {getCounts(unit).map((c, i) => (
                      <option key={i - 1} value={c}>
                        {c}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </>
            )}
          </Card>
        </CardBody>
        <CardFooter>
          {!isEditing && (
            <Button
              className="mr-05rem"
              color="primary"
              size="sm"
              disabled={isLoading}
              onClick={handleEdit}
            >
              Edit setting {isLoading && <Spinner size="sm" color="light" />}
            </Button>
          )}
          {isEditing && (
            <Button
              className="mr-05rem"
              color="primary"
              size="sm"
              disabled={isLoading || !validateForm()}
              onClick={handleSave}
            >
              Save setting {isLoading && <Spinner size="sm" color="light" />}
            </Button>
          )}
          {isEditing && (
            <Button
              color="primary"
              size="sm"
              disabled={isLoading}
              onClick={handleCancelEdit}
            >
              Cancel {isLoading && <Spinner size="sm" color="light" />}
            </Button>
          )}
        </CardFooter>
      </Card>
    );
  };

  return (
    <div className="SettingsComp">
      <Card>
        <CardHeader className="bg-primary text-white" tag="h5">
          Settings
        </CardHeader>
        <CardBody>
          <FurthestDueDateSetting
            defFurthestDueDate={
              appSettings?.furthestDueDate ?? initSettings.furthestDueDate
            }
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default SettingsComp;
